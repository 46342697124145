<app-modal [modalTittle]="modalTittle" [modalMessage]="modalMessage" *ngIf="showModal"
    (click)="hideModal()"></app-modal>

<app-public-profile [target]="target" [targetDomains]="targetDomains" [selectedLanguage]="selectedLanguage"
    [isAuthenticated]="auth.isAuthenticated$ | async" [userId]="userId" [targetNick]="targetNick" [targetCompany]="targetCompany"
    [companyWebsite]="companyWebsite" [user]="user" *ngIf="userProfile" [assets]="assets"
    (onCloseModal)="closeModal($event)"></app-public-profile>

<!-- <div class="sign-up-message" *ngIf="(auth.isAuthenticated$ | async)  === false">
    <label class="user-description doesnt-accept sign-up-link">
        {{ 'signingUpForNickyTipcally' | translate }}
        <a routerLink="/register" target="_blank">{{ 'signUpNow' | translate }}</a></label>
</div> -->
<div class="signup-message-wrapper" *ngIf="!(auth.isAuthenticated$ | async) && signupMessage && itsProfilePage()">
    <label class="user-description doesnt-accept sign-up-link" #signupLink (click)="redirectToRegister()"
        [innerHTML]="'signingUpForNickyTipcally' | translate ">
        <!-- <a routerLink="/register" target="_blank" 
        [style]=" getSignupLinklength(signupLink) ? 'display: contents!important;' : '' ">
        {{ 'signUpNow' | translate }}
        </a> -->
        <!-- <i class="ph ph-x"></i> -->
    </label>
</div>


<header class="flex-center"
    [class]="!(auth.isAuthenticated$ | async) && signupMessage && itsProfilePage() ? 'sign-up-message-header' : ''"
    [ngStyle]="{'position': fromProfile && this.isMobile ? 'fixed' : ''}">
    <!-- <div class="signup-message-wrapper" *ngIf="!(auth.isAuthenticated$ | async) && signupMessage && itsProfilePage() && !isMobile">
        <label class="user-description doesnt-accept sign-up-link" #signupLink>
            {{ 'signingUpForNickyTipcally' | translate }}
            <a (click)="goToRegisterAndRegisterReferral()" target="_blank" 
            {{ getAuthLinkContents() }}
            <a routerLink="/register" target="_blank" 
            [style]=" getSignupLinklength(signupLink) ? 'display: contents!important;' : '' ">
            {{ 'signUpNow' | translate }}
            </a>
             <i class="ph ph-x"></i>
        </label>
    </div> -->

    <div class="left-content flex-center">
        <img class="logo clickable" src="/assets/nicky-logo.png" (click)="goHome()">
        @if (!itsPaymentFlow()) {
        <ul class="flex-center only-desktop" *ngIf="auth.isAuthenticated$ | async">
            <li>
                <a [ngClass]="{'selected-tab': currentPage === '/overview'}" (click)="goToPage('/overview')"
                    id="start-overviewTour">
                    <i class="ph-fill ph-chart-pie-slice"></i>
                    <label for="header-content">
                        {{ 'HEADER.OVERVIEW' | translate }}
                    </label>
                </a>
            </li>
            <!-- <li>
                    <a [ngClass]="{'selected-tab': currentPage === '/transactions'}" (click)="goToPage('/transactions')">
                        <i class="ph-bold ph-arrows-left-right" ></i>
                        <label for="header-content">
                            {{ 'HEADER.DASHBOARD' | translate }}
                        </label>
                    </a>
    
                </li> -->
            <li>
                <a [ngClass]="{'selected-tab': currentPage === '/contacts'}" (click)="goToPage('/contacts')">
                    <i class="ph-bold ph-address-book"></i>
                    <label for="header-content">
                        {{ 'contacts' | translate }}
                    </label>
                </a>
            </li>
            <li>
                <a [ngClass]="{'selected-tab': currentPage === '/settings'}" id="test" (click)="goToPage('/settings')">
                    <i class="ph-bold ph-gear"></i>
                    <label for="header-content">
                        {{ 'HEADER.SETTINGS' | translate }}
                    </label>
                </a>
            </li>
        </ul>
        } @else {
        <h1 class="flow-name">
            {{ itsPaymentFlow() | translate }}
        </h1>
        }
    </div>

    @if ( auth.isAuthenticated$ | async ) {
    <div class="right-content flex-center" [ngStyle]="{'gap': itsPaymentFlow() && isMobile ? '0px' : ''}">
        <div *ngIf="!itsPaymentFlow()" class="searchbar-wrapper only-desktop">
            <app-searchbar [myNick]="myNick" [authenticated]="auth.isAuthenticated$ | async" (openNewPayment)="openPayment($event)">
            </app-searchbar>
        </div>

        <div class="contents-wrapper">
            @if ( existingGeneralToursPages.includes(router.url) && !this.userProfile && !itsPaymentFlow() ) {
            <!-- <i [ngClass]="bookIconOpen ? 'ph-book-open' : 'ph-book'" class="ph button clickable ph-book" id="bookIcon" (click)="startPageTour()"></i> -->
            <img src="/assets/icons/help-book.png" class="help-book clickable" id="bookIcon" (click)="startPageTour()">
            }

            <div class="idiom-option-select only-desktop">

                <div class="selection-div clickable" (click)="openSelection = !openSelection">
                    <label class="selected-language">
                        <img class="country-flag clickable" src="/assets/icons/{{getCurrentLanguage()}}.png">
                        <!-- {{ selectedLanguage === 'pt-br' ? 'Port' : languageName?.substring(0, 3) }}  -->
                        <!-- <i class="ph-thin ph-caret-down"></i> -->
                        <!-- <img class="language-dropdown" src="/assets/icons/language-dropdown.png"> -->
                    </label>
                </div>
                <div class="options clickable" *ngIf="openSelection">
                    <a *ngFor="let idiom of systemLanguages" (click)="selectLanguage(idiom.language)">
                        {{idiom.name}}
                        <label class="bolder-label">{{ idiom.language === 'pt-br' ? idiom.language.replace('-br',
                            '').toUpperCase() : idiom.language.toUpperCase() }}</label>
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="!itsPaymentFlow()" class="mobile-icon-wrapper only-mobile">
            <i class="ph clickable" [ngClass]="{'ph-magnifying-glass search-icon': !searching, 'ph-x': searching}"
                (click)="searching = !searching; (!toggledMobileMenu || toggleMenu())">
            </i>
            <div *ngIf="searching" class="mobile-searchbar">
                <app-searchbar [myNick]="myNick" [authenticated]="auth.isAuthenticated$ | async"
                    (openNewPayment)="openPayment($event)">
                </app-searchbar>
            </div>
        </div>
        <div class="mobile-icon-wrapper only-mobile" (click)="toggleMenu(); searching = false;">
            <i [ngClass]="{'ph-list': !toggledMobileMenu, 'ph-x': toggledMobileMenu}" class="ph ph-list"></i>
        </div>


        <div #userProfileDiv class="user-profile flex-center" *ngIf="!isMobile">
            <li *ngIf="!loadingLogo" class="flex-center only-desktop">
                <img class="company-logo"
                    [src]="userLogo || this.avatarService.getInitialAvatar(user?.publicName?.charAt(0) || 'X')"
                    (click)="toggleProfile()" class="clickable profile-icon after-refresh">
                <i class="ph-thin ph-caret-down clickable open" (click)="toggleProfile()"></i>
                <!-- <img *ngIf="userLogo" [src]="'/assets/icons/new-open-menu.png'" (click)="toggleProfile()" class="clickable open"> -->
            </li>
            <div #profileContent *ngIf="!isMobile" class="profile-menu profile-wrapper common-profile-content">
                <a (click)="openProfile(user);" class="clickable">
                    <i class="ph ph-user-circle"></i>
                    {{ 'HEADER.PROFILE' | translate }}
                </a>
                <a (click)="goToPage('/settings')" id="test" class="clickable">
                    <i class="ph ph-gear-six"></i>
                    {{ 'HEADER.SETTINGS' | translate }}
                </a>
                <hr>
                <a (click)="logout()" class="clickable">
                    <i class="ph ph-sign-out"></i>
                    {{ 'HEADER.LOGOUT' | translate }}
                </a>
            </div>
        </div>
        <div #headerContent class="profile-menu profile-wrapper">
            <a [ngClass]="{'selected-tab': currentPage === '/overview'}" (click)="goToPage('/overview')">
                <i class="ph-fill ph-chart-pie-slice"></i>
                <label for="header-content">
                    {{ 'HEADER.OVERVIEW' | translate }}
                </label>
            </a>
            <!-- <a [ngClass]="{'selected-tab': currentPage === '/transactions'}" (click)="goToPage('/transactions')">
                    <i class="ph ph-arrows-left-right" ></i>
                    <label for="header-content">
                        {{ 'HEADER.DASHBOARD' | translate }}
                    </label>
                </a> -->

            <a [ngClass]="{'selected-tab': currentPage === '/contacts'}" (click)="goToPage('/contacts')">
                <i class="ph-bold ph-address-book"></i>

                <label for="header-content">
                    {{ 'contacts' | translate }}
                </label>
            </a>
            <a [ngClass]="{'selected-tab': currentPage === '/settings'}" id="mobile-settings" class="clickable"
                (click)="goToPage('/settings')">
                <i class="ph ph-gear"></i>
                <label for="header-content">
                    {{ 'HEADER.SETTINGS' | translate }}
                </label>
            </a>
            <a class="mobile-idiom-options">
                <label for="header-content">
                    {{ 'language' | translate }}
                </label>
                <div class="idiom-option-select">

                    <div class="selection-div clickable" (click)="openSelection = !openSelection">
                        <label class="selected-language">
                            <img class="country-flag clickable" src="/assets/icons/{{getCurrentLanguage()}}.png">
                            <!-- {{ selectedLanguage === 'pt-br' ? 'Port' : languageName?.substring(0, 3) }} 
                                <i class="ph-thin ph-caret-down"></i> -->
                            <!-- <img class="language-dropdown" src="/assets/icons/language-dropdown.png"> -->
                        </label>
                    </div>
                    <div class="options clickable" *ngIf="openSelection">
                        <a *ngFor="let idiom of systemLanguages" (click)="selectLanguage(idiom.language)">
                            {{idiom.name}}
                            <label class="bolder-label">{{ idiom.language === 'pt-br' ? idiom.language.replace('-br',
                                '').toUpperCase() : idiom.language.toUpperCase() }}</label>
                        </a>
                    </div>
                </div>
            </a>
            <div #profileContent *ngIf="isMobile" class="profile-menu profile-wrapper common-profile-content">
                <a (click)="openProfile(user); toggleMenu()" class="clickable">
                    <i class="ph ph-user-circle"></i>
                    {{ 'HEADER.PROFILE' | translate }}
                </a>
                <a (click)="goToPage('/settings')" id="mobile-settings" class="clickable">
                    <i class="ph ph-gear-six"></i>
                    {{ 'HEADER.SETTINGS' | translate }}
                </a>
                <hr>
                <a (click)="logout()" class="clickable">
                    <i class="ph ph-sign-out"></i>
                    {{ 'HEADER.LOGOUT' | translate }}
                </a>
            </div>
            <div class="mobile-user-wrapper flex-center" *ngIf="user" (click)="toggleProfile()">
                <div class="image-and-name flex-center">
                    <img [src]="userLogo || this.avatarService.getInitialAvatar(user?.publicName?.charAt(0) || 'X')"
                        (click)="toggleProfile()" class="clickable profile-icon after-refresh">
                    {{ formatPublicName(user?.publicName) }}
                </div>
                <i class="ph ph-caret-up-down"></i>
            </div>
        </div>
    </div>

    } @else {
    <div class="right-content flex-center">
        <div class="idiom-option-select">

            <div class="selection-div clickable" (click)="openSelection = !openSelection">
                <label class="selected-language">
                    {{ selectedLanguage === 'pt-br' ? 'Port' : languageName?.substring(0, 3) }}
                    <i class="ph-thin ph-caret-down"></i>
                    <!-- <img class="language-dropdown" src="/assets/icons/language-dropdown.png"> -->
                </label>
            </div>
            <div class="options clickable" *ngIf="openSelection">
                <a *ngFor="let idiom of systemLanguages" (click)="selectLanguage(idiom.language)">
                    {{idiom.name}}
                    <label class="bolder-label">{{ idiom.language === 'pt-br' ? idiom.language.replace('-br',
                        '').toUpperCase() : idiom.language.toUpperCase() }}</label>
                </a>
            </div>
        </div>
    </div>
    }
</header>



<div class="modal-backdrop" *ngIf="termsModal">
    <div class="modal message-modal">
        <div class="modal-header">
            <h3>{{ 'WelcomeToNickyWeAreHappyToHaveYou' | translate }}</h3>
            <h3 class="close" (click)="logout()"> X </h3>
        </div>
        <div class="modal-body">
            <label class="text-label">
                {{ 'youNeedToAgreeWithThe' | translate }}
                <a target="_blank" [href]="termsOfUseUrl"> {{ 'REGISTER.termsOfUse' | translate }}</a>
                , <a target="_blank" [href]="cookiePolicyUrl">{{ 'cookiePolicy' | translate }}</a>
                {{ 'REGISTER.and' | translate }}
                <a target="_blank" [href]="privacyPolicyUrl"> {{ 'privacyPolicy' | translate}}</a>
            </label>
        </div>
        <div class="modal-footer">
            <button (click)="acceptTerms()"> {{ 'accept' | translate }} </button>
            <button class="secondary-button delete-button" (click)="termsModal = false; logout()"> {{ 'decline' |
                translate }} </button>
        </div>
    </div>
</div>

<div class="modal-backdrop" *ngIf="domainOwnerModal">
    <div class="modal message-modal">
        <div class="modal-header">
            <h3> {{ 'youOwnThisDomain' | translate }}</h3>
            <h3 class="close" (click)="logout()"> X </h3>
        </div>
        <div class="modal-body">
            <label class="text-label">
                {{ 'areYouTheOwnerOf' | translate }} {{ this.getUserDomain() }}
            </label>
        </div>
        <div class="modal-footer">
            <button (click)="checkDomainOwnerStatus(true)"> {{ 'yes' | translate }} </button>
            <button class="secondary-button delete-button" (click)="checkDomainOwnerStatus(false)"> {{ 'no' | translate
                }} </button>
        </div>
    </div>
</div>