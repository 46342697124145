import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxCurrencyInputMode } from 'ngx-currency';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, firstValueFrom } from 'rxjs';
import { ManualOffRampDto } from 'src/app/dtos/manual-off-ramp.dto';
import { PaymentRequestCreateDto } from 'src/app/dtos/payment-request.dto';
import { AvatarService } from 'src/app/services/avatar.service';
import { EventService } from 'src/app/services/event.service';
import { OffRampService } from 'src/app/services/off-ramp.service';
import { PaymentRequestService } from 'src/app/services/payment.request.service';
import { QrCodeService } from 'src/app/services/qr-code.service'; // Import the service
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import { UserService } from 'src/app/services/user.service';
import { AssetIdFormatterUtil } from 'src/app/utils/asset-id-utils';
import FormatCurrency from 'src/app/utils/format-currency-utils';
import {
  decimalPlacesFor,
  environment
} from 'src/environments/environment';
import { Asset } from '../models/asset.model';
import { optionSelectsModel } from '../models/option-select.model';
import { PaymentRequestStepsModel } from '../models/payment-request-steps.model';
import { savedOffRampSettings } from '../models/saved-off-ramp-seetings.model';
import { User } from '../models/user.mode';
import { Subject } from 'rxjs';
import { catchError, debounceTime, tap } from 'rxjs/operators';

// Define a type for the steps
interface StepInfo {
  id: keyof PaymentRequestStepsModel;
  label: string;
  isLast?: boolean;
}

@Component({
  selector: 'app-payment-requests',
  templateUrl: './payment-requests.component.html',
  styleUrls: ['./payment-requests.component.scss'],
})
export class PaymentRequestsComponent {
  public completedSteps: PaymentRequestStepsModel = {
    paymentDetails: false,
    paymentInfo: false,
    reportPayment: false,
    finished: false,
  };

  public previousStep!: string;

  @Input('paymentModal') paymentModal!: boolean;

  @Input('isAuthenticated') isAuthenticated!: boolean;

  @Input('JWTToken') JWTToken!: string;

  @Input('userId') userId!: string;

  @Input('user') user!: any;

  @Input('targetNick') targetNick!: string;

  @Input('targetUser') targetUser!: User | any;

  @Input('assets') assets!: any;

  public fiatAssets: any[] = [];

  public cryptoAssets: any[] = [];

  public qrModal: boolean = false;

  public customRule!: boolean;
  public currentUserOffRampSettings: savedOffRampSettings[] = [];
  public manualOffRampSettings: ManualOffRampDto = {
    amount: undefined,
    assetId: undefined,
    cryptoConnectionId: undefined,
  };

  @ViewChild('code') code!: ElementRef;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public formatCurrency: FormatCurrency,
    public paymentRequestService: PaymentRequestService,
    public toastrService: ToastrService,
    private params: ActivatedRoute,
    public translate: TranslateService,
    private offRampService: OffRampService,
    private userService: UserService,
    private eventService: EventService,
    private toastrFactory: ToastrFactoryService,
    private assetIdFormatterUtil: AssetIdFormatterUtil,
    private avatarService: AvatarService,
    private qrCodeService: QrCodeService, // Inject the QR code service
  ) {}

  public customCurrencyMaskConfig: any;
  public walletAddress: string = '';

  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() successfullyTransaction: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public alreadyFinished!: boolean;
  public amount!: number;
  public showConfirmationModal = false;
  public selectedAsset: string = 'BTC';
  public browserLanguage = navigator.language;
  public invalidAmount!: boolean;
  public ethPrice!: any;
  public btcPrice!: any;
  public usdcPrice = 1.0;
  public usdtPrice = 1.0;
  public savedUsdValue!: string | number;
  public formatedPrice!: string;
  public savedAssetValue: any;
  public organization!: string;
  public email!: string;
  public hideCopyButton: boolean = false;
  public firstTime = true;
  public payingAsset: string | undefined;
  public isCommaDecimal = this.formatCurrency.isCommaDecimal(
    navigator.language,
  );
  public invalidEmail!: boolean;
  public toggleBook: boolean = false;
  public convertToFiatPorcentage: number = 0;
  public setConversionRate!: boolean;
  public receiverAcceptedAssets: Asset[] = [];
  public firefox = navigator.userAgent.indexOf('Firefox') > -1;
  public navigatorShare = this.firefox ? undefined : navigator.share;
  public url: string =
    window.location.href.replace('dashboard', '').replace('overview', '') +
    'home?paymentId=';
  public cliboardItem = typeof ClipboardItem !== 'undefined';
  public conversionValue!: number;
  public exchangeModal: boolean = false;
  public fullEmailModal: boolean = false;
  public showModal: boolean = false;
  public modalMessage!: string;
  public paymentIdModal!: boolean;
  public name!: string | undefined | null;
  public invalidPayerInfo!: boolean;
  public currentBackgroundPosition: number = 0;
  public isMobile!: boolean;
  public showNameInput!: boolean;
  private emailInputSubject = new Subject<string>();
  private debounceTimeInMs = 600; 

  currentOptionsSelects: {
    payingAsset: optionSelectsModel[];
    currentOffRampSettings: optionSelectsModel[];
  } = {
    payingAsset: [],
    currentOffRampSettings: [],
  };

  public areAUser: string = 'guest';

  public payment: FormGroup = new FormGroup({
    company: new FormControl(this.organization),
    name: new FormControl(this.name, [
      Validators.pattern('^.+$'),
    ]),
    email: new FormControl(this.email, [Validators.required, Validators.email]),
    asset: new FormControl(this.selectedAsset, Validators.required),
    amount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
    reference: new FormControl(null, Validators.maxLength(17)),
    description: new FormControl(null),
    status: new FormControl(),
    assetId: new FormControl(),
  });

  public shortId!: string;
  public environment: any = environment;

  // Define the steps array
  public steps: StepInfo[] = [
    // { id: 'paymentDetails', label: 'payment_flow.contact' },
    { id: 'paymentInfo', label: 'payment_flow.RequestDetails' },
    { id: 'reportPayment', label: 'payment_flow.overview', isLast: true },
  ];

  public step: string = this.steps[0].id;

  checkIfStepCanBeClicked(step: string): void {
    if (step === 'paymentInfo') {
     if (this.payment.valid) {
      return;
     }
    }
    // Exit early if the current step is finished
    if (this.step === 'finished') {
      return;
    }

    // Find the target step information
    const stepInfo = this.steps.find((s) => s.id === step);
    if (stepInfo) {
      const currentStepIndex = this.steps.findIndex((s) => s.id === this.step);
      const targetStepIndex = this.steps.findIndex((s) => s.id === step);

      // Allow navigation to the next step if valid
      if (targetStepIndex <= currentStepIndex + 1) {
        this.step = step;
      }
    }
  }

  public nextStep(nextStep: string): void {
    if (nextStep === 'reportPayment') {
      if (this.payment.valid) {
        this.step = this.steps[1].id;
        this.formatedPrice= this.formatPrice(this.payment.controls['amount'].value);
       }
    }
    if (nextStep === 'finish') {

      const paymentRequest = this.buildPaymentRequestDto();

      this.paymentRequestService.makeRequest(paymentRequest).pipe(
        tap((response: any) => {
          this.toastrService.success(
            this.translate.instant(
              'PAYMENT_REQUEST.paymentRequestSuccessfullyCreated',
            ),
            '',
            {
              timeOut: 3000,
            },
          );
          this.eventService.notifyRequestComplete();
          this.shortId = response.bill.shortId;
          this.step = 'finish';
          this.completedSteps.finished = true;
        }),
        catchError((error:any) => {
          this.toastrFactory.unknownError(
            this.translate.instant('unknownError'),
          );
          return EMPTY;
        })
      ).subscribe();
      return;
    }
  }

  // Method to check if a step is the current step
  isCurrentStep(stepId: keyof PaymentRequestStepsModel): boolean {
    return this.step === stepId;
  }

  // Method to check if a step is completed
  isStepCompleted(stepId: keyof PaymentRequestStepsModel): boolean | undefined {
    return this.completedSteps[stepId];
  }

  // Method to determine the color for a step
  getStepColor(stepId: keyof PaymentRequestStepsModel): string {
    return this.isCurrentStep(stepId) ? '#000' : '';
  }

  ngOnInit(): void {
    this.getReceiverAcceptedAssets();
    if (this.targetUser) {
      this.populateSenderInfo();
    } else {
      this.checkParams();
    }

    this.emailInputSubject.pipe(
      debounceTime(this.debounceTimeInMs)
    ).subscribe(() => this.emailInput());
  }

  async checkParams() {
    const queryParams = await firstValueFrom(this.params.queryParams);

    if (queryParams['paymentRequest'] && !this.targetUser) {
      this.targetUser = {
        email: queryParams['email'] || undefined,
        name: queryParams['name'] || undefined,
      };
      this.payment.controls['description'].setValue(
        queryParams['description'] || undefined,
      );
      this.payment.controls['reference'].setValue(
        queryParams['reference'] || undefined,
      );
      this.populateSenderInfo();
    }
  }

  populateSenderInfo() {
    this.email = this.targetUser.nick || this.targetUser.email;
    this.name = this.targetUser.name;
  }

  updateMaskConfig() {
    this.customCurrencyMaskConfig = {
      align: 'center',
      allowNegative: false,
      allowZero: false,
      decimal: this.isCommaDecimal ? ',' : '.',
      precision: this.getPrecision(),
      prefix: '',
      suffix: '',
      thousands: this.isCommaDecimal ? '.' : ',',
      nullable: false,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    };
  }

  async shareScreen(shortId: string): Promise<void> {
    await this.qrCodeService.shareQrCode(this.url, shortId);
  }

  getQrCode(): string {
    return this.qrCodeService.getQrCodeUrl(this.url, this.shortId);
  }

  async copyQr(): Promise<void> {
    try {
      await this.qrCodeService.copyQrToClipboard(this.url, this.shortId);
    } catch (error) {
      console.error('Error handling QR code:', error);
      this.toastrFactory.unknownError(this.translate.instant('unknownError'));
    }
  }

  public onCopyRequestLink(shortId: string) {
    let url =
      window.location.href.replace('dashboard', '').replace('overview', '') +
      'home?paymentId=';
    navigator.clipboard.writeText(url + shortId);
    this.toastrService.success(
      this.translate.instant('theLinkToThePaymentRequestHasBeenCopied'),
      '',
      {
        timeOut: 3000,
      },
    );
  }

  arrayBufferToBase64(buffer: ArrayBuffer): string {
    const binary = String.fromCharCode(...new Uint8Array(buffer));
    return btoa(binary);
  }

  copyRequestId() {
    navigator.clipboard.writeText(this.url + this.shortId);
    this.toastrService.success(
      this.translate.instant('thePaymentIdHasBeenCopied'),
    );
    this.alreadyFinished = true;
    // this.closeModal();
  }

  getPrecision() {
    return decimalPlacesFor[this.payingAsset || 'BTC'];
  }

  navigatorSupportsShare() {
    return this.navigatorShare;
  }

  handleImageError(event: any) {
    event.target.onerror = null;
    event.target.src = this.avatarService.getInitialAvatar(event.target.title);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  checkWindowSize() {
    if (window.innerWidth <= 1100) {
      this.isMobile = true;
      return;
    }
    this.isMobile = false;
  }

  populateCryptoAssets() {
    this.categorizeAssets();
    this.populatePayingAssetOptions();
    this.setInitialPayingAsset();
    this.updateMaskConfig();
  }

  private categorizeAssets() {
    this.fiatAssets = [];
    this.cryptoAssets = [];

    this.assets.forEach((asset: Asset) => {
      if (this.isAssetAccepted(asset)) {
        if (asset.isFiat) {
          this.fiatAssets.push(asset);
        } else if (!asset.id.includes('MATIC') || asset.assetTicker === 'POL') {
          this.cryptoAssets.push(asset);
        }
      }
    });
  }

  private isAssetAccepted(asset: Asset): boolean {
    return (
      this.receiverAcceptedAssets.length === 0 ||
      this.receiverAcceptedAssets.some(
        (acceptedAsset) => acceptedAsset.id === asset.id,
      )
    );
  }

  private populatePayingAssetOptions() {
    this.currentOptionsSelects.payingAsset = [];

    this.addAssetGroupToOptions('PAYMENT_REPORT.fiat', this.fiatAssets);
    this.addAssetGroupToOptions(
      'PAYMENT_REPORT.cryptoAssets',
      this.cryptoAssets,
    );
  }

  private addAssetGroupToOptions(groupName: string, assets: Asset[]) {
    if (assets.length > 0) {
      this.currentOptionsSelects.payingAsset.push({
        name: this.translate.instant(groupName),
        value: 'disabled',
      });

      assets.forEach((asset: Asset) => {
        this.currentOptionsSelects.payingAsset.push({
          name: asset.assetTicker,
          value: asset.assetTicker,
        });
      });
    }
  }

  private setInitialPayingAsset() {
    this.payingAsset =
      this.receiverAcceptedAssets.length > 0
        ? this.receiverAcceptedAssets[0].assetTicker
        : 'BTC';
  }

  getOffRampAllSettings() {
    this.offRampService.getOffRampAllSettings().pipe(
      tap((response: any) => {
        this.currentUserOffRampSettings = [];
        response.forEach((setting: savedOffRampSettings) => {
          if (setting.isEnabled) this.currentUserOffRampSettings.push(setting);
        });
        this.currentUserOffRampSettings.forEach(
          (offRampSetting: savedOffRampSettings) => {
            this.currentOptionsSelects.currentOffRampSettings.push({
              name: offRampSetting.cryptoConnection.name || undefined,
              value: offRampSetting.cryptoConnection.id,
            });
          },
        );
        this.manualOffRampSettings.cryptoConnectionId =
        this.currentOptionsSelects.currentOffRampSettings[0]?.value || '';
      }),
      catchError((error:any) => {
        this.toastrFactory.unknownError(this.translate.instant('unknownError'));
        return EMPTY;
      })
    ).subscribe();
  }

  public backPreviousStep(nextStep: string) {
    this.step = nextStep;
  }

  getReceiverAcceptedAssets() {
    this.userService.getAcceptedAssetsById(this.user.id).pipe(
      tap((response: any) => {
        this.receiverAcceptedAssets = response.filter(
          (asset: any) =>
            !asset.id.includes('MATIC') || asset.assetTicker === 'POL',
        );
        this.populateCryptoAssets();
        this.updateMaskConfig();
        
      }),
      catchError((error:any) => {
        this.toastrFactory.unknownError(this.translate.instant('unknownError'));
        this.closeModal();    
        return EMPTY;
      })
    ).subscribe();
  }

  buildPaymentRequestDto() {
    const paymentRequestDto: PaymentRequestCreateDto = {
      amountExpectedNative: this.payment.controls['amount'].value,
      billDetails: {
        description: this.payment.controls['description'].value
          ?.trim()
          .substring(0, 17),
        invoiceReference: this.payment.controls['reference'].value?.trim(),
        receiverUserId: this.user.id,
      },
      blockchainAssetId: this.assetIdFormatterUtil.formatBlockchainAssetId(
        this.payingAsset || '',
      ),
      requester: {
        email: this.payment.controls['email'].value?.trim() || null,
        name: this.payment.controls['name'].value?.trim() || null,
        publicName: this.payment.controls['company'].value?.trim() || null,
      },
      convertToFiatPercentage: this.convertToFiatPorcentage / 100,
    };
    return paymentRequestDto;
  }

  getEllipsisText(maxCharacteres: number, text: string) {
    if (text?.length > maxCharacteres) {
      return `${text.substring(0, maxCharacteres)} ...`;
    } else {
      return text;
    }
  }

  public openPaymentIdModal(exchange: string) {
    this.showModal = true;
    this.modalMessage = exchange;
    this.paymentIdModal = true;
  }

  onEmailKeyup(): void {
    this.emailInputSubject.next(this.payment.controls['email'].value);
  }

  emailInput() {
    if (this.payment.controls['email'].valid) {
      this.userService.userIsValidatedWithDetails(this.payment.controls['email'].value).pipe(
        tap((response: any) => {
          if (response.isValidated) {
            this.showNameInput = false;
            this.name = response.userPublicName || '';
          } else {
            this.showNameInput = true;
          }    
        }),
        catchError((error:any) => {
          this.toastrFactory.unknownError(this.translate.instant('unknownError'));
          this.showNameInput = true;  
          return EMPTY;
        })
      ).subscribe();
    }
  }


  public closeModal(): void {
    this.removeQueryParams();
    this.router.navigateByUrl(this.router.url.split('?')[0]);
    this.onCloseModal.emit(false);
    this.step = 'payment-details';
    if (this.shortId) {
      this.successfullyTransaction.emit(true);
    }
  }

  removeQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      queryParamsHandling: 'merge',
    });
  }

  closeInfoModal() {
    this.showModal = false;
    this.paymentIdModal = false;
  }

  getCryptoName(assetName: string) {
    if (assetName.includes('USDC') || assetName.includes('USDT')) {
      return assetName;
    } else {
      return assetName.split('.', 1);
    }
  }

  formatPrice(value: any, afterConversion?: boolean) {
    let decimal = this.formatDecimalPlaces();

    const formatter = new Intl.NumberFormat(
      this.isCommaDecimal ? 'pt-BR' : 'en-US',
      {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      },
    );

    if (this.isCommaDecimal) {
      return formatter.format(value).trim().toString();
    }
    return formatter.format(value).trim();
  }

  formatDecimalPlaces(): number {
    return decimalPlacesFor[this.payingAsset || 'BTC'];
  }

  isFiat(payingAsset: string) {
    if (payingAsset == 'BRL' || payingAsset == 'EUR' || payingAsset == 'USD') {
      return true;
    }
    return false;
  }

  public isFraction(num: number): boolean {
    const fractionalPart = (num % 1).toString().split('.')[1];
    if (fractionalPart) {
      return true;
    }
    return false;
  }

  generatePlaceholderZerosForAsset(assetId: string = ""){
    return this.assetIdFormatterUtil.generatePlaceholderZerosForAsset(assetId);
  }
  
  isEmailInvalid(): boolean {
    return (
      (this.invalidPayerInfo && !this.payment.controls['email'].valid) ||
      this.invalidEmail
    );
  }

  isAmountInvalid(): boolean {
    return this.invalidPayerInfo || this.payment.controls['amount'].value == '';
  }

  toggleQrModal() {
    this.qrModal = !this.qrModal;
  }

  toggleConfirmationModal() {
    this.showConfirmationModal = !this.showConfirmationModal;
  }

  // Method to check if the current step is 'payment-details'
  isPaymentDetailsStep(): boolean {
    return this.step === 'paymentDetails';
  }

  // Method to check if the current step is 'payment-info'
  isPaymentInfoStep(): boolean {
    return !!this.payingAsset && this.step === 'paymentInfo';
  }

  // Method to check if the current step is 'report-payment'
  isReportPaymentStep(): boolean {
    return this.step === 'reportPayment';
  }

  // Method to check if the current step is the finish step
  isFinishStep(): boolean {
    // Assuming you have logic to determine if it's the finish step
    return this.step === 'finish';
  }
  // Method to check if the confirmation modal should be shown
  shouldShowConfirmationModal(): boolean {
    return this.showConfirmationModal;
  }

  // Method to check if the QR modal should be shown
  shouldShowQrModal(): boolean {
    return this.qrModal;
  }

}
