import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';
import FormatCurrency from 'src/app/utils/format-currency-utils';
import { assetContracts, binance, bnbAddress, btcAddress, contractABI, decimalPlacesFor, devEnvironment, environment, ethAddress, minutes, polyAddress, polygon, qrApi, secondaryQrApi } from 'src/environments/environment';
import { QuoteModel } from '../models/quote.model';

import { TranslateService } from '@ngx-translate/core';
import { ethers } from 'ethers';
import { NgxCurrencyInputMode } from 'ngx-currency';
import { catchError, EMPTY, firstValueFrom, tap } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';
import { EIP6963ProviderDetail } from 'src/app/types/eip-6369';
import { Web3 } from 'web3';
import { Asset } from '../models/asset.model';
import { optionSelectsModel } from '../models/option-select.model';
import { PaymentReportStepsModel } from '../models/payemnt-report-steps.model';
import { User } from '../models/user.mode';

import { ShepherdService } from 'angular-shepherd';
import StepOptionsButton from 'shepherd.js/src/types/step';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import { constructTutorialUrl } from 'src/app/utils/link-utils';
import RoundUpUtil from 'src/app/utils/round-up-utils';
import { AssetIdFormatterUtil } from 'src/app/utils/asset-id-utils';
import { AvatarService } from 'src/app/services/avatar.service';
import { subscribe } from 'diagnostics_channel';

// import { EthereumService } from 'src/app/services/ethereum.service';



@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})

export class PaymentComponent implements OnInit, AfterViewInit{
  
  @ViewChild('code') code!: ElementRef;
  @ViewChild('timeBar') timeBar!: ElementRef;

  public walletAddress:string = ""

  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() successfullyTransaction: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input('assets')assets!: any;
  @Input('selectedLanguage')selectedLanguage!: any;
  @Input('paymentModal')paymentModal!: boolean;
  @Input('isAuthenticated')isAuthenticated!: boolean;
  @Input('user')user!: any;
  @Input ('targetUser')targetUser!:User;
  @Input('paymentRequestInfo')paymentRequestInfo!: any;
  
  public step:string = '';
  public previousStep!:string;
  public alreadyFinished!:boolean;
  public assetValue: any = "";
  public paymentValue: any = "";
  public showConfirmationModal = false;
  public selectedUserType: string = 'individual';
  public selectedAsset:string = "BTC";
  public targetWallets:any;
  public walletError!:boolean;
  public browserLanguage = navigator.language
  public invalidAmount!:boolean;
  public payingAssetPrice!:any; 
  public usdcPrice = 1.00;
  public usdtPrice= 1.00;
  public savedpaymentValue:any;
  public savedAssetValue:any;
  public guestName!:string;
  public organization!:string;
  public email!:string;
  public invalidGuestInfo!: boolean;
  public hideCopyButton:boolean = false;
  public firstTime = true;
  public invalidQuote!: boolean;
  private getCurrencyInterval:any;
  public payingAsset:string = "USD";
  public network:string = "";
  public copied:boolean = false;
  public timerPercentage!:number;
  public youMayProceed:string = this.translate.instant('PAYMENT_REPORT.youMayProceedBut')
  public invalidEmail!:boolean;
  public currentConversion?:QuoteModel;
  public isMobile:boolean = window.screen.availWidth < 1100;
  public toggleBook:boolean = false;
  public qrError!:boolean;
  public endtime!:Date;
  public width!:number;
  public publicProfile!:boolean;
  public height!:number;
  public target!:User;
  public conversionValue!:number;
  public exchangeModal:boolean = false;
  public fullEmailModal:boolean = false;
  public qrModal:boolean = false;
  public showModal:boolean = false;
  public moreDetailsModal:boolean = false;
  public moreDetailsModalContent:string = '';
  public modalMessage!:string;
  public newPaymentId!:string;
  public transactionID:any = '';
  public seconds:number = minutes * 60;
  public totalTimeInMinutes:number = minutes;
  public timer!: any;
  private secondsCooldown!: any;
  public refreshTx!:any;
  public targetCurrencyQuote:any;
  public requestParams!:any;
  public requestPayment!:boolean;
  public nullPrice!:boolean;
  public receiverConnections:any[] = [];
  public enabledNetworks:any[] = [];
  public receiverAcceptedAssets:Asset[] = [];
  public assetOptions:any[] = [];
  public transferred!:boolean;
  public settlingSelectWidth!:number;
  public sendingSelectWidth!:number;
  public screenSize:number = window.screen.width
  public currentSettlingAssetName!:string; 
  public currentPayingAssetName!:string;
  private currentBackgroundPosition:number = 20;
  public refreshRatesTimerInSeconds:number = 15;
  public refreshQuotesTimerInSeconds:number = 15 * 60;
  public refreshRatesInterval!:any;
  public onlyOneAcceptedAsset!:boolean;
  public successWalletTransaction:boolean = false;
  public selectedWallet!:EIP6963ProviderDetail;
  public availableWallets:EIP6963ProviderDetail[] = [];
  public completedSteps:PaymentReportStepsModel = {
    confirmation: false,
    identification: false,
    overview: false,
    finished: false,
    contact: false
  }; 

  public clipboardPermissions!:PermissionStatus;
  public clipboardDenied!:boolean;
  public flippedRates!:boolean;
  public reference!:string;
  

  public behalfName!:string;
  public behalfCompany!:string;
  public behalfEmail!:string;
  public paymentId!:string;
  public metamaskLoading:boolean[] = [];
  public currentQuote!:QuoteModel;
  public currentDate!:string;
  public latestTransactions:any[] = [];
  public loadingTx!:boolean;
  public qrUrl = qrApi;
  public errorGettingPrices!:boolean;
  public isCommaDecimal = this.formatCurrency.isCommaDecimal(navigator.language);
  public additionalDetails:boolean = false;
  public customCurrencyMaskConfig:any;
  public woozaTranslations = {
    getReady : this.translate.instant('PAYMENT.REPORT.whoozaGetReady'),
    prepare: this.translate.instant('PAYMENT_REPORT.whoozaPrepareToComplete')
  } 
  currentOptionsSelects: {
    payingAsset: optionSelectsModel[],
    userType: optionSelectsModel[],
    currentOffRampSettings: optionSelectsModel[],
  } = {
    payingAsset: [],
    userType: [
       { 
        name: this.translate.instant('PAYMENT_REQUEST.payerIsAnIndividual'),
        value: 'individual'
      },
      { 
        name: this.translate.instant('PAYMENT_REQUEST.payerIsAnOrganization'),
        value: 'organization'
      },
    ],
    currentOffRampSettings: []
  };



  public seeingAssetPrice:boolean = false;

  public environment = environment;

  public polygon = polygon;

  public binance = binance;

  public areAUser:string = 'guest';

  public fiatAssets:any[] = [];

  public cryptoAssets:any[] = [];

  public queryParams:any;
  public commonParams:any;

    public payment: FormGroup = new FormGroup({
    guestName: new FormControl(this.guestName, [Validators.required,Validators.pattern('^.+$')]),
    company: new FormControl(this.organization, [Validators.required,Validators.pattern('^.+$')]),
    email: new FormControl(this.email, [Validators.required ,Validators.email, Validators.pattern(/\.[a-zA-Z0-9]+$/)]),
    asset: new FormControl(this.selectedAsset, Validators.required),
    targetId: new FormControl(null, Validators.required),
    amount: new FormControl(this.assetValue, [Validators.required]),
    targetAmount: new FormControl(this.paymentValue, [Validators.required]),
    receiverAddress: new FormControl(null, Validators.required),
    senderAddress: new FormControl(null, Validators.required),
    transactionID: new FormControl(this.transactionID, [Validators.required,Validators.pattern('^.+$')]),
    reference: new FormControl(null, Validators.maxLength(17)),
    description: new FormControl(null),
    status: new FormControl(),
    assetId:new FormControl(),
    });
  
    bookIconOpen: any;
    JWTToken: string = '';


  constructor(
    private changeDetector:ChangeDetectorRef,
    private auth: AuthService,
    public formatCurrency:FormatCurrency,
    public toastrService:ToastrService,
    public paymentService:PaymentService,
    public router: Router,
    public userService:UserService,
    private params: ActivatedRoute,
    private roundUpUtil:RoundUpUtil,
    public translate:TranslateService,
    private searchService:SearchService,
    private shepherdService: ShepherdService,
    private web3:Web3,
    private toastrFactory: ToastrFactoryService,
    private assetIdFormatterUtil: AssetIdFormatterUtil,
    private avatarService: AvatarService
    // private ethereumService: EthereumService
        ) {  }

        private tourStepLegend:any = undefined; 
        private nextButton:any = undefined;
        private backButton:any = undefined;
        private exitButton:any = undefined;
        private finishButton:any = undefined;
        private tourDefaults:any = undefined;

        private defaultTourButtons: {
          haveBackButton:any[],
          haveFinishButton:any[],
          onlyNextButton:any[]
        } = {
          haveBackButton : [],
          haveFinishButton:[],
          onlyNextButton : []
        }
      
        private currentStepId!:string |undefined;
      
        private tourMobilePositionCheck:any;
        private tourPositionDefault:any;
      
      

  async ngOnInit(): Promise<void> {

      this.paymentValue = undefined;
      this.isAuthenticated = await firstValueFrom(this.auth.isAuthenticated$);

      if (this.isAuthenticated) {
        this.JWTToken = await firstValueFrom(this.auth.getAccessTokenSilently());
      }
      
      if ( this.targetUser?.email ) {
        const targetNick = await firstValueFrom(this.searchService.generalSearch(this.targetUser.email)) as User[];

        this.target = targetNick[0];
      };

      await this.checkDirectPaymentReport();

      this.getReceiverAcceptedAssets();
      
      this.populateTourLegendAsAButton();
      this.populateTourButton();
      this.populateTourDefaults();
  } 

  // async initializeProvider() {
  //   const providers: any[] = [];
  
  //   const providerPromise = new Promise<any>((resolve) => {
  //     const listener = (event: Event) => {
  //       const eipEvent = event as any;
  //       console.log(eipEvent.detail);
  //       providers.push(eipEvent.detail);
  
  //       if (eipEvent.detail.info.name === "Exodus") {
  //         window.removeEventListener("eip6963:announceProvider", listener);
  //         resolve(eipEvent.detail);
  //       }
  //     };
  
  //     window.addEventListener("eip6963:announceProvider", listener);
  //   });
  
  //   window.dispatchEvent(new Event("eip6963:requestProvider"));
  
  //   const metaMaskProvider = await providerPromise;
  //   console.log(metaMaskProvider);
  //   console.log('memastkprovider')
  
  //   if (metaMaskProvider) {
  //     this.selectedWallet = metaMaskProvider as any;
  //     console.log("MetaMask provider:", this.selectedWallet);
  //   } else {
  //     console.warn("MetaMask not found among announced providers.");
  //   }
  // }
  

  ngAfterViewInit(): void {

    window.addEventListener("eip6963:announceProvider", (event: any) => {
      const walletProviderName = event.detail.info.name;
      if (walletProviderName === "MetaMask" || walletProviderName === "Trust Wallet" || walletProviderName === 'Coinbase Wallet') {        
        this.selectedWallet = event.detail;
      }

      if (walletProviderName === "MetaMask" || walletProviderName === "Trust Wallet" || walletProviderName === 'Coinbase Wallet') {        
        this.availableWallets.push(event.detail);
      }
    });


    window.dispatchEvent(new Event("eip6963:requestProvider"));
  
    if(this.selectedLanguage == undefined){
      this.selectedLanguage = localStorage.getItem('defaultLang');
    }

    this.updateMaskConfig();
  }
  
  @HostListener('window:resize', ['$event'])
    onResize(event:any) {
    this.isMobile = event.target.innerWidth < 1100
  }

  
  toggleBookIcon(open: boolean) {
    this.bookIconOpen = open;
  }

  populateTourButton() {
    this.nextButton = {
      //classes: 'shepherd-button-primary',
      text: () => {
        return this.translate.instant("TOUR.next")
      },
      action() {
        return this.next();
      }
    };
    this.exitButton = {
      classes: 'exit-button',
      text:() => {
        return this.translate.instant("TOUR.exit")
      }, 
      action: () => {
        this.shepherdService.complete();
        setTimeout(() => {
          this.toggleBookIcon(false);
        }, 100); 
      }
    };
    this.backButton = {
      classes: 'back-button secondary-button',
      text: () => {
        return this.translate.instant("TOUR.back")
      },
      action: () => {
        this.toggleBookIcon(true);
        return this.shepherdService.back();
      }
    } 
    this.finishButton = {
      classes: 'secondary-button',
      text: () => {
        return this.translate.instant("TOUR.finish")
      },
      action: () => {
        this.toggleBookIcon(false);
        return this.shepherdService.complete();
      }
    }

    this.defaultTourButtons.haveBackButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.nextButton
    ]

    this.defaultTourButtons.onlyNextButton = [
        this.tourStepLegend,
        this.exitButton,
        this.nextButton
    ]

    
    this.defaultTourButtons.haveFinishButton = [
      this.tourStepLegend,
      this.exitButton,
      this.backButton,
      this.finishButton
  ]

  }

  populateTourLegendAsAButton() {
    this.tourStepLegend = {
      text: () => {
        const tourObject = this.shepherdService.tourObject;
        const stepsLength = tourObject.steps.length;
        const currentStep = this.shepherdService.tourObject.getCurrentStep();
        let stepIndex = -1; 
      
        if (currentStep) {
          stepIndex = tourObject.steps.findIndex(step => step.id === currentStep.id); 
        }
      
        return `${stepIndex + 1} ${this.translate.instant('of').toLowerCase()} ${stepsLength}`; 
      },
      classes: "sheperd-steps-legend"
    }
  }

  // PAYMENT TOUR
  startPaymentTour() {

    const tourDefaults = {
      highlightClass: 'highlight'
    }

    const mobilePositionCheck = this.isMobile ? 'bottom' : 'right';
    const positionDefault = this.isMobile ? 'bottom' : 'bottom';

    this.shepherdService.defaultStepOptions = {
      scrollTo: { behavior: 'smooth', block: 'center' },
      cancelIcon: {
        enabled: false
      },
      canClickTarget: true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    }
    this.populateTourDefaults();
    const steps: StepOptionsButton.StepOptions[] = ([
      {
        id: "received",
        attachTo: {
          element: '#received',
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.onlyNextButton,

        text: this.translate.instant("TOUR.thisIsTheAmountChargedInItsOriginalCurrency")
      },
      {
        id: "paid",
        attachTo: {
          element: '#paid',
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.pleaseMakeSureToUseTheChosenNetworkWhenMakingTheTransaction")
      },
      {
        id: "rate",
        attachTo: {
          element: '#rate',
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.thisIsTheRateUsedToCalculateTheAmountToBePaid")
      },
      {
        id: "next-button",
        attachTo: {
          element: '#next-button', 
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,
        
        buttons: this.defaultTourButtons.haveFinishButton,

        highlightClass: tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.clickNextToContinueWithYourPayment")
      }
    ]);

    this.startStepTour(steps);
  }


  // CONFIRMATION PAYMENT TOUR
  confirmationPaymentTour() {

    const tourDefaults = {
      highlightClass: 'highlight'
    }

    const mobilePositionCheck = this.isMobile ? 'bottom' : 'right';
    const positionDefault = this.isMobile ? 'bottom' : 'bottom';

    this.shepherdService.defaultStepOptions = {
      scrollTo: { behavior: 'smooth', block: 'center' },
      cancelIcon: {
        enabled: false
      },
      canClickTarget: true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    }
    this.populateTourDefaults();
    const steps: StepOptionsButton.StepOptions[] = ([
      {
        id: "amount-received",
        attachTo: {
          element: '#amount-received',
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.onlyNextButton,

        text: this.translate.instant("TOUR.thisIsTheAmountChargedInItsOriginalCurrency")
      },
      {
        id: "amount-paid",
        attachTo: {
          element: '#amount-paid',
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.amountThatYouWillPay")
      },
      {
        id: "payment-deadline",
        attachTo: {
          element: '#payment-deadline',
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.youNeedToFinishPayingWithinThisTime")
      },
      {
        id: "current-rate",
        attachTo: {
          element: '#current-rate',
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.rateUsedToCalculateAmount")
      },
      {
        id: "payment-address",
        attachTo: {
          element: '#payment-address',
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.payToThisAddress")
      },
      {
        id: "confirmation-step",
        attachTo: {
          element: '#confirmation-step', 
          on: mobilePositionCheck
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,
        
        buttons: this.defaultTourButtons.haveFinishButton,

        highlightClass: tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.confirmYouHaveSent")
      }
    ]);

    this.startStepTour(steps);
  }


  populateTourDefaults() {
    this.defaultTourButtons.haveBackButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.nextButton
    ]

     this.defaultTourButtons.haveFinishButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.finishButton
    ]

    this.defaultTourButtons.onlyNextButton = [
        this.tourStepLegend,
        this.exitButton,
        this.nextButton
    ]

    this.tourMobilePositionCheck = this.isMobile ? 'bottom' : 'right';

    this.tourPositionDefault = 'bottom';

    this.tourDefaults = {
      scrollTo: { behavior: 'smooth', block: 'center', inline: 'center' }
    }

    this.shepherdService.defaultStepOptions = {
      scrollTo: this.tourDefaults,
      cancelIcon: {
        enabled: false
      },
      canClickTarget: this.isMobile ? false : true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    }  
  }
  
  private startStepTour(steps: StepOptionsButton.StepOptions[]) {
    steps.forEach(step => {
      if (!step.when) {
        step.when = {
          show: () => {
            const currentStepText = step.text;
            const shepherdObject = this.shepherdService.tourObject;
            const stepIndex = shepherdObject.steps.findIndex(currentStep => currentStep.id === step.id);

            if (this.isMobile) {
              const element = shepherdObject.steps[stepIndex].getElement();
              if (element) element.focus = () => { };
              element?.scrollTo();
            }

            if (stepIndex === steps.length - 1) {
              shepherdObject.steps[stepIndex].updateStepOptions({
                text: currentStepText + "<img src='/assets/white-nicky-logo.png' class='tour-logo'>"
              });
            }
          }
        };
      }
    });

    this.shepherdService.addSteps(steps);
    this.shepherdService.start();
  }


  updateMaskConfig (){
    this.customCurrencyMaskConfig = {
      align: "center",
      allowNegative: false,
      allowZero: false,
      decimal: this.isCommaDecimal ? ',' : '.',
      precision: this.getPrecision(),
      prefix: "",
      suffix: "",
      thousands: this.isCommaDecimal ? '.' : ',',
      nullable: false,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial
    }
  }

  formatTimer(secondsString: string): string {
    const sec_num = parseInt(secondsString, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (minutes < 10) {
      minutes = Number(`0${minutes}`);
    }
    if (seconds < 10) {
      seconds = Number(`0${seconds}`);
    }

    return ` ${ minutes >= 10 ? '' : '0' }${ minutes }:${ seconds.toString().length < 2 ? "0" + seconds : seconds}`;
  }

  
  async redirectToLogin() {
    if (this.requestPayment) {
      let url = "?paymentId=" + this.paymentId
      this.auth.loginWithRedirect({
        appState: {
          target: "/overview" + url
        }
      });
      return;
    }
    const receiverNick = await firstValueFrom(this.searchService.generalSearch(this.targetUser.email)) as User[];
    localStorage.setItem('targetShortId', receiverNick[0].shortId || '');
    
    this.auth.loginWithRedirect({
      appState: { target: "/overview" }
    });
  }


  async checkDirectPaymentReport() {
    try {
      this.commonParams = await firstValueFrom(this.params.params);
      this.queryParams = await firstValueFrom(this.params.queryParams);
      const shortId = this.commonParams?.['shortId'];
  
      if (shortId) {
        const targetUser = await firstValueFrom(this.searchService.getPublicProfileByShortId(shortId)) as User;
        this.target = targetUser;
        this.targetUser = targetUser;
        
        if (targetUser?.id) {
                    
          this.targetUser = targetUser;
 
          if(this.queryParams?.['amount']) {
            this.paymentValue = this.parseAmount(this.queryParams?.['amount']);
          }

          this.payingAsset = this.queryParams?.['settlementAsset'];

          if(this.queryParams?.['settlementAsset']){
            this.onlyOneAcceptedAsset = true;
          }
  
          const paymentControllers = this.payment.controls;
          paymentControllers['reference'].setValue(this.queryParams?.['reference']);
          paymentControllers['description'].setValue(this.queryParams?.['description']);
          this.reference =  this.queryParams?.['reference'];
        }
      }
    } catch (error) {
      console.error("Error checking direct payment report:", error);
    }
  }

  private parseAmount(amount:any) {
    amount = amount.trim().replace(/[^\d.,-]/g, '');

    const hasComma = amount.includes(',');
    const hasDot = amount.includes('.');

    if (hasComma && hasDot) {
        if (amount.indexOf(',') < amount.indexOf('.')) {
            amount = amount.replace(/,/g, '');
        } else {
            amount = amount.replace(/\./g, '').replace(',', '.');
        }
    } else if (hasComma) {
        amount = amount.replace(/,/g, '.');
    }

    const number = parseFloat(amount);

    if (isNaN(number)) {
        this.toastrService.error(this.translate.instant('invalidAmount'));
        this.closeModal();
        throw new Error('Invalid Amount');
    }

    return number;
}

pasteTransactionId() {
  this.tryToReadClipboard();
}

async tryToReadClipboard(onlyTesting?:boolean) {
  try {
    const transactionId = await navigator.clipboard.readText();
    this.transactionID = transactionId.trim();
    this.payment.controls['transactionID'].setValue(transactionId);
  } catch(e:any) {
    console.log(e);
    this.clipboardDenied = true;
    this.toastrService.error(this.translate.instant("permissionToReadFromYourClipboardHasBeenDenied"));
    return
    // this.privateWalletInput?.removeAttribute('readonly');
    // this.privateWalletInput?.removeAttribute('click');
    // this.privateWalletInput?.focus();
  }
}
  


  checkIfRequestedAssetIsFiat(){
    if( this.step === "overview") {
      return this.assets.filter( (asset:Asset) => (asset.assetTicker === this.payingAsset) && asset.isFiat).length > 0;
    }
    return;
  }

  // openMobileWallet() {
  //   const assetId = this.getAssetAndNetworkId();
  //   const assetContract = assetContracts[assetId as keyof typeof assetContracts];
  //   const contractAddress = assetContract.address;
  //   const chainId = assetContract.chainId;
  //   const receiverAddress = this.walletAddress;
  //   const amount = this.assetToWei(this.currentQuote.price, assetContract.decimals);
  //   try {
  //     window.location.href = `ethereum:${contractAddress}${chainId}/transfer?address=${receiverAddress}&uint256=${amount}`;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  payingAssetIsFiat(){
    return this.payingAsset != "BTC" && this.payingAsset != "ETH";
  }

  settlementAssetIsFiat(){
    return this.selectedAsset != "BTC" && this.selectedAsset != "ETH";
  }

  checkReceiverAcceptedAssets(){
   this.receiverAcceptedAssets;
    if(this.receiverAcceptedAssets.length === 1) {
      this.onlyOneAcceptedAsset = true;
    } 
    return this.receiverAcceptedAssets[0].assetTicker || "USD";
  }

  getPrecision(){
    return decimalPlacesFor[this.payingAsset];
  }

  async populateCryptoAssets(){
    if (! this.assets ) {
      this.assets = await firstValueFrom(this.paymentService.getAllAssets());
    }
    this.assets.forEach( (asset:Asset) => {
    if(!this.requestParams && this.receiverAcceptedAssets.length > 0 ){

        const userAcceptCurrentAsset = this.receiverAcceptedAssets.some(acceptedAsset => 
          acceptedAsset.id === asset.id
        );
          if(asset.isFiat){
            if(userAcceptCurrentAsset)
              this.fiatAssets.push(asset)
          } else {
            if(userAcceptCurrentAsset)
              this.cryptoAssets.push(asset)
          }
    } else {
      if(asset.isFiat){
        this.fiatAssets.push(asset)
      } else {
        if(!asset.id.includes("MATIC") && !asset.id.includes('TRC'))
        this.cryptoAssets.push(asset)
      }
    }});
    this.changingSelectedAssetsOnUi();
  }

  checkIfStepCanBeClicked(step: string) {
    const targetAmount = this.payment.controls['targetAmount'];
    const sendingAmount = this.payment.controls['amount'];
    const validAmount = targetAmount.valid && sendingAmount.valid && ( targetAmount.value.trim() != "" && sendingAmount.value.trim() != "");

    if (this.step === 'finish' || this.step === 'confirmation') {
      return;
    }

    if(step === 'confirmation' && ( !validAmount || this.testIfZero() )) {
      this.invalidAmount = true;
      return;
    };

    switch (step) {
      case 'payment':
        if(validAmount && (this.completedSteps.payment || (this.isAuthenticated ? this.completedSteps.identification : this.completedSteps.overview))) {
          this.step = step;
        }
        break;

      case 'overview':
          this.step = step;
        break;

      case 'your-information':
          if(this.completedSteps.contact)
            this.step = step;
        break;
        
        case 'guest':
        if (this.completedSteps?.identification) {
          this.step = step;
        }
        break;
      case 'confirmation':
        if (this.completedSteps.payment) {
          this.step = step;
        }
    }
  }

  getReceiverAcceptedAssets(){
    this.userService.getAcceptedAssetsById(this.targetUser.id).pipe(
      tap((response: any) => {
        this.receiverAcceptedAssets = response.filter((asset:any) => !(asset.id.includes("TRC")));

        this.receiverAcceptedAssets.forEach((fiat:any) => {
          this.currentOptionsSelects.payingAsset.push({
            name: fiat.assetTicker,
            value: fiat.assetTicker
          })
        })

        this.checkReceiverAcceptedAssets();
        this.getReceiverConnections();
        this.populateCryptoAssets();

        if( this.isAuthenticated && !this.user ) {
          this.userService.getUser().pipe(
            tap((response: any) => {
              this.user = response;    
            })
          ).subscribe();
        }
      }),
      catchError((error:any) => {
        this.toastrFactory.unknownError(this.translate.instant('unknownError'));
        this.closeModal();
        return EMPTY;
      })
    ).subscribe();
  }

  getCryptoName(assetName:string){
      return assetName.split(".", 1)
  }


  ifLargeDescription(){
    let description = this.payment.controls['description'].value;
    if(!description){
      return `${this.translate.instant('payment')}`
    }
    if(description.length > 40){
      return '...'
    } 
    return '';
  }

  async checkIfItsAnPaymentRequest() {
    if (!this.requestPayment) {
      this.commonParams = await firstValueFrom(this.params.queryParams)
        this.paymentId = this.commonParams['paymentId'];
        if (!this.paymentId) {
          this.handleNoPaymentId();
        }
        if (this.paymentId) {
          // this.paymentRequestInfo.successUrl = 'https://google.com';
          // this.paymentRequestInfo.cancelUrl = 'https://yahoo.com';
          // console.log(this.paymentRequestInfo);
          const request = this.paymentRequestInfo;
          if (request) {
            this.initializePaymentDetails(request);
            this.fillPaymentControls(request);
            this.setRequesterDetails(request);
            this.updateAsset();
            this.updateMaskConfig();
            this.step = 'overview';
            return;
          }
        }
    }
    this.setStep();
  }
  
  private handleNoPaymentId(): void {
    if (this.receiverAcceptedAssets.length > 0) {
      this.payingAsset = this.receiverAcceptedAssets[0].assetTicker || "USD";
      const settlementAssetParam = this.commonParams['settlementAsset'];
  
      if (settlementAssetParam) {
        this.checkSettlementAsset(settlementAssetParam);
      }
    }
  }
  
  private checkSettlementAsset(settlementAssetParam: string): void {
    const receiverSupportsAsset = this.receiverAcceptedAssets?.filter(
      (asset: Asset) => asset.assetTicker === settlementAssetParam
    );
  
    if (receiverSupportsAsset.length < 1) {
      this.toastrService.error(
        `${this.translate.instant('receiverDoesNotAccept')} ${settlementAssetParam}`
      );
      this.alreadyFinished = true;
      this.closeModal();
    } else {
      this.payingAsset = settlementAssetParam;
    }
  }

  public checkIFOnlyOneSettlementAsset() {
    return this.requestParams != undefined && (this.requestPayment || this.onlyOneAcceptedAsset); 
  }


  private initializePaymentDetails(request: any) {
    // this.payingAsset = ;
    this.formatPayingAsset(request.blockchainAssetId || "");
    this.onlyOneAcceptedAsset = true;
    this.requestParams = this.commonParams;
    this.paymentValue = this.roundUpUtil.roundUpAmount(request.amountNative, decimalPlacesFor[this.payingAsset]);
  }
  
  private fillPaymentControls(request: any) {
    this.payment.controls['targetAmount'].setValue(this.formatPrice(parseFloat(request.amountNative)));
    this.payment.controls['receiverAddress'].setValue(request.creator.email);
    this.payment.controls['asset'].setValue(request.blockchainAssetId);
    this.payment.controls['description'].setValue(request.bill.description || '');
    this.payment.controls['reference'].setValue(request.bill.invoiceReference || '');
  }
  
  private async setRequesterDetails(request: any) {
    const creator = request.creator;
    this.targetUser = request.creator;
  
    const requester = request.requester;
    this.behalfName = requester.name || '';
    this.behalfEmail = requester.email || '';
    this.behalfCompany = requester.name || '';

    const isAuthenticated = await firstValueFrom(this.auth.isAuthenticated$); 
    
    if ( !isAuthenticated ) {
      this.guestName = this.behalfName;
      this.organization = this.behalfName;
      this.email = this.behalfEmail;
      this.payment.controls['guestName'].setValue(this.behalfName);
      this.payment.controls['company'].setValue(this.behalfCompany);
      this.payment.controls['email'].setValue(this.behalfEmail);
    }

    this.requestPayment = true;
  }

  formatPayingAsset(blockchainAssetId:string){
    this.payingAsset = blockchainAssetId.split('.')[1];
  }

  public setStep(){
    if(!this.requestParams && this.step == ""){
      if(this.isAuthenticated){
          this.step = 'payment';
          this.changeTargetCurrency();
          this.refreshRatesTimerInSeconds = 15;
          this.setRatesTimeout();
          this.completedSteps.identification = true;
          return;
      } else {
        this.step = 'your-information'
      }
    }
  }

  sameAssets(){
    return this.selectedAsset == this.payingAsset
  }

  changingSelectedAssetsOnUi(){
    if(this.requestParams){
      this.setSelectedAssetsName(this.payingAsset, true);
    }
    this.cryptoAssets.filter((asset:any) => {
      if(asset.assetTicker == this.payingAsset){
        this.setSelectedAssetsName(asset, true)
      }
    })
    this.fiatAssets.filter((asset:any) => {
      if(asset.assetTicker == this.payingAsset){
       this.setSelectedAssetsName(asset, true)
      }
    })
    this.assetOptions.filter((asset:any) => {
      if(asset.assetTicker == this.selectedAsset){
       this.setSelectedAssetsName(asset)
      }
    })
  }

  setSelectedAssetsName(asset:any, settleAsset?:boolean){
    if(settleAsset){
      this.currentSettlingAssetName = asset.assetName;
    } else {
      this.currentPayingAssetName = asset.assetName;
    }
  }

  isNetworkAsset(){
    return this.selectedAsset != 'BTC' && this.selectedAsset != 'ETH' && this.selectedAsset != 'POL';
  }

  buildSelectionText(assetName: string, assetId: string): string {
    let formattedAssetName = assetName;

    if (assetName === 'Polygon Ecosystem Token') {
        formattedAssetName = 'Polygon';
    } else {
        formattedAssetName = assetName.replace(/\(Polygon\)/i, '').replace(/\(TRON\)/i, '').trim();
    }

    if (formattedAssetName === 'Ethereum') {
        formattedAssetName = 'Ether';
    }

    return `${assetId} (${formattedAssetName})`;
}

getWrongNetworkInforming() {
  const propertys = {
    assetName : this.currentPayingAssetName,
    networkName: this.translate.instant( this.formatNetworkName(this.selectedAsset).toLocaleLowerCase() + "Network" ),
  }
  return this.translate.instant('thisIsTheAssetAddressForSending', propertys)
}

  testIfZero(){
    let value = this.paymentValue;
    if(this.isCommaDecimal){
      value = this.paymentValue?.toString().replace(/\./g, '').replace(/\,/g, '.');
    } else {
      if(this.paymentValue?.toString().replace(/\,/g, '')){
        value = this.paymentValue?.toString().replace(/\,/g, '');
      } else {
        value = 0
      }
    }
    return value == 0 || isNaN(value);
  }

  startTimer(){
    this.timerPercentage = 0;

    if(this.sameAssets()){
      return;
    }

    this.clearTimer();
    
    this.getCurrencyInterval = setInterval('', (minutes * 60000))
    this.timer = setInterval(() => {
      const currentTime = this.getCurrentTime();
      const timeDifference = this.endtime.getTime() - currentTime;
      const remainingTimeInSeconds = timeDifference / 1000;
      const minutesDifference = remainingTimeInSeconds / 60;
    
      if (remainingTimeInSeconds <= 0) {
        this.refreshQuotesTimerInSeconds = 0;
        this.invalidQuote = true;
        this.step = 'invalid-quote';
        this.clearTimer();
        return;
      }
    
      this.refreshQuotesTimerInSeconds = remainingTimeInSeconds;
    
    }, 1000);

  }

  getCurrentTime(){
    return Date.now();
  }

  sendTransaction(){
    if(!this.transferred){
      this.transferred = true;
      return;
    } else {
      this.nextStep('finish')
    }
  }

  setRatesTimeout(){
    this.clearTimer();
    this.refreshRatesInterval = 15;
    this.refreshRatesInterval = setInterval((timer:any) => {
      this.refreshRatesTimerInSeconds--;
      if (this.refreshRatesTimerInSeconds <= 0) {
        // this.invalidQuote = true;
        if(!this.sameAssets() && this.step === 'payment'){
          this.getCurrencyValue(true);
        }
        this.clearTimer();
      }
    }, 1000);
  }

  clearTimer(){
    clearInterval(this.refreshRatesInterval)
    clearInterval(this.timer);
    clearInterval(this.getCurrencyInterval);
    clearInterval(this.refreshTx)
    clearInterval(this.secondsCooldown)
  }

  refreshValues(){
    this.clearTimer();
    // this.startTimer();
  }

  getReceiverConnections() {
    
    this.userService.getReceiverConnections(this.targetUser.id).pipe(
      tap(async (response: any) => {
        const acceptedAssetsByExchange = await firstValueFrom(this.userService.getAcceptableAssetsByExchange());
        response.forEach(async (asset: any) => {          
          try {

          this.receiverConnections.push(asset);

          this.pushConnection(asset);

          if (this.assetOptions.length > 1) {

            this.assetOptions.sort((a, b) => {
              const result = a?.assetTicker.localeCompare(b?.assetTicker);

              if (result === 0) {
                return a?.assetTicker.localeCompare(b?.assetTicker);
              }

              return result;
            });

          }

          this.selectedAsset = this.assetOptions[0].assetTicker;
          this.changingSelectedAssetsOnUi();
          
          } catch (e) {
            console.log(e);
            return;
          }
        });

        this.checkIfItsAnPaymentRequest();

      }),
      catchError((error:any) => {
        console.log(error)
        return EMPTY;
      })
    ).subscribe();
  }

  pushConnection(connection:any){
    const hasAsset = this.assetOptions.some(assetConnection => assetConnection?.assetTicker === connection.assetTicker);
    if (!hasAsset){
      this.assetOptions.push(connection)
    }
  }

  updateAsset(itsFromInput?: boolean, itsFromFunction?: boolean) {
    if (itsFromInput) {
      this.invalidQuote = false;
    }

    if (this.paymentValue) {
      let asset = parseFloat(this.paymentValue.toString().replace(/\,/g, ''));
      let callValue = asset /  this.conversionValue;
      this.assetValue = this.roundUpUtil.roundUpAmount(callValue , this.currentConversion?.from.decimalPrecisionUI || decimalPlacesFor[this.payingAsset]); 
      this.assetValue = this.formatPrice(this.assetValue, true)
      this.payment.controls['targetAmount'].setValue(this.formatPrice(this.paymentValue.toString(), true))
      this.payment.controls['amount'].setValue(this.formatPrice(this.assetValue.toString(), true))
    }
  }

  updateSettlementAsset(itsFromInput?:boolean) {
    if(this.paymentValue == "NaN" || this.assetValue == "NaN"){

      this.assetValue = '';
      this.paymentValue = '';
      this.payment.controls['targetAmount'].setValue("")
      this.payment.controls['amount'].setValue("")
      
    }

    if(itsFromInput){
      this.invalidQuote = false;
    }


    if (this.assetValue) {
      let asset = parseFloat(this.assetValue.toString().replace(/\,/g, ''));
      this.paymentValue = asset * this.conversionValue;
      this.paymentValue = this.formatPrice(this.paymentValue, true)
      this.payment.controls['targetAmount'].setValue(this.paymentValue, true)
      this.payment.controls['amount'].setValue(this.formatPrice(this.assetValue.toString(), true))

    } else {

      this.assetValue = '';
      this.paymentValue = '';
      this.payment.controls['targetAmount'].setValue("")
      this.payment.controls['amount'].setValue("")
    }

  }

  isFiat(){
    return !(this.payingAsset === "BTC" || this.payingAsset === "ETH")
  }


  getCurrencyValue(currencyHasChanged?:boolean) {
    let currentAssetValue = this.payingAssetPrice;
    let payingAsset = this.assetIdFormatterUtil.formatBlockchainAssetId(this.payingAsset || "");

    let selectedAsset = this.assetIdFormatterUtil.formatBlockchainAssetId(this.selectedAsset);
    
    if(this.requestParams){
      payingAsset = this.paymentRequestInfo.blockchainAssetId;
    } 

      this.paymentService.getPrice(selectedAsset, payingAsset).pipe(
        tap((response: any) => {
          this.currentConversion = response;
          this.paymentValue = this.paymentValue;
          if(response.price == null){
            response.price = 1;
            if(this.step == 'payment'){
              this.toastrErrorGettingPrices();
            }
            this.errorGettingPrices = true;
          } 
          
          if(currencyHasChanged){
            this.refreshRatesTimerInSeconds = 15;
            this.setRatesTimeout()
          }

          this.testUpdatedAndOutdatedValues(currentAssetValue, response);
        }),
        catchError((error:any) => {
          console.log(error);
          return EMPTY;
        })
      ).subscribe();
  }

  testUpdatedAndOutdatedValues(currentAssetValue?:any, response?:any){

    if (!this.firstTime && this.payingAssetPrice) {

      if (this.payingAssetPrice != response.price) {

          this.payingAssetPrice = response.price
          this.conversionValue = this.payingAssetPrice
          this.targetCurrencyQuote = "";

          if (currentAssetValue) {

            if (!(this.paymentValue == "" || this.assetValue == "") && currentAssetValue != this.payingAssetPrice) {
              // this.invalidQuote = true;
            }

          }
      this.updateAsset(false, true)
      return;
    }}
    if (this.payingAssetPrice != response.price) {
      this.payingAssetPrice = response.price;
      if (this.firstTime || (!this.payingAssetPrice)) {
        this.chooseUserWallet(false, this.payingAssetPrice);
        this.firstTime = false
      }
    }
  }

  getOptionName(asset:any){
    let formatedAssetName = asset.assetName.replace('(Polygon)', '').replace('(TRON)', '');
    if (formatedAssetName === 'Ethereum') {
      formatedAssetName = 'Ether'
    }
    return formatedAssetName + ' (' + asset.assetTicker + ')'
  }

  formatPhrase(firstPart:string, secondPart:string){
    let mustEllipsis; 
    if(!firstPart){
      firstPart = ""
      return secondPart;
    }
    if(firstPart?.length > 40){
      mustEllipsis = true;
    }
    if(this.selectedLanguage == "pt-br" || this.selectedLanguage == "es"){
      return `${secondPart} ${firstPart} ${(mustEllipsis ? "..." : '')}`
    } else {
      return `${firstPart}${(mustEllipsis ? "..." : '')} ${secondPart}`  
    }
  }

  changeTargetCurrency() {
    let selectedAsset:string;
    this.targetCurrencyQuote = "";

    let currentAssetValue = this.payingAssetPrice;

    let payingAsset = this.assetIdFormatterUtil.formatBlockchainAssetId(this.payingAsset || "");
    
    if (this.selectedAsset === "POL") {
      this.network = 'MATIC';
    }

    selectedAsset = this.formatNetwork();
    this.refreshRatesTimerInSeconds = 15;
    
    if(this.requestParams){

      payingAsset = this.paymentRequestInfo.blockchainAssetId;

    }

    this.paymentService.getPrice(selectedAsset, payingAsset).pipe(
      tap((response: any) => {
        this.currentConversion = response;
        if(response.price == null){
          if(this.step == 'payment'){
            this.toastrErrorGettingPrices();
          }
          this.errorGettingPrices = true;
            if(selectedAsset == payingAsset){
              this.nullPrice = false;
              response.price = 1;
            } else {
              this.nullPrice = true;
              response.price = 0;
            }
        } else {
          this.nullPrice = false;
        }
        this.updateMaskConfig();
        this.testUpdatedAndOutdatedValues(currentAssetValue, response);
        if(this.firstTime){
          this.checkIfItsAnPaymentRequest()
        }
        return      
      }),
      catchError((error:any) => {
        console.log(error);
        return EMPTY;
      })
    ).subscribe();
  }


  validNetwork(){

    if((this.selectedAsset != 'BTC' && this.selectedAsset != 'ETH' && this.selectedAsset != "POL") && this.network == ''){

      return false;

    } 

    return true;
  }

  chooseUserWallet(itsFromInput:boolean, value?:number){
    this.enabledNetworks = [];

    this.selectedNetwork();

    if(value)
      this.conversionValue = value;

    this.updateAsset(true);
  
    this.refreshRatesTimerInSeconds = 15;

    this.getUserWallets(itsFromInput);
  }

  private selectedNetwork() {
    if(this.selectedAsset == "USDC" || this.selectedAsset == "USDT"){

      this.enabledNetworks = [];
      
      const enabledNetworks: string[] = [];
      
      this.receiverConnections.forEach( connection => {

          if(connection.assetTicker == this.selectedAsset){

            if(connection.id.includes("ETH") && !enabledNetworks.includes('ETH')){

              enabledNetworks.push('ETH');

            } else if (connection.id.includes("MATIC") && !enabledNetworks.includes('MATIC')) {

              enabledNetworks.push('MATIC');

            } else if(!enabledNetworks.includes('TRC')) {
              enabledNetworks.push('TRC');
            }
          }
        });

        enabledNetworks.sort((a, b) => {
          const result = this.getNetworkName(a).localeCompare(this.getNetworkName(b));

          return result;
        });

        this.enabledNetworks = enabledNetworks;

        if(!this.enabledNetworks.includes(this.network)) {
          // this.network = this.enabledNetworks[0];
          this.network = ""
        }
          
    }
  }

  formatNetworkName( network:string ) {
    switch ( network ) {
      case 'ETH':
        return 'Ethereum';
      case 'MATIC':
        return 'Polygon';
      case 'BTC':
        return 'Bitcoin';
      case 'TRC':
        return 'TRON';
      default:
        return '';
    }
  }

  getNetworkName(networkId:string){
    switch (networkId) {
      case "ETH":
        return 'Ethereum';
      case "MATIC":
        return 'Polygon'
      case "TRC":
        return 'TRON'
      default:
        return ''
    }
  }

  getSelectedAssetNetwork() {
    if(this.selectedAsset === "USDC" || this.selectedAsset === 'USDT') {
      return this.network;
    } 

    if (this.selectedAsset === "POL") {
      return 'MATIC'
    }

    return this.selectedAsset || '';
  }

  getWalletExchange(onlyWalletInfo?:boolean){
    let walletProviderName = this.targetWallets?.cryptoAddress?.cryptoDataSource;

    const termsPhrasePropertys = {
      walletProviderName: this.translate.instant(walletProviderName),
      assetName: this.currentPayingAssetName
    };

    let phrase = ''

    if(walletProviderName === 'Manual') {
      phrase = `${this.translate.instant('privateWalletProviderInform', termsPhrasePropertys) }`;
    } else {
      phrase = `${this.translate.instant('exchangeWalletProviderInform', termsPhrasePropertys) }`;
    }

    if ( onlyWalletInfo ) {
      return phrase;
    } 

    return `${phrase} ${this.translate.instant('nickyIsAPeerToPeer')}`

  }

  getSuccessPhrase() {
    return this.translate.instant('payment_flow.youSentToReceiverTheOweAmount', { 
        receiver: this.targetUser.publicName, 
        payingAsset: this.selectedAsset,
        sendAmount: this.formatPrice(this.currentQuote?.price, (this.selectedAsset === 'BTC' || this.selectedAsset === 'ETH') ? true : false),
        settlementAsset: this.payingAsset, amountOwed: this.formatPrice(this.savedpaymentValue, (this.payingAsset == 'BTC' || this.payingAsset == 'ETH'), true)
      }
    )
  }

  getUserWallets(itsFromInput:boolean, quoteInfo?:any){

    if(!this.validNetwork()) {
      this.changeTargetCurrency();
      return;
    }

    const asset = this.formatNetwork();

    this.paymentService.getUserWallets(this.targetUser.email, asset).pipe(
      tap((response: any) => {
        this.targetWallets = response;

        if(this.targetWallets.cryptoAddress == null){
          const toastrMessage = this.translate.instant('userTemporarilyUnableToReceivePayments', { 
            assetName: this.getFormatedAssetForWarnings()
          })
          this.toastrService.error(
          toastrMessage,
          '', 
          {
            enableHtml: true
          }
          );
          this.walletError = true;
          this.targetWallets = undefined;
          // this.alreadyFinished = true;
          // this.closeModal()
        }

        this.chooseWallet(itsFromInput);

        if(quoteInfo){
          this.getQuotes(quoteInfo);
        }

        this.walletError = false;

      }),
      catchError((error:any) => {
        let thrownError:boolean = false;
        const errorToastrTimeMs = 5000;
        const errorMessage = error?.error;
        if(errorMessage?.detail?.includes('No user found for this Nick:')){
          this.toastrService.error(this.translate.instant('PAYMENT_REPORT.thisPaymentRequestIsNotValid'),'',{
            timeOut: errorToastrTimeMs
          }),
          thrownError = true;
        }

        if(!thrownError){
          const toastrMessage = this.translate.instant('userTemporarilyUnableToReceivePayments', { 
            assetName: this.getFormatedAssetForWarnings()
          })
          this.toastrService.error(
          toastrMessage,
          '', 
          {
            enableHtml: true
          }
          );
          this.walletError = true;
          this.targetWallets = undefined;
          // this.alreadyFinished = true;
          // this.closeModal()
        }

        this.chooseWallet(itsFromInput);


        return EMPTY;
      })
    ).subscribe();
  }

  getFormatedAssetForWarnings():any{
    let networkName = this.formatNetworkName(this.assetIdFormatterUtil.formatBlockchainAssetId(this.selectedAsset).split(".")[0]);
    if(this.selectedAsset === "USDT" || this.selectedAsset === "USDC") {
      networkName = this.formatNetworkName(this.network);
    }
    if (networkName) {
      return `${this.selectedAsset} (${this.translate.instant(networkName.toLowerCase() + "Network")})`;
    } 
    return `${this.selectedAsset}`
  }

  exchangeError (serverError:any) {
    if (serverError.error.Message.includes('Kraken') || serverError.error.ErrorCode.includes('KrakenError')) {
      this.toastrService.error(
        `${this.translate.instant('krakenMessage')} "${serverError.error.Message}" ${this.translate.instant('pleaseAccess')}`,
        this.translate.instant('krakenError'),
        {
          enableHtml: true,
          timeOut: 10000
        })
    } else {
      this.toastrService.error(`${serverError.error?.Message.includes('Exchange') ? 'Exchange Error -' : ''} - ${serverError.error?.Message.replace('System.Exception').split('\r')[0]}`,'',{
        timeOut: 10000
      })
    }
  }


  finishFlow(){
    this.alreadyFinished = true;
    this.closeModal();
  }

  getLockedRateToolTip(){
    return this.translate.instant('lockedRateIsTheGuaranteed', { payingAsset: this.payingAsset, settlementAsset: this.selectedAsset, lineBreak: '\n\n' } )
  }

  getCoinMarketTooltip() {
    return this.translate.instant('thisConversionRatesComesFrom', { payingAsset: this.payingAsset, settlementAsset: this.selectedAsset, lineBreak: '\n\n' } )
  }

  getNetwork(){
    if(this.selectedAsset == "USDT" || this.selectedAsset == "USDC"){
      if(this.network == "MATIC"){
        return 'MATIC'
      }
      if(this.network == "ETH"){
        return 'ETH'
      }
      return '';
    } else {
      return ''
    }
  }

  formatNetwork(){
    if(this.selectedAsset == 'BTC' || this.selectedAsset == 'ETH'){
      return this.assetIdFormatterUtil.formatBlockchainAssetId(this.selectedAsset);
    } else if (this.selectedAsset == 'POL') {
      return this.assetIdFormatterUtil.formatBlockchainAssetId(this.selectedAsset);
    } else {
      this.selectedNetwork()
      return (this.network || 'ETH') + "." + this.selectedAsset
    }
  }

  formatPaymentValue(){
    if(this.isCommaDecimal){
      return parseFloat(this.paymentValue.toString().replace(/\./g, '').replace(/\,/g, '.'))
    } else {
      return parseFloat(this.paymentValue.toString().replace(/\,/g, ''))
    }
  }

  emailInput(){
    if(this.email.trim() != " "){
    if(!this.email[0]?.match(/^[a-zA-Z0-9]+$/)){
      this.invalidGuestInfo = true;
      this.invalidEmail = true;
    } else {
      this.invalidEmail = false;
    }}
    this.email = this.email?.replaceAll(" ", "");
    this.payment.controls['email'].setValue(this.email)
  }

  getQuote(){
    let paymentValue;
    let selectedAsset;
    let assetValue;
    if(!this.validNetwork())
      return;
    
    paymentValue = parseFloat(this.paymentValue.toString().replace(/\,/g, ''))
    assetValue = parseFloat(this.assetValue.toString().replace(/\,/g, ''))
    
    selectedAsset = this.formatNetwork();

    const quoteInfo = {
      paymentValue: paymentValue,
      payingAsset: this.assetIdFormatterUtil.formatBlockchainAssetId(this.payingAsset || ""),
      selectedAsset: selectedAsset
    }

    this.getUserWallets(false, quoteInfo);
  }

  getQuotes(quoteInfo:any){

    this.paymentService.getQuotes(quoteInfo.paymentValue, quoteInfo.payingAsset, quoteInfo.selectedAsset).pipe(
      tap((response: any) => {
          
        this.invalidQuote = false;
        
        const validEndTimeDate = new Date(response.validEndTime);

        this.checkIfQuoteIsValid(validEndTimeDate);

        this.endtime = validEndTimeDate;

        this.currentQuote = response;
        
        response.price = this.roundUpUtil.roundUpAmount(response.price, this.currentQuote.to.decimalPrecisionUI);

        this.currentQuote.price = response.price;

        this.previousStep = this.step; 

        this.startTimer();

        this.openWallet();

        this.step = 'confirmation';
      }),
      catchError((error:any) => {
        this.toastrFactory.unknownError(this.translate.instant('unknownError'));
        this.alreadyFinished = true;
        this.closeModal();
        return EMPTY;
      })
    ).subscribe();
  }

  copySendingAmount() {
    const numberString = this.currentQuote?.price.toString(); 
    navigator.clipboard.writeText(numberString).then(() => {
      this.toastrFactory.success(this.translate.instant('sucessfullyCopiedToClipboard'));
    }).catch(err => {
        console.error('Error copying to clipboard:', err);
    });
  }

  checkIfQuoteIsValid (validEndTimeDate:Date) {
    const userTime = new Date();
    if(validEndTimeDate > userTime ) {
      const timeDifference = validEndTimeDate.getTime() - userTime.getTime();
      const minutesDifference = timeDifference / (1000 * 60);
      
      this.totalTimeInMinutes = Math.round(minutesDifference);
      this.refreshQuotesTimerInSeconds = minutesDifference * 60;
    } else {
      this.invalidQuote = true;
      this.step = 'invalid-quote'
    }
  }
 
  closeInfoModal() {
    this.showModal = false;
    this.exchangeModal = false;
  }

  public openExchangeModal(message:string){
    if(message == "transactions"){
      this.modalMessage = `${this.translate.instant('PAYMENT_REPORT.weHaveRetrievedAllTheTransactions')} ${minutes} ${this.translate.instant('PAYMENT_REPORT.minutesAgo')}`
    } else {
      this.modalMessage = message;
    }
    this.showModal = true;
    this.exchangeModal = true;
    this.hideCopyButton = true;
  }

  fillTimeLapseBar(currentTime:number, totalTime:number){
    const percentage = (currentTime / totalTime) * 100;
    return `linear-gradient(90deg,  rgba(120, 175, 0, 1) ${percentage}%,  rgba(28, 28, 28, 0.1) ${percentage}%)`
  }

  chooseWallet(itsFromInput:boolean) {
    this.conversionValue = this.payingAssetPrice
    this.invalidQuote = false;
    
    this.resetValueFields();

    if(itsFromInput){

      this.changeTargetCurrency();

    }
  }

  checkIfItsAGuest() {
    if(!this.isAuthenticated)
    return
  }

  resetValueFields(){
      if (this.targetWallets?.cryptoAddress?.blockchainAsset?.assetTicker == this.selectedAsset) {
        this.payment.controls['assetId'].setValue(this.targetWallets.cryptoAddress.blockchainAsset.id)
        this.walletAddress = this.targetWallets.cryptoAddress.address
      }
  }

  formatDecimalPlaces(isAsset?: boolean, payingAsset?: boolean | 'skip'): number {
    if(payingAsset){
      return decimalPlacesFor[this.payingAsset];
    } else {
      return decimalPlacesFor[this.selectedAsset];
    }
  }

  formatPrice(value: string | number, isAsset?: boolean, payingAsset?:boolean, print?:boolean) {

    let valueToString = value?.toString();
    
    if (!this.isCommaDecimal) {
      value = parseFloat(valueToString?.replaceAll(",",""));
    } else {
      if (valueToString?.includes('.') && Number.isNaN(Number(value))) {
       valueToString = valueToString?.replaceAll('.', '')
      }
      value = parseFloat(valueToString?.replace(',','.'));
    }
    
    let decimal = this.formatDecimalPlaces(isAsset, payingAsset);
    
    const formatter = new Intl.NumberFormat(this.isCommaDecimal ? "pt-BR" : "en-US", {
      maximumFractionDigits:  decimal,
      minimumFractionDigits:  decimal,
    });
    
    const finalValue = formatter.format(value).trim();

    return finalValue === "NaN" ? '0' : finalValue;
  }

  clearTx(){
    this.payment.controls["transactionID"].setValue(undefined);
    this.transactionID = "";
  }


  public nextStep(nextStep:string){
    this.qrError = false;
    this.copied = false;
    this.changingSelectedAssetsOnUi();

    if(this.step == 'your-information'){
      const invalidEmail =  (!this.payment.controls['email'].valid || this.invalidEmail)
      if( this.selectedUserType == "individual" && ( !this.payment.controls['guestName'].valid || invalidEmail)){
        this.invalidGuestInfo = true;
        return;
      }
      if( this.selectedUserType == "organization" && ( !this.payment.controls['company'].valid || invalidEmail )){
        this.invalidGuestInfo = true;
        return;
      }
      this.invalidGuestInfo = false 
  }

    if(nextStep == 'finish'){
      this.payment.controls['assetId'].setValue(this.formatNetwork())
      this.finishTransaction();
      this.previousStep = 'finish';
      return;
    }

    if(nextStep == "payment"){
      this.changeTargetCurrency();

      if(this.errorGettingPrices){
        this.toastrErrorGettingPrices();
      } else {
        this.seconds = minutes * 60;
      }
    }

    if (this.step == 'confirmation' && this.previousStep != 'finish') {

      let transactionID = this.payment.controls['transactionID'].value;
      if (transactionID == null || transactionID.trim() == '' || transactionID == undefined) {
        this.payment.controls['transactionID'].markAsTouched();
      }
      if(!this.payment.controls['transactionID'].valid){
        return;
      }
      this.clearTimer();
    }
    
    if(this.step == 'payment'){
      if(this.selectedAsset != 'BTC' && this.selectedAsset != 'ETH' && this.selectedAsset != 'POL' && this.network == ""){
        this.toastrService.warning(this.translate.instant('PAYMENT_REPORT.youMustSelectANetworkForThisAssets'))
        return;
      }

      if (!this.targetWallets?.cryptoAddress?.address) {
        return;
      }

      if( this.payment.controls['targetAmount'].valid && this.payment.controls['amount'].valid && (this.payment.controls['targetAmount'].value.trim() != "" && this.payment.controls['amount'].value.trim() != "")){
        this.savedpaymentValue = this.paymentValue.toString().replace(/\,/g, '');
        this.savedAssetValue = this.assetValue.toString().replace(/\,/g, '');
        this.invalidAmount = false;
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() - minutes);
        this.currentDate = currentDate.toISOString();
        this.getQuote();
      } else {
        this.invalidAmount = true;
        return;
      }
    }

    if(nextStep == 'report-payment'){
      this.alreadyFinished = false;
    }

    if(nextStep == 'payment-info' && (this.requestParams != undefined || this.queryParams['reference'] && this.queryParams['description'])){
      if(this.payment.controls['description'].value != undefined && this.payment.controls['reference'].value != undefined){
        this.changeTargetCurrency();

        nextStep = 'payment'

        if(this.errorGettingPrices){

          this.toastrErrorGettingPrices();

        }
      }
    }

    this.invalidGuestInfo = false;

    this.previousStep = this.step;
    this.step = nextStep;  

    if (this.step === 'confirmation') {
      setTimeout(() => {
        const profileLink = document.getElementsByClassName('user-profile-link');
        if (profileLink.length > 0) {
          (profileLink[0] as HTMLElement).onclick = () => {
            this.publicProfile = true;
          };
        }
      }, 1000);
      
    }

    if(this.step == 'payment'){
      this.refreshRatesTimerInSeconds = 15;
      this.setRatesTimeout();
    }

    switch (this.previousStep) {
      case 'payment':
        this.completedSteps.payment = true;
        break;

      case 'overview':
        this.completedSteps.overview = true;
        break;

      case 'your-information':
        this.completedSteps.contact = true;
        break;

      case 'guest':
        this.completedSteps.identification = true;
        break;

      case 'confirmation':
        this.completedSteps.confirmation = true;
        break;
    }
  }

  public itsFixedSettlementAsset () {
      return (this.requestParams != undefined && this.requestPayment) || this.queryParams['settlementAsset']
  }

  async openWallet(){
    if(!devEnvironment) {
      return
    }
    // if(this.isMobile) {
    //   let url
    //   switch (this.selectedAsset) {
    //     case 'ETH':
    //       url = `ethereum:${this.walletAddress}@1?value=${this.assetToWei(this.currentQuote?.price, 18)}`;
    //       location.href = url;
    //     break;
    //     case 'BTC':
    //       url = `bitcoin:${this.walletAddress}?amount=${this.currentQuote?.price}&value=${this.currentQuote?.price}`;
    //       location.href = url;
    //   }
    // } 
  }

  async testNetworkId() {
    console.log(await window.ethereum.request({ method: "eth_eth_chainId" }));
  }

  public startPaymentWallet( selectedWallet:EIP6963ProviderDetail ) {

    const noTypeOfWindow = window as any;

    // if(this.selectedWallet.info.name === "Exodus") {
    //   this.selectedWallet.provider = noTypeOfWindow?.exodus?.ethereum;
    //   this.selectedWallet.info = selectedWallet.info;
    // } else {
    this.selectedWallet = selectedWallet;
    // }

    this.testMetamask();
  }

  getNoTypeWindow() {
    return window as any
  }

  async testMetamask() {
    
    if(!this.selectedWallet) {
      window.open(
        'https://metamask.io/download/',
        '_blank' 
      );
      return;
    }

    let accounts = await this.selectedWallet.provider.request({ method: "eth_requestAccounts" }) as any;
  
    const assetId = this.getAssetAndNetworkId();

    const selectedAssetChainId = assetContracts[assetId as keyof typeof assetContracts].chainId;
    let provider = this.selectedWallet.provider;

    if (this.selectedWallet.info.name === 'Exodus') {
      await this.getNoTypeWindow().exodus.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: selectedAssetChainId }],
      })

      accounts = await this.getNoTypeWindow().exodus.ethereum.request({ method: "eth_requestAccounts" }) as any;

      provider = this.getNoTypeWindow().exodus.ethereum

    }

    const walletChainId = await this.selectedWallet.provider.request({ method: "eth_chainId" });

    if (walletChainId === selectedAssetChainId) {
      if (this.selectedAsset === "ETH" || this.selectedAsset === "POL") {   
        this.metamaskLoading.push(true); 
        provider
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: accounts[0],
              to: this.walletAddress,
              value: this.assetToWei(this.currentQuote?.price, 18),
            },
          ],
        }).then((txHash:any) => {
          const transactionId = txHash;
          this.updateTransactionId(transactionId);
          this.successWalletTransaction = true;
          this.metamaskLoading.pop();
          this.nextStep('finish')
        }
        )
        .catch((error:any) =>  {console.error(error), this.metamaskLoading.pop(); });
        return;
      } else {
        this.metamaskTokenTransaction();
      }
    } else {
      this.toastrService.error(
        this.translate.instant('ensureMetaMaskNetworkConfigured', {
          networkName: this.formatNetworkName(this.network || this.selectedAsset),
          walletExtension: this.selectedWallet.info.name
        }) , '',
        {
          disableTimeOut: true
        }
      );
    }

    
  }

  async metamaskTokenTransaction() {

    let account = await this.selectedWallet.provider.request({ method: "eth_requestAccounts" });

    const assetId = this.getAssetAndNetworkId();
    const contract:any = assetContracts[assetId  as keyof typeof assetContracts] ;
    // const balance = await this.getTokenBalance(account[0], contract.address);

    this.metamaskLoading.push(true);
    this.transferToken(this.walletAddress, contract.address, contract.decimals).then((response:any) => {
      const transactionId = response.hash
      this.updateTransactionId(transactionId);
      this.successWalletTransaction = true;
      this.metamaskLoading.pop();
      this.nextStep('finish')
    }
    )
    .catch((error:any) => {
      this.metamaskLoading.pop();
      console.log(error);

      if( error['reason']?.includes('rejected') ) {
        this.toastrService.error(this.translate.instant('theTransactionHasBeenRejectedInMetamask', {
          walletExtension: this.selectedWallet.info.name
        }), '' , {
          disableTimeOut: true
        });
        return;
      }

      if(error['reason']?.includes('transfer amount exceeds balance')) {
        this.toastrService.error(this.translate.instant('notEnoughFundsInMetaMask', {
          assetName: this.selectedAsset,
          walletExtension: this.selectedWallet.info.name
        }), '', 
          {
            disableTimeOut: true
          }
        )
        return;
      }
      this.toastrService.error(
        this.translate.instant('ensureMetaMaskNetworkConfigured', {
          networkName: this.formatNetworkName(this.network || this.selectedAsset),
          walletExtension: this.selectedWallet.info.name
        }) , '',
        {
          disableTimeOut: true
        }
    );
    } 
     
    );
    return;
  }

  getAssetAndNetworkId() {
    if(this.selectedAsset === "USDC" || this.selectedAsset === "USDT") {
      return `${this.network}.${this.selectedAsset}`;
    }
    
    return this.assetIdFormatterUtil.formatBlockchainAssetId(this.selectedAsset);
  }

  async getTokenBalance(address:string, contractAddress:string, decimals = 18) {
    const provider = await this.getMetaMaskProvider();
    const contract = new ethers.Contract(contractAddress, ["function balanceOf(address) view returns (uint)"], provider);
    const balance = await contract['balanceOf'](address)
    return ethers.formatUnits(balance, decimals);
}

  async transferToken(toAddress: any, contractAddress: string | ethers.Addressable, decimals: number) {
    const amount = this.currentQuote?.price;
    const provider = await this.getMetaMaskProvider();
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractABI , signer);
    ethers.getAddress(toAddress); 
    const tx = await contract['transfer'](toAddress, this.assetToWei(amount, decimals));
    await tx.wait();
    return tx;
  }

 
  async getMetaMaskProvider() {
  const noTypeOfWindow = window as any; 
    
  if (!this.selectedWallet) throw new Error(`No MetaMask found!`);
  const provider = new ethers.BrowserProvider( this.selectedWallet.provider);

  return provider;
}

//   async getBnbBalance(address:string) {
//   const provider = this.selectedWallet.provider;
//   const balance = await provider.getBalance(address);
//   return ethers.formatEther(balance.toString());
// }

//   async transferBnb(toAddress: string, quantity: string) {
//   const provider = await this.selectedWallet.provider;
//   const signer = await provider.getSigner();
//   ethers.getAddress(toAddress);

//   const tx = await signer.sendTransaction({
//       to: toAddress,
//       value: ethers.parseEther(quantity)
//   })

//   await tx.wait();

//   return tx;
// }

assetToWei(paymentValue: number, decimals: number): string {
  const contractDecimals = 10 ** decimals;

  const weiValue = BigInt(Math.round(paymentValue * contractDecimals));

  return '0x' + weiValue.toString(16);
}

  public async guestNextStep(nextStep: string) {
    if (this.areAUser == 'guest') {
      if (this.requestParams) {
        this.email = this.behalfEmail
        this.payment.controls['email'].setValue(this.behalfEmail)
        this.guestName = this.behalfName
        this.payment.controls['guestName'].setValue(this.behalfName)
        nextStep = 'overview'
      } else {
        nextStep = 'your-information'
      }
      this.nextStep(nextStep)
    }
    if (this.areAUser == 'login') {
      if (this.requestPayment) {
        let url = "?paymentId=" + this.paymentId
        this.auth.loginWithRedirect({
          appState: {
            target: "/overview" + url
          }
        });
        return;
      }
      let userId = this.targetUser.id.toString();
      let targetNick = this.targetUser.email.toString();
      const targetUser = await firstValueFrom(this.searchService.getPublicProfileByShortId(this.targetUser.id)) as User;
      this.auth.loginWithRedirect({
        appState: { target: "/overview?paymentId=" + userId + "&targetNick=" + targetNick + "&companyName=" + this.targetUser.publicName + "&companyWebsite=" + this.targetUser.websiteUrl }
      });
    }
    if (this.areAUser == 'register') {
      this.router.navigateByUrl('/login?paymentId=' + this.paymentId + '&signup=true');
    }
    this.completedSteps.identification = true;
  }

  
  public backPreviousStep(previousStep:string){
    this.qrError = false;
    this.copied = false;
    this.invalidQuote = false;
    this.transferred = false;
    this.clearTimer()


    if (previousStep === 'your-information') {
      if (this.isAuthenticated) {
          if (this.requestParams) {
              previousStep = 'overview';
          } else {
              previousStep = 'payment-info';
          }
      } else {
          previousStep = 'your-information';
      }
    }
  
    if(previousStep == 'payment-info'){
      if(this.requestParams){
      if(this.payment.controls['description'].value != undefined && this.payment.controls['reference'].value){
        previousStep = 'overview'
      }}
    }
    this.step = previousStep;
    
    if(this.step == 'payment'){
      this.refreshRatesTimerInSeconds = 15;
      this.setRatesTimeout();
    }
  }

  toastrErrorGettingPrices(){
    this.toastrService.error(this.translate.instant('PAYMENT_REPORT.weEncounteredATemporaryProblemFetchingTheRate'),'',{
      timeOut:3000
    })
  }


  validGuestInfo(){
    if(this.payment.controls['email'].value){
    if(this.selectedUserType == 'individual'){
      if(this.payment.controls['guestName'].value){
        return true;
      }
    } else {
      if(this.payment.controls['company'].value){
        return true;
      }
    }}
    return false
  }

  formatPayingAssetFromView(asset:string){
    return asset.split('.').slice(1, 2).join('');
  }

    
  handleImageError(event:any, userName?:string){
    event.target.onerror = null; 
    event.target.src = this.avatarService.getInitialAvatar(userName?.charAt(0) || 'X');
  }
  
  formatAssetToUi(asset:string){
    if(!asset){
      return '';
    }
    return asset.split('.')[1]
  }

  convertUTCtoLocal(date:string) {
    const dateObj = new Date(date);
    let options:any = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    }
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObj);
    return formattedDate + " UTC";
  }


  getScanAddress(transactionId:string) {
    const transactionAsset = this.formatNetwork();
    if (transactionAsset.startsWith('BTC.')) {
      return btcAddress + "tx/" + transactionId.trim();
    }
    if (transactionAsset.startsWith('ETH.')) {
      return ethAddress + "tx/" + transactionId.trim();
    }
    if (transactionAsset.startsWith('MATIC.')) {
      return polyAddress + "tx/" + transactionId.trim();
    }
    if (transactionAsset.startsWith('BEP.')) {
      return bnbAddress + "tx/" + transactionId.trim();
    }
    return '';
  }
  

  updateTransactionId(txId:string){
    this.transactionID = txId;
    this.payment.controls['transactionID'].setValue(txId);
  }

  public closeModal(): void {
    if(this.requestPayment){
      const url = this.router.url;
      const index = url.indexOf('?');
      let currentRoute = index === -1 ? url : url.slice(0, index);
      
      // if (this.paymentRequestInfo?.successUrl && this.step === 'finish') {
      //   window.location.href = this.paymentRequestInfo.successUrl
      //   return;
      // }

      if (this.paymentRequestInfo?.cancelUrl) {
        window.location.href = this.paymentRequestInfo.cancelUrl 
        return;
      }
      
      this.router.navigateByUrl(currentRoute);

    }
    this.onCloseModal.emit(false)
    this.step = '';
    this.clearTimer();
    if(this.alreadyFinished){
      this.successfullyTransaction.emit(true);
      if(!this.isAuthenticated) {
        this.router.navigateByUrl('/register');
        return;
      } else if(this.router.url.includes('payment-report')) {
          this.router.navigateByUrl('/overview');
      }
    }
  }

  finishTransaction(retryCount = 0) {
    if (!this.transactionID) {
      return;
    }
  
    this.paymentService.doTransaction(
      this.payment,
      this.walletAddress,
      this.user,
      this.targetUser.id,
      this.targetUser.email,
      this.savedAssetValue,
      this.savedpaymentValue,
      this.isAuthenticated,
      this.targetUser.publicName || '',
      this.targetUser.email,
      this.paymentId, this.currentQuote
    ).pipe(
      tap((response: any) => {
        this.newPaymentId = response.bill.shortId;
        
        if (this.paymentRequestInfo?.successUrl) {
          window.location.href = this.paymentRequestInfo.successUrl;
          return;
        }
        
        this.toastrService.success(this.translate.instant('PAYMENT_REPORT.paymentSuccessfullyReported'), '', {
          timeOut: 3000,
        });
        this.clearTimer(); 
        this.transferred = false;
  
        this.step = 'finish';
        this.alreadyFinished = true;
      }),
      catchError((error:any) => {
        console.log(error);
        this.nextStep('confirmation');
        
        if (this.successWalletTransaction && retryCount < 3) { 
          setTimeout(() => {
            this.finishTransaction(retryCount + 1);
          }, 1000);
          return EMPTY;
        }

        this.successWalletTransaction = false;

        if (error.error?.detail?.trim().toLowerCase().includes("invalid transaction id")) {
          const assetId = this.assetIdFormatterUtil.formatBlockchainAssetId(this.selectedAsset).split('.')[0];
          this.toastrService.error(this.translate.instant('thisIsNotAValidTransactionForTheNetwork', {
            asset: this.selectedAsset,
            network: this.formatNetworkName(assetId),
          }));
        } else {
          if (error.error?.detail?.trim().toLowerCase().includes("quote is no longer valid")) {
            const now = new Date();
            const dateNow = now.toLocaleDateString('en-US'); 
            const timeNow = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }); 
          
            const endTime = new Date(this.currentQuote.validEndTime);
            const limitDate = endTime.toLocaleDateString('en-US'); 
            const limitTime = endTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }); 
          
            this.toastrService.error(this.translate.instant('quoteExpired', {
              nowDate: dateNow,
              timeNow: timeNow,
              limitDate: limitDate,
              limitTime: limitTime
            }));

            return EMPTY;
          }
       
          if (error.error?.detail) {
            this.toastrService.error(error.error?.detail, '', {
              timeOut: 3000,
            });
          } else {
            this.toastrFactory.unknownError(this.translate.instant('unknownError'));
          }
        }
      
      this.transferred = true;
      return EMPTY;
      })
      
    ).subscribe();
  }

  getAddressViaNetwork() {
    const addresViaNetworkPropertys = { 
       payingAsset: this.selectedAsset,
       currentNetwork: this.translate.instant((this.formatNetworkName( this.getSelectedAssetNetwork() ).toLowerCase() + 'Network'))
    }
    return this.translate.instant('payingAssetViaNetwork', addresViaNetworkPropertys);
  }

  getInformingPhrase(){
    const assetId = this.assetIdFormatterUtil.formatBlockchainAssetId(this.selectedAsset).split('.')[0];
    const targetWebsitePath = '/support/how-to-find-a-transaction-id';
    const defaultLang = localStorage.getItem('defaultLang');


    const tutorialUrl = constructTutorialUrl(targetWebsitePath, defaultLang);

    return this.translate.instant( 'theTransactionIdHelpsUsVerifying', {
      asset : this.selectedAsset,
      network: this.translate.instant( this.formatNetworkName(assetId).toLocaleLowerCase() + "Network" ),
      tutorialLink: tutorialUrl
    })
  }

  openMoreInfoModal (content:string) {
    this.moreDetailsModalContent = content.trim();
    this.moreDetailsModal = true;
  }

  checkIfOverflows (content:string, HTMLElement:HTMLElement) {
    return HTMLElement.offsetWidth < (content?.length * 7)
  }

  handleQrError(event:any){
    if(event.target.src == secondaryQrApi + this.walletAddress){
      event.target.alt = 'We are currently unable to generate a QR Code, please try again later';
      this.qrError = true;
      return;
    }
    else{
      this.qrError = false;
    }
    event.target.src = secondaryQrApi + this.walletAddress;
  }


  public copyWalletAddress() {
    navigator.clipboard.writeText(this.walletAddress);
    this.toastrService.success(this.translate.instant('PAYMENT_REPORT.theWalletAddressHasBeenCopiedToTheClipboard'))
    this.copied = true;
  }

  copyPaymentId(){
    navigator.clipboard.writeText(this.newPaymentId);
    this.toastrService.success(this.translate.instant('thePaymentIdHasBeenCopied'));
    this.alreadyFinished = true;
    this.closeModal();
  }

  generatePlaceholderZerosForAsset(assetId: string = ""){
    return this.assetIdFormatterUtil.generatePlaceholderZerosForAsset(assetId);
  }
}