import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FavoritesService } from 'src/app/services/favorites.service';
import { SearchService } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';
import { User, UserClass } from '../models/user.mode';
import { AuthGuardService } from 'src/app/services/auth.service';
import { catchError, EMPTY, firstValueFrom, tap } from 'rxjs';
import { subscribe } from 'diagnostics_channel';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent {

  public paymentFlow!:boolean;
  public JWTToken: string | null = null;
  
  constructor(
      private translate:TranslateService,
      private searchService:SearchService,
      private favoritesService:FavoritesService,
      private toastrService:ToastrService,
      private authGuardService:AuthGuardService  
    ){
  }

  
  public profileInfo!:any;
  @Input('user')user!: any;
  @Input('paymentModal')paymentModal!: boolean;
  @Input('profileNotFound')profileNotFound!:boolean;
  @Input('isAuthenticated')isAuthenticated!: any;
  @Input('userId')userId!: string;
  @Input('target')target!:User;
  @Input('targetNick')targetNick!: string;
  @Input('targetCompany')targetCompany!: string;
  @Input('companyWebsite')companyWebsite!: string;
  @Input('paymentRequestInfo')paymentRequestInfo!: any;
  @Input('targetDomains')targetDomains:any;
  @Input ('assets')assets:any;
  @Input('selectedLanguage')selectedLanguage!: any;
  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public targetUser!:UserClass;

  targetInfo = {
    userId: this.userId,
    targetNick: this.targetNick,
  }

  ngOnInit(): void {
    if (this.profileNotFound) {
      return;
    }
    console.log(this.targetDomains);
    this.isAlreadyFavorite();
    this.getPublicProfileInfo();
    this.JWTToken = this.authGuardService.getJWTToken();
  }
  
  public environment = environment;
  public alreadyFavorite!:boolean;

  handleImageError(event:any){
    event.target.src = '/assets/icons/missing-icon.png';
  }

  openPayment() {
    this.targetUser = new UserClass(this.target);
    this.paymentFlow = true;
  }

  getBio(){
    if(this.profileInfo?.bio){
       return this.profileInfo.bio
    } else {
      return ''
    }               
  }

  getPublicProfileInfo(){
    if (this.target && !this.target?.shortId) {
      this.getPublicProfileByUUID();
      return;
    } else {
      this.getPublicProfile();
    }
    
  }

  getPublicProfileByUUID() {
    this.searchService.uuidPublicProfile(this.userId).pipe(
      tap((response: any) => {
        this.profileInfo = response;
        this.getTargetDomains();
      }),
      catchError((error:any) => {
        console.log(error);
        return EMPTY;
      })
    ).subscribe();
  }

  getPublicProfile() {
    this.searchService.getPublicProfileByShortId(this.target?.shortId || this.user.shortId).pipe(
      tap((response: any) => {
        this.profileInfo = response;
        this.getTargetDomains();
      }),
      catchError((error:any) => {
        console.log(error)
        return EMPTY;
      })
    ).subscribe();
  }

  async getTargetDomains() {
    if (! this.targetDomains ) {
      const user = await firstValueFrom(this.searchService.generalSearch(this.profileInfo.email)) as any[];
      this.targetDomains = user[0].domains;
    }

    this.profileInfo.domains = this.targetDomains;
  }

  public addFavorite() {
    if(!this.JWTToken){
      this.toastrService.error("You have to login to add a favorite.")
    }
    this.alreadyFavorite = false;
      this.favoritesService.addFavorite(this.targetNick).pipe(
        tap((response: any) => {
          this.toastrService.success(this.translate.instant('userAddedToYourFavoritesSuccessfully'), '', {
            timeOut: 3000
          });
          this.alreadyFavorite = true;
        }),
        catchError((error:any) => {
          console.log(error)
          return EMPTY;
        })
      ).subscribe();
  }
  

  isAlreadyFavorite(){
    if ( !this.isAuthenticated ) {

      return;
    }

    this.favoritesService.searchFavorites(this.targetNick).pipe(
      tap((response: any) => {
        if(response.length > 0){
          this.alreadyFavorite = true;
        } else {
          this.alreadyFavorite = false;
        }
      }),
      catchError((error:any) => {
        this.alreadyFavorite = false;
        return EMPTY;
      })
    ).subscribe();
  }
  
  closeModal() {
    this.onCloseModal.emit(false);
    this.paymentFlow = false;
  }
}
