<app-modal
  [modalMessage]="modalMessage"
  [fullEmailModal]="fullEmailModal"
  [paymentIdModal]="paymentIdModal"
  (onCloseModal)="closeInfoModal()"
  [hideCopyButton]="hideCopyButton"
  *ngIf="paymentIdModal"
  (onConfirmModal)="alreadyFinished = true; closeModal()"
>
</app-modal>

<div class="payment-request-flow">
  <div class="full-width">
    <h1 class="flow-title">
      {{ "payment_flow.PaymentRequest" | translate }}
    </h1>
    <!-- Steps Status -->
    <div class="steps-status">
      <ng-container *ngFor="let stepInfo of steps">
        <label
          [ngStyle]="{ color: getStepColor(stepInfo.id) }"
          (click)="checkIfStepCanBeClicked(stepInfo.id)"
        >
          <div
            class="toggle-box clickable"
            [ngClass]="{ 'past-step': isStepCompleted(stepInfo.id) }"
          >
            <div
              class="current-step"
              *ngIf="
                isCurrentStep(stepInfo.id) && !isStepCompleted(stepInfo.id)
              "
            ></div>
            <i
              class="checked-step ph-bold ph-check"
              *ngIf="isStepCompleted(stepInfo.id)"
            ></i>
          </div>
          <span>{{ stepInfo.label | translate }}</span>
        </label>
        <hr *ngIf="!stepInfo.isLast" />
      </ng-container>
    </div>
  </div>

  <!-- Payment Info Step -->
  <div class="modal-body" *ngIf="isPaymentInfoStep()">
    <div class="step-container">
      <div class="step-title flex-centered">
        <h2>{{ "payment_flow.RequestDetails" | translate }}</h2>
        <h3>{{ "payment_flow.PaymentRequestDetails" | translate }}</h3>
      </div>

      <form [formGroup]="payment">
        <!-- Email Input -->
        <div class="input-wrapper">
          <h3>{{ "payment_flow.PayersEmail" | translate }}*</h3>
          <div
            class="input-container"
            [ngClass]="{
              invalid:
                payment.get('email')!.invalid && payment.get('email')!.touched,
            }"
          >
            <i class="ph ph-at"></i>
            <input
              autocomplete="email"
              name="email"
              class="common-input bigger-input"
              type="text"
              placeholder="{{ 'PAYMENT_REQUEST.payersEmail' | translate }}"
              formControlName="email"
              (keyup)="onEmailKeyup()"
            />
          </div>
        </div>

        <label
          *ngIf="payment.get('email')!.invalid && payment.get('email')!.touched"
          class="inform-label warning-inform"
        >
          {{ "youNeedToInformAValidEmail" | translate }}
        </label>
        <!-- Name Input -->
        <ng-container *ngIf="showNameInput">
          <div class="input-wrapper">
            <h3>{{ "payment_flow.PayersName" | translate }}*</h3>
            <div
              class="input-container"
              [ngClass]="{
                invalid:
                  payment.get('name')!.invalid && payment.get('name')!.touched,
              }"
            >
              <i class="ph ph-user-circle"></i>
              <input
                autocomplete="name"
                name="name"
                class="common-input bigger-input"
                type="text"
                placeholder="{{ 'PAYMENT_REQUEST.payersName' | translate }}"
                formControlName="name"
              />
            </div>
          </div>
        </ng-container>

        <!-- Settlement Asset Selection -->
        <div class="input-wrapper">
          <h3>
            {{ "payment_flow.SettlementAsset" | translate }}*
            <i
              class="ph-fill ph-info clickable"
              matTooltip="{{ 'settlementAssetsAre' | translate }}"
              [matTooltipPosition]="'above'"
            ></i>
          </h3>
          <app-custom-option-select
            class="inline-flex-option-select"
            [availableExchanges]="currentOptionsSelects.payingAsset"
            [currentValue]="payingAsset"
            (changedValue)="payingAsset = $event; updateMaskConfig()"
          ></app-custom-option-select>
        </div>
                
        <!-- Amount Input -->
        <div class="input-wrapper">
          <h3>{{ "payment_flow.Amount" | translate }}*</h3>
          <div
            class="input-container"
            [ngClass]="{
              invalid:
                payment.get('amount')!.invalid &&
                payment.get('amount')!.touched,
            }"
          >
            <input
              #amountElement
              class="input-value"
              currencyMask
              [options]="customCurrencyMaskConfig"
              type="text"
              formControlName="amount"
              autocomplete="off"
              name="usd"
                            placeholder="{{'0' + (isCommaDecimal ? ',' : '.') + generatePlaceholderZerosForAsset(payingAsset)}}"
            />
          </div>
        </div>
        <label
          *ngIf="
            payment.get('amount')!.invalid && payment.get('amount')!.touched
          "
          class="inform-label warning-inform"
        >
          {{ "youNeedToInformTheAmountTransfered" | translate }}
        </label>

        <!-- Reference Input -->
        <div class="input-wrapper">
          <h3>{{ "payment_flow.Reference" | translate }}</h3>
          <div class="input-container">
            <i class="ph ph-at"></i>
            <input
              type="text"
              class="fill-size-input bigger-input"
              [maxlength]="17"
              formControlName="reference"
              name="reference"
              placeholder="{{ 'reference' | translate }}"
            />
          </div>
        </div>

        <label
          *ngIf="invalidPayerInfo && payment.get('amount')!.value === ''"
          class="inform-label warning-inform"
        >
          {{ "enterTheAmountToBePaid" | translate }}
        </label>
      </form>
    </div>

    <div class="modal-footer side-buttons-footer only-desktop">
      <button class="cancel-button" (click)="closeModal()">
        {{ "payment_flow.Cancel" | translate }}
      </button>
      <div class="buttons-wrapper">
        <!-- <button class="secondary-button" (click)="backPreviousStep('paymentDetails')">{{ 'back' | translate }}</button> -->
        <button (click)="nextStep('reportPayment')">
          {{ "payment_flow.Continue" | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- Report Payment Step -->
  <div class="modal-body" *ngIf="isReportPaymentStep()">
    <div class="step-container">
      <div class="overview-message">
        <span class="info-header"
          >{{ "payment_flow.Dear" | translate }}
          {{ name || payment.controls["name"].value }},</span
        ><br />
        {{ "payment_flow.iAmSendingThisRequestInTheAmount" | translate }}
        <span class="important-info"
          >{{ payingAsset?.toUpperCase() }} {{ formatedPrice }}</span
        ><br />
        <ng-container *ngIf="payment.controls['reference'].value">
          {{ "payment_flow.Regarding" | translate }}
          <span class="important-info reference">
            {{
              getEllipsisText(15, payment.controls["reference"].value) ||
                "< " + ("PAYMENT_REQUEST.empty" | translate) + " >"
            }}
          </span>
        </ng-container>
        <span class="info-footer"
          ><br *ngIf="isMobile" />
          {{ user.publicName }}</span
        >
      </div>
    </div>
    <div class="modal-footer side-buttons-footer only-desktop">
      <button class="cancel-button" (click)="closeModal()">
        {{ "payment_flow.Cancel" | translate }}
      </button>
      <div class="buttons-wrapper">
        <button
          class="secondary-button"
          (click)="backPreviousStep('paymentInfo')"
        >
          {{ "back" | translate }}
        </button>
        <button (click)="nextStep('finish')">
          {{ "PAYMENT_REQUEST.finish" | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- Finish Step -->
  <div class="modal-body step-success-body" *ngIf="isFinishStep()">
    <div class="step-container step-overview">
      <div class="step-title flex-centered">
        <img
          class="success-payment-img"
          src="/assets/icons/success-payment.png"
        />
        <h2>{{ "payment_flow.requestHasBeenSent" | translate }}</h2>
        <label class="success-email-label"
          >{{ "payment_flow.paymentRequestHasBeenSentTo" | translate }} <br />
          {{ payment.controls["email"].value?.trim() }}</label
        >
      </div>
      <label class="payment-success-inform">
        {{ "payment_flow.requestId" | translate }}
        <span>
          {{ shortId }}
          <i
            class="ph ph-copy copy-button clickable"
            (click)="copyRequestId()"
          ></i>
          <i class="ph ph-qr-code clickable" (click)="toggleQrModal()"></i>
        </span>
      </label>

      <label class="payment-success-inform settlement-title">
        {{ "payment_flow.settlementAmount" | translate }}
      </label>
      <div class="settlement-container">
        <label>{{ payingAsset }}</label>
        <span>{{ formatPrice(payment.controls["amount"].value) }}</span>
      </div>

      <div class="modal-footer side-buttons-footer centered-footer">
        <button
          *ngIf="navigatorSupportsShare()"
          class="secondary-button"
          (click)="shareScreen(shortId)"
        >
          <i class="ph ph-share-network"></i>
          {{ "payment_flow.share" | translate }}
        </button>
        <button (click)="showConfirmationModal = false; closeModal()">
          {{
            isAuthenticated
              ? ("backToDashboard" | translate)
              : ("createAnAccount" | translate)
          }}
        </button>
      </div>
    </div>
  </div>

  <app-nicky-footer class="only-mobile"></app-nicky-footer>
</div>

<!-- Confirmation Modal -->
<div
  class="confirmation-modal-backdrop modal-backdrop"
  *ngIf="showConfirmationModal"
>
  <div class="modal payment-info-modal confirmation-modal">
    <div class="modal-header">
      <h3 class="payment-title">Hold on!</h3>
      <h3 class="close" (click)="toggleConfirmationModal()">X</h3>
      <br />
    </div>
    <div class="modal-body">
      <label>Are you sure you don’t want to report this payment?</label>
    </div>
    <div class="modal-footer side-buttons-footer">
      <button
        class="no-report"
        (click)="
          toggleConfirmationModal(); alreadyFinished = true; closeModal()
        "
      >
        I DON'T
      </button>
      <button (click)="toggleConfirmationModal()">CONTINUE</button>
    </div>
  </div>
</div>

<!-- QR Modal -->
<div *ngIf="shouldShowQrModal()" class="modal-backdrop">
  <div class="modal qr-modal">
    <i class="ph-bold ph-x close-modal clickable" (click)="toggleQrModal()"></i>
    <div class="modal-title">
      <h1>
        {{ "DASHBOARD.scanOr" | translate }}
        {{ (navigatorShare ? "Copy" : "save") | translate }}
        {{ "DASHBOARD.theQrCode" | translate }}
      </h1>
      <h3>{{ "youCanCopyTheLinkOrClick" | translate }}</h3>
    </div>
    <hr class="spacement" />
    <div class="modal-body">
      <img [src]="getQrCode()" class="qr-code clickable" (click)="copyQr()" />
      <div class="input-wrapper">
        <label>{{ "copy" | translate }}</label>
        <div
          class="input-container disabled clickable"
          (click)="onCopyRequestLink(shortId)"
        >
          <i class="ph ph-copy icon clickable copy-icon"></i>
          <input
            type="text"
            class="clickable"
            (click)="onCopyRequestLink(shortId)"
            [value]="url + shortId"
            disabled
          />
        </div>
      </div>
    </div>
    <hr class="spacement" />
    <div class="buttons-wrapper">
      <button class="secondary-button" (click)="toggleQrModal()">
        {{ "cancel" | translate }}
      </button>
      <button (click)="toggleQrModal()">{{ "done" | translate }}</button>
    </div>
  </div>
</div>
