import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthGuardService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authGuardService: AuthGuardService,
    private loadingService: LoadingService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isTranslationRequest = req.url.includes('./assets/i18n/');
    const isPublicRequest = req.url.includes('/public');
    const isGetPriceRequest = req.url.includes('/get-price');
    const isValidatingAddress = req.url.includes('/wallet/validateaddress')

    if (isTranslationRequest || isPublicRequest || isValidatingAddress) {
      return next.handle(req);
    }

    const token = this.authGuardService.getJWTToken();

    if (token) {
      const cloned = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });

      
      if (!isGetPriceRequest)
      {
        this.loadingService.setLoading(true);
      }

      return next.handle(cloned).pipe(
        finalize(() => this.loadingService.setLoading(false))
      );
    } else {
      
      if (!isGetPriceRequest)
      {
        this.loadingService.setLoading(true);
      }
      return next.handle(req).pipe(
        finalize(() => this.loadingService.setLoading(false))
      );
    }
  }
}
