import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import * as auth0 from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, tap } from 'rxjs';
import { optionSelectsModel } from 'src/app/components/models/option-select.model';
import { SigninService } from 'src/app/services/sign-in.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

declare const BRANCH_NAME: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  public userForgotPassword: boolean = false;
  private isProd = BRANCH_NAME === 'production';
  public defaultBackgroundPosition: number = 20;
  public choosenAuthMethod: string = 'password';
  public showPassword!: boolean;
  public loginError!: string;
  public requestId!: string;
  public signup!: boolean;
  public showLoginPage!: boolean;
  public remainingSeconds: number = 3;
  public redirectFromValidationEmail!: boolean;

  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
    audience: environment.auth0Audience,
  });

  constructor(
    private router: Router,
    private auth: AuthService,
    public signInService: SigninService,
    public toastrService: ToastrService,
    public userService: UserService,
    private http: HttpClient,
    public translate: TranslateService,
    private params: ActivatedRoute
  ) {}

  currentOptionsSelects: {
    authMethod: optionSelectsModel[];
  } = {
    authMethod: [
      {
        name: this.translate.instant('LOGIN.sendLoginLinkToEmail'),
        value: 'mailLink',
      },
      {
        name: this.translate.instant('LOGIN.iWantToUseMyPassword'),
        value: 'password',
      },
    ],
  };

  public form: FormGroup = new FormGroup({
    nick: new FormControl(null, [Validators.required, Validators.email]),
    authMethod: new FormControl('password', Validators.required),
    password: new FormControl(null, [
      Validators.required,
      Validators.pattern(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/
      ),
    ]),
  });

  getAuthMethod() {
    return this.form.controls['authMethod'].value;
  }

  isDisabled() {
    return this.isProd;
  }

  SSOAuth(socialConnection: string) {
    this.setPaymentId();
    this.auth
      .loginWithRedirect({
        authorizationParams: {
          connection: socialConnection,
          redirect_uri: `${window.location.origin}/overview`,
          ui_locales: navigator.language || 'en',
        },
      })
      .subscribe({
        error: (e: any) => {
          console.log(e);
        },
      });
  }

  ngOnInit(): void {
    this.redirectToForgotPassword();
    this.getUrlParams();
  }

  cancelForgotPassword() {
    this.callAuth0LoginPage();
  }

  validatedEmail() {
    this.redirectFromValidationEmail = true;
    setInterval(() => {
      this.remainingSeconds += -1;
      if (this.remainingSeconds <= 0) {
        this.auth.loginWithRedirect({
          appState: { target: '/overview' },
          authorizationParams: {
            ui_locales: navigator.language || 'en',
          },
        });
      }
    }, 1000);
  }

  getUrlParams() {
    this.params.queryParams
      .pipe(
        tap((params: any) => {
          this.requestId = params['paymentId'];
          this.signup = params['signup'];
          const confirmedEmail = params['confirmedEmail'];

          if (confirmedEmail) {
            this.validatedEmail();
            return;
          }

          if (params['mailLink']) {
            this.setAuthMethod('mailLink');
          }
          if (!this.signup && !params['mailLink'] && !this.userForgotPassword) {
            this.callAuth0LoginPage();
          } else {
            this.showLoginPage = true;
          }
        })
      )
      .subscribe();
  }

  callAuth0LoginPage() {
    if (localStorage.getItem('logout')) {
      localStorage.removeItem('logout');
      this.form.controls['authMethod'].setValue('password');
      this.auth.loginWithRedirect({
        appState: {
          target: '/overview',
          language: localStorage.getItem('defaultLang'),
          ui_locales: navigator.language || 'en',
        },
      });
    }
    if (!localStorage.getItem('logout')) {
      this.auth.logout({
        logoutParams: {
          returnTo: window.location.href,
        },
        openUrl: async (url: string) => {
          localStorage.setItem('logout', 'true');
          window.location.replace(url);
        },
      });
    } else {
      this.auth.loginWithRedirect({
        appState: {
          target: '/overview',
          language: localStorage.getItem('defaultLang'),
          ui_locales: navigator.language || 'en',
        },
      });
    }
  }

  public goHome() {
    this.router.navigateByUrl('/home');
  }

  passwordlessLogin() {
    this.userService.passwordlessAuthentication(
      this.form.controls['nick'].value
    );
    this.setPaymentId();
  }

  setAuthMethod(choosenAuthMethod: string) {
    this.choosenAuthMethod = choosenAuthMethod;
    this.form.controls['authMethod'].setValue(choosenAuthMethod);
  }

  markFormAsUnthouched() {
    this.form.controls['nick'].reset();
  }

  authMethod() {
    if (this.getAuthMethod() == 'null') {
      return;
    }
    if (this.userForgotPassword) {
      this.forgotPassword(this.form.controls['nick'].value);
    }
    const email = this.form.controls['nick'].value;
    const password = this.form.controls['password'].value;
    if (this.form.controls['authMethod'].value == 'password') {
      if (email && password) {
        this.setPaymentId();
        this.webAuth.login(
          {
            email: this.form.controls['nick'].value,
            password: this.form.controls['password'].value,
            redirectUri: `${window.location.origin}/overview`,
            responseType: 'token',
          },
          (err, authResult) => {
            console.log(err);
            console.log(authResult);
            this.loginError = err?.description || '';
          }
        );
      }
    } else {
      this.form.controls['authMethod'].value == 'mailLink';
      if (email) {
        this.passwordlessLogin();
      }
    }
  }

  setPaymentId() {
    localStorage.removeItem('paymentId');
    if (this.requestId) {
      localStorage.setItem('paymentId', this.requestId);
    }
  }

  checkPaymentParams() {
    if (this.requestId) {
      return '?requestId=' + this.requestId;
    }
    return '';
  }

  changeAuthInputs() {
    if (this.getAuthMethod() === 'mailLink') {
      // this.form.controls['authMethod'].setValue('password');
      this.callAuth0LoginPage();
    } else {
      this.form.controls['authMethod'].setValue('mailLink');
    }
  }

  getChangeInputsText() {
    return this.translate.instant('LOGIN.iWantToUseMyPassword') + ' >';
  }

  logouthUser() {
    this.auth.logout();
  }

  redirectToForgotPassword() {
    if (this.router.url == '/login/forgotpassword')
      this.userForgotPassword = true;
  }

  getPasswordVisibility() {
    if (this.showPassword) {
      return '/assets/icons/seeing-password.png';
    }
    return '/assets/icons/password-hide.png';
  }

  validNick() {
    const currentNick = this.form.controls['nick'];
    if (
      currentNick.invalid ||
      currentNick.value === undefined ||
      currentNick.value.trim() === ''
    ) {
      return false;
    }
    return true;
  }

  forgotPassword(nick: any) {
    const invalidNick =
      this.form.controls['nick'].invalid && this.form.controls['nick'].touched;

    if (invalidNick) {
      return;
    }

    this.signInService
      .resetPassword(nick)
      .pipe(
        tap((response: any) => {
          console.log(response);
        }),
        catchError((error: any) => {
          if (error.status.toString() == '200') {
            this.toastrService.success(
              this.translate.instant('LOGIN.weHaveSentAnEmailTo') +
                nick +
                this.translate.instant(
                  'LOGIN.inCaseItIsRegisteredPleaseCheckYourMailBox'
                ),
              '',
              {
                timeOut: 3000,
              }
            );
          }
          if (!(error.status.toString() == '200')) {
            this.toastrService.error(error.statusText, '', {
              timeOut: 3000,
            });
          }
          return EMPTY;
        })
      )
      .subscribe();
  }
}
