import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { AuthService } from '@auth0/auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { Asset } from 'src/app/components/models/asset.model';
import { PaymentReportModel } from 'src/app/components/models/payment.report.model';
import { FavoritesService } from 'src/app/services/favorites.service';
import { FilterService } from 'src/app/services/filter.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';
import FormatCurrency from "src/app/utils/format-currency-utils";
import { bnbAddress, btcAddress, decimalPlacesFor, environment, ethAddress, polyAddress, qrApi, tronScan } from 'src/environments/environment';

import { DatePipe } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { catchError, EMPTY, firstValueFrom, Observable, of, switchMap, tap } from 'rxjs';
import StepOptionsButton from 'shepherd.js/src/types/step';
import { toggleMenu } from 'src/app/app.component';
import { ConnectorModel } from 'src/app/components/models/connector.model';
import { optionSelectsModel } from 'src/app/components/models/option-select.model';
import { ExchangeConversionRequestStatus } from 'src/app/components/models/payment-transfers.model';
import { PaymentRequestModel } from 'src/app/components/models/payment.request.model';
import { savedOffRampSettings } from 'src/app/components/models/saved-off-ramp-seetings.model';
import { User, UserClass } from 'src/app/components/models/user.mode';
import { ManualOffRampDto } from 'src/app/dtos/manual-off-ramp.dto';
import { OffRampService } from 'src/app/services/off-ramp.service';
import { PaymentRequestService } from 'src/app/services/payment.request.service';
import { PaymentService } from 'src/app/services/payment.service';
import ConvertAsset from 'src/app/utils/conversion-utils';

import * as ExcelJS from 'exceljs';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import RoundUpUtil from 'src/app/utils/round-up-utils';
import { AssetIdFormatterUtil } from 'src/app/utils/asset-id-utils';
import { AvatarService } from 'src/app/services/avatar.service';

// import * as XLSX from 'xlsx';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', './dashboard.firefox.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  public itemsPerPage = 10;
  favoritesList: any;
  items: any;
  public searchResults: any;
  public fullEmailModal!: boolean;
  public resendEmailModal!: boolean;
  public currentSentEmail!: string;
  public filteredEmails: string[] = [];
  public requestsfilteredEmails: string[] = [];
  public hideFooter!: boolean;

  public transactions: any[] = [];
  public transactionWarnings!: string[];
  public transactionWarningsModal!: boolean;
  public paginationPageEvent: any;

  public currentPage: { [key: string]: { page: number } } = {
    transactions: {
      page: 1,
    },
    requests: {
      page: 1,
    },
  };
  public bitcoinFilter: boolean = false;
  public ethereumFilter: boolean = false;
  public usdtFilter: boolean = false;
  public usdcFilter: boolean = false;
  public paidFilter: boolean | undefined = false;
  public pendingFilter: boolean | undefined = false;
  public canceledFilter: boolean | undefined = false;
  public convertedAsset!: { [key: string]: { value: number; asset: string } };
  public alreadyConverted: boolean = false;
  public targetCompany!: string;
  public targetDomains: any;
  public companyWebsite!: string;
  public isRequestFilterActive!: boolean;
  public requestFilter: string[] = [];
  public paidId!: string;
  public paymentReportId!: string;
  public reopenPayment: boolean = false;
  public paymentRequestAsset!: string;
  public firefox = navigator.userAgent.indexOf('Firefox') > -1;
  public navigatorShare = this.firefox ? undefined : navigator.share;
  public cliboardItem = typeof ClipboardItem !== 'undefined';
  public selectedShortIdCode!: string;
  public invalidRequestId!: boolean;
  public resendEmail: string = this.translate.instant('DASHBOARD.resendEmail');
  public detailedPaymentReport!: PaymentReportModel;
  public detailedPaymentRequest!: PaymentRequestModel;
  public paymentReportDetails!: boolean;
  public paymentRequestDetails!: boolean;
  public targetPaymentRequestUser!: User;
  public bookIconOpen!: boolean;

  private currentStepId!: string | undefined;
  private tourMobilePositionCheck: any;
  private tourPositionDefault: any;

  private tourStepLegend: any = undefined;
  private nextButton: any = undefined;
  private backButton: any = undefined;
  private exitButton: any = undefined;
  private finishButton: any = undefined;
  private tourDefaults: any = undefined;
  private defaultTourButtons: {
    haveBackButton: any[];
    haveFinishButton: any[];
    onlyNextButton: any[];
  } = {
    haveBackButton: [],
    haveFinishButton: [],
    onlyNextButton: [],
  };

  public manualWithdraw!: boolean;
  public currentToggledAction!: PaymentRequestModel;

  public modalBitcoinFilter: boolean = false;
  public modalEthereumFilter: boolean = false;
  public modalUsdtFilter: boolean = false;
  public modalUsdcFilter: boolean = false;
  public paymentFlow: boolean = false;
  public exchangeModal: boolean = false;
  public loaded: boolean = false;
  public tab: 'transactions' | 'requests' = 'transactions';
  public openIdInput!: boolean;
  public mockPaymentId: string = 'MOCKID';
  public url: string =
    window.location.href.replace('dashboard', '').replace('overview', '') +
    'home?paymentId=';
  public newPaymentRequest!: boolean;
  public cancelPaymentId!: string;
  public domain: any;
  public domainIsLoaded!: boolean;
  public requestInfo: any;
  public targetUser!: UserClass;
  public defaultBackgroundPosition: number = 20;
  public currentRoute = this.router.url;
  public actionsElement: any;
  public balancesBgColors = [
    'rgba(222, 255, 150, 1)',
    'rgba(254, 224, 171, 1)',
    'rgba(209, 209, 255, 1)',
    'rgba(229, 236, 246, 1)',
    'rgba(255, 206, 232, 1)',
  ];

  private defaultTransactionWarnings = {
    outOftime: 'outOfTimeWindowPaymentReport',
    didntFindAnyTransaction: 'weDidntFindAnyTransactionWithThisId',
    paymentRequiresValidation: 'paymentRequiresValidation',
    theTransactionDoesntHaveBeenSentToTheTargetAddress:
      'theFundsFromThisTransactionWereSentToADifferentWalletAddress',
  };

  public openFilter!: boolean;

  public currentTab!: string;
  authenticated: any;
  user: any;

  constructor(
    public toastrService: ToastrService,
    public auth: AuthService,
    public favoritesService: FavoritesService,
    private searchService: SearchService,
    public formatCurrency: FormatCurrency,
    private userService: UserService,
    private filterService: FilterService,
    private datePipe: DatePipe,
    private paymentService: PaymentService,
    public router: Router,
    private params: ActivatedRoute,
    private convertAsset: ConvertAsset,
    private roundUpUtil: RoundUpUtil,
    private paymentRequestService: PaymentRequestService,
    private sanitize: DomSanitizer,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    private shepherdService: ShepherdService,
    private offRampService: OffRampService,
    private toastrFactory: ToastrFactoryService,
    private assetIdFormatterUtil: AssetIdFormatterUtil,
    public avatarService: AvatarService
  ) {}

  @ViewChild('transactionId') transactionId!: ElementRef;
  @ViewChild('paymentId') paymentId!: ElementRef;
  @ViewChild('search') search!: ElementRef<HTMLInputElement>;
  @ViewChild('searchDesktop') searchDesktop!: ElementRef<HTMLInputElement>;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  dateChanged(date: any) {}

  toggleMenu() {
    toggleMenu();
  }

  testePage() {}

  public showModal!: boolean;
  public modalMessage!: string;
  public modalTittle!: string;
  public dashBoardOptions!: boolean;
  public modalNick!: string;
  public modalPublicName!: string;
  public myNick!: any;
  public isMobile!: boolean;
  public balances!: any[];
  public totalBalances!: number;
  public isFilterActive: boolean = false;
  public maskedLessThanInput: any;
  public userId!: string;
  public targetNick!: string;
  public totalTransactions: any;
  public start!: number;
  public end!: number;
  public ascending: boolean = true;
  public browserLanguage = navigator.language;
  public paymentRequests: PaymentRequestModel[] = [];
  public totalRequests: any;
  public modalRequestCancel: boolean = false;
  public assets: Asset[] = [];
  public userExchanges!: any;
  public userActiveWallets: string[] = [];
  public modalMarkAsPaid!: boolean;
  public targetPaymentId!: string;
  public shortId!: string;
  public target!: User;
  public currentPayment!: PaymentRequestModel;
  public paymentStatus!: string;
  public tittleMessage!: string;
  public paymentWarnings: string[] = [];
  public qrCode = '';
  public qrModal = false;
  public missingValue!: string;
  public invalidMissingValue!: boolean;
  public userProfile!: boolean;
  public environment = environment;
  public minimumCharMessage!: string;
  public selectedLanguage!: string;
  // private decimalPlaces = decimalPlacesFor.bitcoin;
  public resendEmailShortId!: string;
  public choosenAsset: string = '';
  public choosenStatus: string = '';
  public paymentReportsFilterStatus: any[] = [
    {
      name: this.translate.instant('finished'),
      value: 'Finished',
    },
    {
      name: this.translate.instant('error'),
      value: 'Error',
    },
    {
      name: this.translate.instant('pending'),
      value: 'Pending',
    },
  ];

  public paymentRequestFilterStatus: any[] = [
    {
      name: this.translate.instant('finished'),
      value: 'Finished',
    },
    {
      name: this.translate.instant('canceled'),
      value: 'Canceled',
    },
    {
      name: this.translate.instant('pending'),
      value: 'PaymentPending',
    },
  ];
  public notVerified!: boolean;
  public defaultIconsPath: string = '/assets/icons/';
  public currentUserOffRampSettings: savedOffRampSettings[] = [];
  public availableConnectors: ConnectorModel[] = [];
  public availableConnectorsForFiat!: ConnectorModel[] | undefined;
  public manualOffRampSettings: ManualOffRampDto = {
    amount: undefined,
    assetId: undefined,
    cryptoConnectionId: undefined,
  };

  currentOptionsSelects: {
    choosenAsset: optionSelectsModel[];
    choosenStatus: optionSelectsModel[];
    currentOffRampSettings: optionSelectsModel[];
  } = {
    choosenAsset: [],
    choosenStatus: [
      {
        name: this.translate.instant('selectStatus'),
        value: '',
      },
      {
        name: this.translate.instant('pending'),
        value: 'pending',
      },
      {
        name: this.translate.instant('finished'),
        value: 'Finished',
      },
      {
        name: this.translate.instant('error'),
        value: 'Error',
      },
    ],
    currentOffRampSettings: [],
  };

  @Output() submitForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @ViewChild('picker') picker!: MatDatepicker<Date>;

  toggleDatePicker(datePicker: MatDatepicker<Date>) {
    datePicker.opened ? datePicker.close() : datePicker.open();
  }

  private createFormGroup(extraControls: { [key: string]: AbstractControl }) {
    const commonControls = {
      dateStart: new FormControl(undefined),
      dateEnd: new FormControl(undefined),
      amountMoreThan: new FormControl(undefined),
      amountLessThan: new FormControl(undefined),
      receiverNick: new FormControl(undefined),
      blockchainAssets: new FormControl(undefined),
      status: new FormControl(undefined),
      nick: new FormControl(undefined, Validators.email),
    };

    return new FormGroup({ ...commonControls, ...extraControls });
  }

  public filterForm: FormGroup = this.createFormGroup({
    transactionId: new FormControl(undefined),
    bitcoinFilter: new FormControl(this.bitcoinFilter),
    ethereumFilter: new FormControl(this.ethereumFilter),
    usdtFilter: new FormControl(this.usdtFilter),
    usdcFilter: new FormControl(this.usdcFilter),
  });

  public requestFilterForm: FormGroup = this.createFormGroup({
    paymentId: new FormControl(undefined),
    assetFilter: new FormControl(''),
    paidFilter: new FormControl(this.paidFilter),
    canceledFilter: new FormControl(this.canceledFilter),
    pendingFilter: new FormControl(this.pendingFilter),
  });

  setAssetFilter(asset: string) {
    this.choosenAsset = asset;
    this.requestFilterForm.controls['assetFilter'].setValue(asset);
  }

  setChoosenStatus(status: string) {
    this.choosenStatus = status;
    if (this.tab === 'transactions') {
      this.filterForm.controls['status'].setValue(status);
      return;
    }
    this.requestFilterForm.controls['status'].setValue(status);
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }

  moreInfoPage() {
    return this.router.url === '/transactions';
  }

  handleImageError(event: any, userName?: string) {
    event.target.onerror = null;
    event.target.src = this.avatarService.getInitialAvatar(
      userName?.trim()?.charAt(0) || 'X'
    );
  }

  toggleRequestActions(request: PaymentRequestModel) {
    if (request.visible) {
      request.visible = false;
      return;
    }

    if (this.currentToggledAction?.visible) {
      this.currentToggledAction.visible = false;
    }

    request.visible = true;
    this.currentToggledAction = request;
  }

  changeTab(tabName: 'transactions' | 'requests') {
    this.tab = tabName;
    if (tabName === 'transactions') {
      const transactionsFilter = this.filterForm.controls['status'].value;
      this.choosenStatus = transactionsFilter;
    } else {
      const paymentRequestFilter =
        this.requestFilterForm.controls['status'].value;
      this.choosenStatus = paymentRequestFilter;
    }
  }

  showPaymentReportDetails(paymentReport: PaymentReportModel) {
    if (this.isMobile) {
      return;
    }
    this.paymentReportDetails = true;
    this.detailedPaymentReport = paymentReport;
  }

  showPaymentRequestDetails(paymentRequest: PaymentRequestModel) {
    if (this.isMobile) {
      return;
    }
    this.paymentRequestDetails = true;
    this.detailedPaymentRequest = paymentRequest;
  }

  closeModalDetailsModal(event: MouseEvent) {
    const detailsModal = document.querySelector(
      '.transaction-description-modal'
    );

    if (detailsModal && !detailsModal.contains(event.target as Node)) {
      this.paymentReportDetails = false;
      this.paymentRequestDetails = false;
    }
  }

  goToRoute(routeName: string) {
    this.router.navigateByUrl(routeName);
  }

  public getSystemAssets() {
    this.paymentService
      .getAllAssets()
      .pipe(
        tap((response: any) => {
          this.currentOptionsSelects.choosenAsset = [];
          this.assets = response;
          this.currentOptionsSelects.choosenAsset.push({
            name: this.translate.instant('Assets'),
            value: '',
          });
          this.assets.forEach((asset: Asset) => {
            if (!asset.isFiat && !asset.id.includes('MATIC'))
              this.currentOptionsSelects.choosenAsset.push({
                name: asset.assetTicker,
                value: asset.id,
              });
          });
        }),
        catchError((error: any) => {
          console.log(error);
          this.toastrFactory.unknownError(
            this.translate.instant('unknownError')
          );
          return EMPTY;
        })
      )
      .subscribe();
  }

  newWallet() {
    this.router.navigateByUrl('/settings?wallet=true');
  }

  public redirectToPayment() {
    this.params.queryParams
      .pipe(
        tap((params: any) => {
          const paymentId = this.getPaymentId(params);
          if (paymentId) {
            this.handlePaymentId(paymentId);
          }

          if (localStorage.getItem('targetShortId')) {
            const targetShortId = localStorage.getItem('targetShortId');
            localStorage.removeItem('targetShortId');
            this.router.navigateByUrl('payment-report/' + targetShortId);
          }

          this.handleTargetNickAndPaymentAddress(params);
        })
      )
      .subscribe();
  }

  private getPaymentId(params: any): string {
    return params['paymentId'] || localStorage.getItem('paymentId');
  }

  private handlePaymentId(paymentId: string) {
    if (localStorage.getItem('paymentId')) {
      this.router.navigateByUrl('/overview?paymentId=' + paymentId);
    }
    this.paymentRequestService
      .getPaymentById(paymentId)
      .pipe(
        tap((request: any) => {
          this.processPaymentRequest(request);
        }),
        catchError((error: any) => {
          this.handlePaymentRequestError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  private processPaymentRequest(request: any) {
    if (request) {
      if (
        request.bill.paymentReports.length == 0 &&
        request.status != 'Canceled'
      ) {
        this.setPaymentRequestData(request);
        localStorage.removeItem('paymentId');
      } else {
        this.handleInvalidPaymentRequest(request);
        localStorage.removeItem('paymentId');
      }
    }
  }

  private setPaymentRequestData(request: any) {
    this.requestInfo = request;
    this.targetUser = new UserClass(request.creator);
    this.paymentFlow = true;
  }

  private handleInvalidPaymentRequest(request: any) {
    if (request.status == 'Canceled') {
      this.toastrService.error(
        this.translate.instant('thisPaymentRequestHasBeenCanceled'),
        'Something went wrong'
      );
    } else {
      this.toastrService.error(
        this.translate.instant('thisPaymentRequestHasAlreadyBeenReported'),
        'Something went wrong'
      );
    }
  }

  private handlePaymentRequestError(e: any) {
    this.toastrService.error(
      this.translate.instant('weAreUnableToFindThisPaymentRequest'),
      'Something went wrong',
      {
        timeOut: 3000,
      }
    );
    console.log(e);
  }

  private handleTargetNickAndPaymentAddress(params: any) {
    if (params['targetNick'] || params['paymentAddress']) {
      this.userService
        .userIsValidated(params['paymentAddress'] || params['targetNick'])
        .pipe(
          tap((response: any) => {
            this.processUserValidation(response, params);
          }),
          catchError((error: any) => {
            this.handleUserValidationError(error);
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  private async processUserValidation(response: any, params: any) {
    if (response == false) {
      this.toastrFactory.unknownError(this.translate.instant('unknownError'));
    } else if (response == true) {
      const favorite = await firstValueFrom(
        this.searchService.generalSearch(params['targetNick'])
      );
      this.targetUser = new UserClass(favorite);
      this.paymentFlow = params['paymentFlow'] === 'true';
      // if (params['targetNick']) this.targetUser.id = params['userId'];
      // this.targetUser.publicName = params['publicName'];
      // this.targetUser.websiteUrl = params['companyWebsite'];
    }
  }

  private handleUserValidationError(e: any) {
    this.toastrFactory.unknownError(this.translate.instant('unknownError'));
    console.log(e);
  }
  getBalancesBackgroundColor(index: number) {
    if (index <= this.balancesBgColors.length) {
      return this.balancesBgColors[index];
    }

    return this.balancesBgColors[Math.floor(Math.random() * 5)];
  }

  getTotalBalances() {
    return this.totalBalances?.toString() || '';
  }

  linkToAPayment() {
    if (this.targetPaymentId == '' || this.targetPaymentId == undefined) {
      this.invalidRequestId = true;
      return;
    }
    this.paymentRequestService
      .assignToPaymentRequest(this.targetPaymentId, this.paymentReportId)
      .pipe(
        tap((response: any) => {
          this.toastrService.success(
            this.translate.instant('DASHBOARD.requestAndReportLinked'),
            this.translate.instant('success'),
            {
              timeOut: 3000,
            }
          );
          this.getAllPaymentReports();
          this.closeLinkPaymentModal();
        }),
        catchError((error: any) => {
          if (error.error.detail?.includes('Unable to move PaymentReport')) {
            this.toastrService.error(
              this.translate.instant('DASHBOARD.invalidRequestId'),
              'Something went wrong',
              {
                timeOut: 3000,
              }
            );
            this.invalidRequestId = true;
            return EMPTY;
          }
          console.log(error);
          this.toastrFactory.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  closeLinkPaymentModal() {
    this.openIdInput = false;
    this.invalidRequestId = false;
    this.targetPaymentId = '';
  }

  showInPaymentReports(payment: PaymentRequestModel) {
    const query =
      payment.bill.paymentReports[0].paymentTransactions[0]
        .blockchainTransactionId;
    this.tab = 'transactions';
    this.filterForm.controls['transactionId'].setValue(query);
    this.submitFilter(true);
    this.toastrService.success(
      this.translate.instant('DASHBOARD.filterSettingsToShowPaymentReport')
    );
  }

  closeModal(event: any) {
    this.showModal = false;
    this.dashBoardOptions = false;
    this.fullEmailModal = false;
    this.paymentFlow = false;
    this.exchangeModal = false;
    this.transactionWarningsModal = false;
    this.newPaymentRequest = false;
    this.userProfile = false;
    this.hideFooter = false;
  }

  public showDashboardOptionsModal(favorite: any) {
    this.showModal = true;
    this.modalNick = favorite.nick;
    this.modalPublicName = favorite.publicName;
    this.modalTittle = this.translate.instant('weFoundIt');
    this.dashBoardOptions = true;
  }

  balancesPostConversion(balance: any) {
    let asset = this.convertAsset.formatAsset(balance.blockchainAsset.id);

    if (this.alreadyConverted && this.convertedAsset[asset]) {
      return this.formatCurrency.convertToLocal(
        (this.convertedAsset[asset].value * balance.amountNative).toString(),
        this.getDecimalPlaces('USD.USD')
      );
    } else {
      return '';
    }
  }

  convertedBalance(asset: string, amountNative: number) {
    if (this.alreadyConverted) {
      return this.convertedAsset[asset].value * amountNative;
    } else {
      return 0;
    }
  }

  formatAsset(asset: string) {
    if (asset === 'USDC' || asset === 'USDT') return 'ETH.' + asset;
    if (asset === 'MATIC') return 'MATIC.' + asset;
    return asset;
  }

  public showEmailModal(modalMessage: string) {
    if (this.isMobile) {
      this.showModal = true;
      this.modalMessage = modalMessage;
      this.fullEmailModal = true;
    }
  }

  getFilterIcon(
    asset: string,
    filterStatus: boolean,
    modalFilter?: boolean
  ): string {
    const baseIconPath = `/assets/icons/filter-${asset}.png`;
    return baseIconPath;
  }

  setFilter(assetName: string) {
    switch (assetName) {
      case 'Bitcoin':
        this.bitcoinFilter = !this.bitcoinFilter;
        break;

      case 'Ethereum':
        this.ethereumFilter = !this.ethereumFilter;
        break;

      case 'USDC':
        this.usdcFilter = !this.usdcFilter;
        break;

      case 'USDT':
        this.usdtFilter = !this.usdtFilter;
        break;
    }

    this.submitFilter();
  }

  getAssetStatus(assetName: string) {
    switch (assetName) {
      case 'Bitcoin':
        return this.bitcoinFilter;

      case 'Ethereum':
        return this.ethereumFilter;

      case 'USDC':
        return this.usdcFilter;

      case 'USDT':
        return this.usdtFilter;

      default:
        return false;
    }
  }

  getRequestStatusIcon(request: PaymentRequestModel): string {
    let requestStatus = this.getRequestStatus(request);
    const iconMap: any = {
      Finished: 'paid',
      PaymentPending: 'request-pending',
      PaymentValidationRequired: 'pending-warning',
      Canceled: 'canceled',
    };
    const withdrawalStatus =
      request.bill?.paymentReports[0]?.paymentTransactions[0]
        ?.paymentTransfers[0]?.exchangeConversionRequestStatus;
    return (
      this.defaultIconsPath +
        iconMap[requestStatus] +
        this.getIconsWithdrawalStatus(withdrawalStatus) || ''
    );
  }

  getIconsWithdrawalStatus(withdrawalStatus: ExchangeConversionRequestStatus) {
    if (
      withdrawalStatus === 'None' ||
      withdrawalStatus === 'NotConfigured' ||
      !withdrawalStatus
    ) {
      return '.png';
    } else {
      return `-${withdrawalStatus}.png`;
    }
  }

  paymentReportClick(transaction: PaymentReportModel): void {
    const paymentReportStatus = this.getPaymentReportStatus(transaction);
    const withdrawalStatus =
      transaction.paymentTransactions[0]?.paymentTransfers[0]
        ?.exchangeConversionRequestStatus;
    const hasWithdrawalSettings =
      withdrawalStatus === ExchangeConversionRequestStatus.Error ||
      withdrawalStatus === ExchangeConversionRequestStatus.Started;
    if (
      (paymentReportStatus !== 'Finished' &&
        paymentReportStatus !== 'Pending') ||
      hasWithdrawalSettings
    ) {
      this.openTransactionWarningsModal(transaction);
    }
  }

  requestClick(request: PaymentRequestModel): void {
    const requestStatus = this.getRequestStatus(request);
    const withdrawalStatus =
      this.getRequestEchangeConversionRequestStatus(request);
    const hasWithdrawalSettings =
      withdrawalStatus === ExchangeConversionRequestStatus.Error ||
      withdrawalStatus === ExchangeConversionRequestStatus.Started;

    if (requestStatus !== 'Finished' || hasWithdrawalSettings) {
      this.modalMarkAsPaid = true;
      this.paidId = request.bill.shortId;
      this.currentPayment = request;
      if (requestStatus != 'Finished') {
        this.getPaymentStatus();
      } else {
        this.paymentWarnings.push(
          this.translate.instant(
            `ExchangeConversionRequestStatus.${withdrawalStatus}`
          )
        );
      }
      this.paymentRequestAsset = request.blockchainAssetId;
    }
  }

  getPaymentReportStatus(transaction: PaymentReportModel): string {
    const { paymentTransactions } = transaction;
    const transactionStatus = paymentTransactions?.[0]?.checkedStatus;
    const paymentTransfers = paymentTransactions?.[0]?.paymentTransfers;

    if (transactionStatus === 'Finished' && paymentTransfers?.length > 1) {
      const exchangeConversionRequestStatus =
        paymentTransfers[0]?.exchangeConversionRequestStatus;
      const conversion =
        exchangeConversionRequestStatus != 'None' &&
        exchangeConversionRequestStatus != 'NotConfigured';
      if (conversion) {
        return this.getExchangeConversionStatus(
          exchangeConversionRequestStatus
        );
      }
    }

    if (transactionStatus === 'Finished' && paymentTransfers.length < 1) {
      return 'Error';
    }

    if (transactionStatus === 'TxFound') {
      return 'Pending';
    }

    return transactionStatus || 'Error';
  }

  getWithdrawalTittle(withdrawalStatus: string | undefined) {
    if (
      withdrawalStatus != 'None' &&
      withdrawalStatus != 'NotConfigured' &&
      withdrawalStatus != null &&
      withdrawalStatus != undefined
    ) {
      return `${this.translate.instant(
        'REGISTER.and'
      )} ${this.translate.instant(
        `ExchangeConversionRequestStatus.${withdrawalStatus}`
      )}`;
    }
    return '';
  }

  getRequestEchangeConversionRequestStatus(request: PaymentRequestModel) {
    return request.bill?.paymentReports[0]?.paymentTransactions[0]
      ?.paymentTransfers[0]?.exchangeConversionRequestStatus;
  }

  getWithdrawalStatus(
    transaction: PaymentReportModel | undefined
  ): ExchangeConversionRequestStatus {
    if (!transaction) {
      return ExchangeConversionRequestStatus.None;
    }
    const { paymentTransactions } = transaction;
    const transactionStatus = paymentTransactions?.[0]?.checkedStatus;
    const paymentTransfers = paymentTransactions?.[0]?.paymentTransfers;

    if (transactionStatus === 'Finished' && paymentTransfers?.length > 1) {
      const exchangeConversionRequestStatus =
        paymentTransfers[0]?.exchangeConversionRequestStatus;
      const conversion =
        exchangeConversionRequestStatus != 'None' &&
        exchangeConversionRequestStatus != 'NotConfigured';
      if (conversion) {
        return exchangeConversionRequestStatus;
      } else {
        return exchangeConversionRequestStatus || 'None';
      }
    }

    return ExchangeConversionRequestStatus.None;
  }

  getExchangeConversionStatus(
    exchangeConversionRequestStatus: string | undefined | null
  ) {
    if (exchangeConversionRequestStatus === 'Started') {
      return 'Pending';
    }
    if (exchangeConversionRequestStatus === 'Success') {
      return 'Finished';
    }
    if (exchangeConversionRequestStatus === 'Error') {
      return 'Error';
    }

    return 'Error';
  }

  getPaymentRequestPaymentWithdrawalIcon(request: PaymentRequestModel) {
    const paymentReport = request.bill?.paymentReports[0];
    return this.getPaymentWithdrawalIcon(paymentReport);
  }

  getPaymentWithdrawalIcon(transaction: PaymentReportModel): string {
    let withdrawalStatus = transaction
      ? this.getWithdrawalStatus(transaction)
      : undefined;
    if (withdrawalStatus === 'None') {
      withdrawalStatus = ExchangeConversionRequestStatus.Started;
    }
    return `${this.defaultIconsPath}${
      withdrawalStatus || 'Started'
    }-withdrawal.png`;
  }

  getPaymentReportIcon(transaction: PaymentReportModel): string {
    const paymentReportStatus = this.getPaymentReportStatus(transaction);
    const iconMap: any = {
      Finished: 'success',
      Pending: 'request-pending',
      Error: 'transaction-warning',
    };
    const withdrawalStatus =
      transaction.paymentTransactions[0]?.paymentTransfers[0]
        ?.exchangeConversionRequestStatus;
    return (
      this.defaultIconsPath +
      (iconMap[paymentReportStatus] || 'transaction-warning.png') +
      this.getIconsWithdrawalStatus(withdrawalStatus)
    );
  }

  withdrawalErrors(withdrawalStatus: ExchangeConversionRequestStatus): void {
    this.openWithdrawalModal(withdrawalStatus);
  }

  openTransactionWarningsModal(transaction: PaymentReportModel): void {
    this.transactionWarnings = [];

    const withdrawalStatus =
      transaction.paymentTransactions[0]?.paymentTransfers[0]
        ?.exchangeConversionRequestStatus;
    const hasWithdrawalSettings =
      withdrawalStatus === ExchangeConversionRequestStatus.Error ||
      withdrawalStatus === ExchangeConversionRequestStatus.Started;

    const paymentTransactions = transaction.paymentTransactions[0];
    // const isInTimeWindow = paymentTransactions?.isWithinQuoteTimeWindow;
    const checkedStatus = paymentTransactions?.checkedStatus;
    const foundTransfer = paymentTransactions?.paymentTransfers || [];
    const paymentReportStatus = this.getPaymentReportStatus(transaction);

    if (hasWithdrawalSettings) {
      this.transactionWarnings.push(
        this.translate.instant(
          `ExchangeConversionRequestStatus.${withdrawalStatus}`
        )
      );
    }

    if (checkedStatus === 'Error') {
      if (foundTransfer.length < 1) {
        this.transactionWarnings.push(
          this.defaultTransactionWarnings.didntFindAnyTransaction
        );
      }

      // if (!isInTimeWindow && foundTransfer.length > 0) {
      //   this.transactionWarnings.push(this.defaultTransactionWarnings.outOftime);
      // }

      if (paymentReportStatus !== 'Finished') {
        if (foundTransfer) {
          this.transactionWarnings.push(
            this.defaultTransactionWarnings.outOftime
          );
        } else {
          this.transactionWarnings.push(
            this.defaultTransactionWarnings
              .theTransactionDoesntHaveBeenSentToTheTargetAddress
          );
        }
      }
    } else if (checkedStatus === 'Pending') {
      this.transactionWarnings.push(
        this.defaultTransactionWarnings.paymentRequiresValidation
      );
    } else if (checkedStatus === 'Finished') {
      if (paymentReportStatus !== 'Finished') {
        this.transactionWarnings.push(
          this.defaultTransactionWarnings
            .theTransactionDoesntHaveBeenSentToTheTargetAddress
        );
      }
    }

    if (!paymentTransactions) {
      this.transactionWarnings.push(
        this.defaultTransactionWarnings.didntFindAnyTransaction
      );
    }

    this.transactionWarningsModal = true;
    this.showModal = true;
  }

  openWithdrawalModal(withdrawalStatus: ExchangeConversionRequestStatus): void {
    this.transactionWarnings = [];

    if (withdrawalStatus === 'None') {
      return;
    }

    if (withdrawalStatus === 'Success') {
      this.toastrService.success(
        this.translate.instant(
          `ExchangeConversionRequestStatus.${withdrawalStatus}`
        )
      );
    }

    this.transactionWarnings.push(
      this.translate.instant(
        `ExchangeConversionRequestStatus.${withdrawalStatus}`
      )
    );

    this.transactionWarningsModal = true;
    this.showModal = true;
  }

  getRequestStatus(request: PaymentRequestModel): string {
    const { status } = request;

    if (status === 'Finished') {
      return 'Finished';
    }

    if (status === 'Canceled') {
      return 'Canceled';
    }

    if (status === 'PaymentPending' && !this.requestPaymentError(request)) {
      return 'PaymentPending';
    }

    if (
      status === 'PaymentValidationRequired' ||
      (this.requestPaymentError(request) && status !== 'Finished')
    ) {
      return 'PaymentValidationRequired';
    }

    return 'PaymentValidationRequired';
  }

  formatPaymentRequestStatusFromView(request: PaymentRequestModel) {
    const { status } = request;
    const { bill } = request;
    const paymentReports = bill.paymentReports;

    if (status === 'Finished') {
      return 'Paid';
    }
    if (status === 'PaymentPending' && paymentReports?.length > 0) {
      const { checkedStatus } = paymentReports[0]?.paymentTransactions[0] || {};

      if (checkedStatus === 'Finished' || checkedStatus === 'Error') {
        return 'PaymentValidationRequired';
      }
    }
    return status;
  }

  showMoreOrLessIcons(flag: boolean): string {
    return `/assets/icons/${flag ? 'show-less' : 'show-more'}.png`;
  }

  getRequestAssetId(request: PaymentRequestModel): string {
    const { blockchainAssetId } = request;

    if (
      blockchainAssetId.startsWith('ETH.') ||
      blockchainAssetId.startsWith('BTC.') ||
      blockchainAssetId.startsWith('USD.') ||
      blockchainAssetId.startsWith('EUR.')
    ) {
      return blockchainAssetId.substring(4);
    }

    return blockchainAssetId;
  }

  createNamedPaymentRequest(target: User) {
    this.targetPaymentRequestUser = target;
    this.openPaymentRequestFlow();
  }

  openPaymentRequestFlow() {
    if (this.notVerified) {
      return;
    }
    if (this.userActiveWallets.length > 0) {
      this.newPaymentRequest = true;
    } else
      this.toastrService.error(
        this.translate.instant(
          'DASHBOARD.itsNecessaryToHaveAnVerifiedExchageToCreateAPaymentRequest'
        ),
        'Something went wrong',
        {
          timeOut: 3000,
        }
      );
  }

  openPayment(target: any) {
    this.buildTargetObject(target);
    this.paymentFlow = true;
    if (this.isMobile) {
      this.hideFooter = true;
    }
  }

  openProfile(target: any) {
    if (this.isNickUser(target)) {
      localStorage.setItem(
        this.tab + 'CurrentPage',
        this.currentPage['transactions'].page.toString()
      );
      localStorage.setItem('shortId', this.user.shortId);
      this.buildTargetObject(target);
      this.userProfile = true;
      this.hideFooter = true;
    }
  }

  isNickUser(target: any) {
    return target?.shortId;
  }

  buildTargetObject(target: any) {
    this.target = target;
    this.targetUser = new UserClass(target);
    this.target.id = target?.id || target.userId;
    this.target.email = target?.email || target.nick;
    this.targetDomains = target.domains;
    this.userId = target.userId;
    this.targetNick = target.nick;
    this.targetCompany = target.publicName;
    this.companyWebsite = target.websiteUrl;
  }

  public showExchangeModal(exchange: string) {
    this.showModal = true;
    this.modalMessage = exchange;
    this.exchangeModal = true;
  }

  public openResendEmailModal(payerEmail: string, shortId: string) {
    this.resendEmailShortId = shortId;
    this.resendEmailModal = true;
    this.currentSentEmail = payerEmail;
  }

  public resendPaymentRequest() {
    this.paymentRequestService
      .resendPaymentRequestEmail(this.resendEmailShortId)
      .pipe(
        tap((response: any) => {
          this.resendEmailModal = false;
          this.toastrService.success(
            this.translate.instant('sucessfullyResendThePaymentRequestEmail') +
              ' ' +
              this.currentSentEmail,
            '',
            {
              timeOut: 3000,
            }
          );
        }),
        catchError((error: any) => {
          if (error.error?.tittle == 'Not Found') {
            this.translate.instant('notFound');
          } else {
            this.toastrFactory.unknownError(
              this.translate.instant('unknownError')
            );
          }

          return EMPTY;
        })
      )
      .subscribe();
  }

  public getFormattedDate(date: string) {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  public async addFavorite(nick: string) {
    if (this.notVerified) {
      return;
    }

    let alreadyFavorite: any = await firstValueFrom(
      await this.favoritesService.favoriteByNick(nick)
    );

    if (alreadyFavorite?.length > 0) {
      this.toastrService.success(
        this.translate.instant('userIsAlreadyInYourFavorites'),
        '',
        {
          timeOut: 3000,
        }
      );
    } else {
      this.favoritesService
        .addFavorite(nick)
        .pipe(
          tap((response: any) => {
            this.toastrService.success(
              this.translate.instant('userAddedToYourFavoritesSuccessfully'),
              '',
              {
                timeOut: 3000,
              }
            );
            this.searchResults = response;
            this.loadFavorites();
          }),
          catchError((error: any) => {
            this.toastrFactory.handleError(error);
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  getCurrentUserNick() {
    this.auth.user$
      .pipe(
        tap((user: any) => {
          this.myNick = user?.email;
        })
      )
      .subscribe();
  }

  public deleteNick(favorite: any) {
    this.favoritesService
      .deleteFavorite(favorite)
      .pipe(
        tap((response: any) => {
          this.loadFavorites();
          this.toastrService.success(
            this.translate.instant('theNickHasBeenSuccessfullyRemoved'),
            '',
            {
              timeOut: 3000,
            }
          );
        }),
        catchError((error: any) => {
          this.toastrFactory.unknownError(
            this.translate.instant('unknownError')
          );
          return EMPTY;
        })
      )
      .subscribe();
  }

  showingNicks() {
    let total;
    if (this.tab == 'requests') {
      total = this.totalRequests;
    } else {
      total = this.totalTransactions;
    }
    if (total) {
      if (!this.currentPage[this.tab].page) this.currentPage[this.tab].page = 0;

      this.start =
        (this.currentPage[this.tab].page != 0
          ? this.currentPage[this.tab].page - 1
          : this.currentPage[this.tab].page) * this.itemsPerPage;
      this.end = this.start + this.itemsPerPage;
      if (this.end > total) {
        this.end = total;
      }
      if (this.end == 0) return this.start + 0 + ' - ' + this.end * 1;
      return this.start + 1 + ' - ' + this.end * 1;
    }
    return 0;
  }

  getPagesNumber(totalItems: number) {
    let pages = totalItems / this.itemsPerPage;
    if (!Number.isInteger(pages)) {
      return Number.parseInt((pages + 1).toString());
    }
    return pages;
  }

  previousPage() {
    if (this.currentPage[this.tab].page > 1) {
      this.currentPage[this.tab].page -= 1;
      // localStorage.setItem(this.tab + 'CurrentPage', this.currentPage[this.tab].page.toString())
      this.submitFilter();
    }
  }

  nextPage(totalItems: number) {
    if (this.currentPage[this.tab].page < this.getPagesNumber(totalItems)) {
      this.currentPage[this.tab].page += 1;
      // localStorage.setItem( this.tab + 'CurrentPage', this.currentPage[this.tab].page.toString())
      this.submitFilter();
    }
  }

  public onCopyTransactionId(transactionID: string) {
    navigator.clipboard.writeText(transactionID);
    this.toastrService.success(
      this.translate.instant('theTransactionIdHasBeenCopied'),
      this.translate.instant('success'),
      {
        timeOut: 3000,
      }
    );
  }

  public onCopyRequestId(transactionID: string) {
    navigator.clipboard.writeText(transactionID);
    this.toastrService.success(
      this.translate.instant('theRequestIdHasBeenCopied'),
      this.translate.instant('success'),
      {
        timeOut: 3000,
      }
    );
  }

  public onCopyPaymentId(paymentId: string) {
    navigator.clipboard.writeText(paymentId);
    this.toastrService.success(
      this.translate.instant('thePaymentIdHasBeenCopied'),
      this.translate.instant('success'),
      {
        timeOut: 3000,
      }
    );
  }

  noCurrentUserOffRampSettings(fromFunction?: boolean) {
    if (
      this.currentUserOffRampSettings &&
      this.currentUserOffRampSettings.length < 1
    ) {
      if (fromFunction)
        this.toastrService.error(
          this.translate.instant(
            'PROFILE.youHaveToConfigureYourAutomaticConversion'
          )
        );
      return true;
    }
    return false;
  }

  getAvailableConnectors() {
    this.offRampService
      .getAvailableConnectors()
      .pipe(
        tap((response: any) => {
          this.availableConnectors = response;
        }),
        catchError((error: any) => {
          console.log(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  getOffRampAllSettings() {
    this.offRampService
      .getOffRampAllSettings()
      .pipe(
        tap((response: any) => {
          this.currentUserOffRampSettings = [];
          response.forEach((setting: savedOffRampSettings) => {
            if (setting.isEnabled)
              this.currentUserOffRampSettings.push(setting);
          });
          this.currentUserOffRampSettings.forEach(
            (offRampSetting: savedOffRampSettings) => {
              this.currentOptionsSelects.currentOffRampSettings.push({
                name: offRampSetting.cryptoConnection.name || undefined,
                value: offRampSetting.cryptoConnection.id,
              });
            }
          );
          this.manualOffRampSettings.cryptoConnectionId =
            this.currentOptionsSelects.currentOffRampSettings[0]?.value || '';
        }),
        catchError((error: any) => {
          console.log(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  exportTable() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Dashboard table');

    const table = document.getElementById(
      `${this.tab}-table`
    ) as HTMLTableElement;

    if (this.tab === 'transactions') {
      this.exportPaymentReportsTable(table, worksheet);
    }

    if (this.tab === 'requests') {
      this.exportPaymentRequestTable(table, worksheet);
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const tableName =
        this.tab === 'transactions'
          ? 'DASHBOARD.paymentReport'
          : 'DASHBOARD.paymentRequests';
      a.download = this.translate.instant(tableName) + '.xlsx';
      a.click();

      URL.revokeObjectURL(url);
    });
  }

  exportPaymentReportsTable(
    table: HTMLTableElement,
    worksheet: ExcelJS.Worksheet
  ) {
    const headerRow = table.querySelector(
      'tr.only-desktop, tr.hide-portrait'
    ) as HTMLTableRowElement;
    const headers = Array.from(headerRow?.cells || [])
      .map((cell) => cell.innerText.trim())
      .filter((text) => text);

    const pagadorIndex = headers.indexOf(
      this.translate.instant('DASHBOARD.sender')
    );
    const statusIndex = headers.indexOf(
      this.translate.instant('PROFILE.status')
    );
    const shortIdIndex = headers.indexOf(
      this.translate.instant('DASHBOARD.shortId')
    );
    const transactionIdIndex = headers.indexOf(
      this.translate.instant('DASHBOARD.transactionId')
    );

    if (pagadorIndex !== -1) {
      headers.splice(pagadorIndex + 1, 0, 'Email');
    }

    const headerRowExcel = worksheet.addRow(headers);

    const columnWidths: number[] = headers.map((header) => header.length);

    const rows = Array.from(
      table.querySelectorAll('tr.only-desktop, tr.hide-portrait')
    ).slice(1);
    rows.forEach((row) => {
      let rowData = Array.from((row as HTMLTableRowElement).cells).map(
        (cell) => {
          let cellText = cell.innerText || '--';

          if (cellText.trim() === this.translate.instant('DASHBOARD.addHere')) {
            cellText = '--';
          }

          return cellText.trim();
        }
      );

      const payerCell = rowData[pagadorIndex];
      const shortIdCell = rowData[shortIdIndex];

      const [name, email] = payerCell.split('\n');

      rowData[pagadorIndex] = name;

      rowData[transactionIdIndex] = this.transactions.filter(
        (transaction) => shortIdCell === transaction.bill.shortId
      )[0]?.paymentTransactions[0]?.blockchainTransactionId;

      rowData.splice(pagadorIndex + 1, 0, email);
      rowData.pop();

      rowData.forEach((data, i) => {
        columnWidths[i] = Math.max(columnWidths[i], data?.length);
      });

      const rowExcel = worksheet.addRow(rowData);

      const statusCell = rowExcel.getCell(statusIndex + 2);
      const statusText = statusCell.value?.toString()?.trim()?.toLowerCase();

      if (statusText === this.translate.instant('Error').toLowerCase()) {
        statusCell.font = { color: { argb: 'FFFF0000' } };
      } else if (
        statusText === this.translate.instant('Finished').toLowerCase()
      ) {
        statusCell.font = { color: { argb: 'FF00FF00' } };
      }

      rowExcel.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      });
    });

    headerRowExcel.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000FF' },
      };
      cell.font = { color: { argb: 'FFFFFFFF' }, bold: true };
      // cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });

    worksheet.columns.forEach((column, i) => {
      column.width = columnWidths[i] + 4;
    });
  }

  exportPaymentRequestTable(
    table: HTMLTableElement,
    worksheet: ExcelJS.Worksheet
  ) {
    const headerRow = table.querySelector(
      'tr.only-desktop, tr.hide-portrait'
    ) as HTMLTableRowElement;
    const headers = Array.from(headerRow?.cells || [])
      .map((cell) => cell.innerText.trim())
      .filter((text) => text && text !== this.translate.instant('Actions'));

    const pagadorIndex = headers.indexOf(
      this.translate.instant('DASHBOARD.sender')
    );
    const statusIndex = headers.indexOf(
      this.translate.instant('PROFILE.status')
    );
    const shortIdIndex = headers.indexOf(
      this.translate.instant('DASHBOARD.shortId')
    );

    if (pagadorIndex !== -1) {
      headers.splice(pagadorIndex + 1, 0, 'Email');
    }

    const headerRowExcel = worksheet.addRow(headers);

    const columnWidths: number[] = headers.map((header) => header?.length);

    const rows = Array.from(
      table.querySelectorAll('tr.only-desktop, tr.hide-portrait')
    ).slice(1);
    rows.forEach((row) => {
      let rowData = Array.from((row as HTMLTableRowElement).cells).map(
        (cell) => {
          let cellText = cell.innerText || '--';

          if (cellText.trim() === this.translate.instant('DASHBOARD.addHere')) {
            cellText = '--';
          }

          return cellText.trim();
        }
      );

      const payerCell = rowData[pagadorIndex];
      const shortIdCell = rowData[shortIdIndex];

      const [name, email] = payerCell.split('\n');

      rowData[pagadorIndex] = name;

      rowData.splice(pagadorIndex + 1, 0, email);
      rowData.pop();

      rowData.forEach((data, i) => {
        columnWidths[i] = Math.max(columnWidths[i], data?.length);
      });

      const rowExcel = worksheet.addRow(rowData);

      const statusCell = rowExcel.getCell(statusIndex + 2);
      const statusText = statusCell.value?.toString().toLowerCase();

      switch (statusText) {
        case this.translate.instant('Canceled').toLowerCase():
          statusCell.font = { color: { argb: 'FFFF0000' } };
          break;

        case this.translate.instant('Paid').toLowerCase():
          statusCell.font = { color: { argb: 'FF00FF00' } };
          break;

        case this.translate.instant('PaymentValidationRequired').toLowerCase():
          statusCell.font = { color: { argb: 'FFFF9800' } };
          break;

        case this.translate.instant('Pending').toLowerCase():
          statusCell.font = { color: { argb: 'FFFF9800' } };
          break;
      }

      rowExcel.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      });
    });

    headerRowExcel.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000FF' },
      };
      cell.font = { color: { argb: 'FFFFFFFF' }, bold: true };
      // cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });

    worksheet.columns.forEach((column, i) => {
      column.width = columnWidths[i] + 4;
    });
  }

  openManualWithdrawlFlow() {
    if (this.noCurrentUserOffRampSettings(true)) {
      return;
    }
    this.manualWithdraw = true;
  }

  public onCopyRequestLink(shortId: string) {
    navigator.clipboard.writeText(this.url + shortId);
    this.toastrService.success(
      this.translate.instant('theLinkToThePaymentRequestHasBeenCopied'),
      '',
      {
        timeOut: 3000,
      }
    );
  }

  async shareScreen(shortId: string) {
    try {
      const requestUrl = this.url + shortId;
      this.qrCode = qrApi + this.url + shortId;
      const qrCodeImage = this.qrCode;
      const blob = await fetch(qrCodeImage);
      const imageBlob = await blob.blob();
      const imageBuffer = await imageBlob.arrayBuffer();
      const base64Image = this.arrayBufferToBase64(imageBuffer);
      const text =
        this.translate.instant('DASHBOARD.clickOnThisLink') +
        requestUrl +
        this.translate.instant('DASHBOARD.toBeRedirectedTo');
      if (navigator.share) {
        await navigator.share({
          title: this.translate.instant('DASHBOARD.shareTitle'),
          text: text,
          url: '/home?paymentId=' + shortId,
          files: [new File([imageBlob], 'qr_code.png', { type: 'image/png' })],
        });
      } else {
        this.toastrService.error(
          this.translate.instant('DASHBOARD.shareError')
        );
      }
    } catch (error) {
      console.log('Error sharing:', error);
    }

    // navigator.share({
    //   title: 'Nicky Payment Request Link',
    //   text: 'Click on this link to be redirected to the payment request on nicky.',
    //   url: '/home?paymentId=' + requestLink
    // })
    // .then(() => console.log('Successfully shared'))
    // .catch((error) => console.log('Error sharing:', error));
  }

  arrayBufferToBase64(buffer: ArrayBuffer): string {
    const binary = String.fromCharCode(...new Uint8Array(buffer));
    return btoa(binary);
  }

  public async getAllAssetsBalances() {
    this.convertedAsset = await this.convertAsset.getConvertedAssets(
      this.balances
    );

    for (let balance of this.balances) {
      const asset = this.convertAsset.formatAsset(balance.blockchainAsset.id);
      const amountNative = balance.amountNative;
      let convertedAssetValue = this.convertedAsset[asset]?.value;

      this.totalBalances += convertedAssetValue * amountNative;
    }

    this.alreadyConverted = true;
  }

  private getUserData() {
    this.getCryptoConnections();
    this.loadFavorites();
    this.loadAccountBalances();
    this.getAllPaymentReports();
    this.getAllPaymentRequests();
    this.isAuthenticated();
    this.getOffRampAllSettings();
    this.getAvailableConnectors();
    this.getUserDomain();
    this.redirectToPayment();
  }

  private async getUserDomain() {
    this.userService
      .getDomain()
      .pipe(
        tap((response: any) => {
          this.domain = response[0];
          this.domainIsLoaded = true;
        }),
        catchError((error: any) => {
          this.toastrService.error(
            this.translate.instant('unableToValidatedDns'),
            '',
            {
              timeOut: 5000,
            }
          );
          console.log(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  private loadFavorites() {
    this.favoritesService
      .getUserFavorites()
      .pipe(
        tap((response: any) => {
          this.favoritesList = response;
          this.favoritesList?.slice(0, 5).forEach((favorite: any) => {
            this.getReceiverConnections(favorite);
          });
        }),
        catchError((error: any) => {
          if (!error?.Message?.includes('Error saving new user')) {
            this.toastrFactory.unknownError(
              this.translate.instant('unknownError')
            );
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  getReceiverConnections(favorite: any) {
    favorite.loading = true;
    this.userService
      .getReceiverConnections(favorite.userId)
      .pipe(
        tap((response: any) => {
          if (response.length > 0) {
            favorite.hasWallets = true;
          }
          favorite.loading = false;
        })
      )
      .subscribe();
  }

  getFavoritePayTooltip(favorite: any) {
    if (favorite.loading) {
      return;
    }

    if (favorite?.hasWallets) {
      const pay = this.translate.instant('public-profile.pay');
      return pay[0] + pay.slice(1).toLowerCase();
    }
    return this.translate.instant('thisUserIsNotCurrentlyAcceptingPayments');
  }

  public submitFilter(submitedFromButton?: boolean) {
    const isTransactionsTab = this.tab === 'transactions';

    if (submitedFromButton) {
      if (isTransactionsTab) {
        this.isFilterActive = true;
      } else {
        this.isRequestFilterActive = true;
      }
    }

    this.setButtonFilterValue(submitedFromButton);
    this.openFilter = false;

    if (isTransactionsTab) {
      this.getPaymentReports();
    } else {
      this.prepareRequestFilter();
      this.getAllPaymentRequests();
    }
  }

  private prepareRequestFilter() {
    this.requestFilter = [];
    if (this.choosenStatus) {
      this.requestFilter.push(this.choosenStatus);
      if (this.choosenStatus === 'Pending') {
        this.requestFilter.push('PaymentValidationRequired');
      }
    }
  }

  getCryptoConnections() {
    this.userService
      .getCryptoConnections()
      .pipe(
        tap((walletsConections: any) => {
          walletsConections.forEach((wallet: any) => {
            this.userActiveWallets.push(wallet.cryptoDataSource);
          });
          this.userExchanges = walletsConections;
          this.checkPaymentRequestCreationParams();
        }),
        catchError((error: any) => {
          console.log(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  translateDate(date: string | null) {
    if (!date) {
      return '';
    }
    const month = date.split(' ')[0];
    const dayAndYear = ` ${date.split(' ')[1]} ${date.split(' ')[2]}`;
    const translatedMonth = this.translate.instant(
      `months.${month.toLowerCase()}`
    );

    return translatedMonth + dayAndYear;
  }

  getPaymentReports() {
    this.filterService
      .getAllPaymentReports(
        this.itemsPerPage,
        this.currentPage['transactions'].page,
        this.filteredEmails,
        this.filterForm,
        this.ascending
      )
      .pipe(
        tap((response: any) => {
          this.transactions = response.data;
          this.totalTransactions = response.total;
          let invalidIndex;
          this.showingNicks();
          while (this.start > parseInt(this.totalTransactions)) {
            if (this.currentPage['transactions'].page > 1) {
              if (this.totalTransactions > 0) {
                invalidIndex = true;
                this.transactions = [];
                this.showingNicks();
                if (this.start > parseInt(this.totalTransactions))
                  this.currentPage['transactions'].page--;
              }
            }
          }

          if (invalidIndex) {
            this.submitFilter();
          }
        }),
        catchError((error: any) => {
          this.toastrFactory.handleError(error), console.log(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  // Ensure getAllPaymentRequests returns an Observable
  public getAllPaymentRequests() {
    this.filterService
      .getAllPaymentRequests(
        this.requestFilterForm,
        this.ascending,
        this.requestsfilteredEmails,
        this.itemsPerPage,
        this.currentPage['requests'].page,
        this.requestFilter
      )
      .pipe(
        tap((response: any) => {
          this.paymentRequests = response.data;
          this.totalRequests = response.total;
        }),
        catchError((error: any) => {
          this.toastrFactory.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  isAuthenticated() {
    this.auth.isAuthenticated$.pipe(
      tap((response: any) => {
        this.authenticated = response;
      })
    ).subscribe(); 
  }
  

  cancelRequest(paymentId: string) {
    this.paymentRequestService
      .changeRequestStatus(paymentId, 'Canceled')
      .pipe(
        tap((response: any) => {
          this.toastrService.success(
            this.translate.instant('successfullyCanceledPaymentRequest'),
            '',
            {
              timeOut: 3000,
            }
          );
          this.getAllPaymentRequests();
        }),
        catchError((error: any) => {
          this.toastrFactory.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  // Usage in switchMap
  setRequestAsPaid(paymentId: string) {
    this.paymentRequestService
      .changeRequestStatus(paymentId, 'Finished')
      .pipe(
        tap(() => {
          this.toastrService.success(
            this.translate.instant('requestNowIsPaid'),
            '',
            {
              timeOut: 3000,
            }
          );
        }),
        switchMap(async () => this.getAllPaymentRequests()) // Ensure this returns an Observable
      )
      .subscribe({
        error: (e: any) => this.toastrFactory.handleError(e),
        complete: () =>
          console.log('Request marked as paid and payment requests updated'),
      });
  }

  markAsPaid() {
    this.toastrService.success(this.translate.instant('settledAsPaid'), '', {
      timeOut: 3000,
    });
  }

  public addEmailToFilter(key: any) {
    const isTransactionsTab = this.tab === 'transactions';
    const form = isTransactionsTab ? this.filterForm : this.requestFilterForm;
    const emailList = isTransactionsTab
      ? this.filteredEmails
      : this.requestsfilteredEmails;
    const email = form.controls['receiverNick'].value.trim();

    const isKeySpaceOrEnter = key.code === 'Space' || key.code === 'Enter';
    const isMobileSpace =
      this.isMobile && key.inputType === 'insertText' && key.data === ' ';

    if ((isKeySpaceOrEnter || isMobileSpace) && email) {
      emailList.push(email);
      const receiverNickController = form.controls['receiverNick'];
      receiverNickController.reset();
      receiverNickController.setValue('');
    }
  }

  public removeEmail(removedEmail: string) {
    const emails =
      this.tab === 'transactions'
        ? this.filteredEmails
        : this.requestsfilteredEmails;
    const index = emails.findIndex((email) => email === removedEmail);

    if (index !== -1) {
      emails.splice(index, 1);
    }
  }

  private loadAccountBalances() {
    this.userService
      .getBalances()
      .pipe(
        tap((response: any) => {
          (this.balances = response.total.filter(
            (balance: any) => balance.amountNative > 0
          )),
            this.balances.sort((assetA: any, assetB: any) => {
              assetA = this.assetIdFormatterUtil.formatAssetId(assetA);
              assetB = this.assetIdFormatterUtil.formatAssetId(assetB);
              return this.assetIdFormatterUtil.compareAssetIds(assetA, assetB);
            });

          this.totalBalances = 0;

          this.getAllAssetsBalances();
        }),
        catchError((error: any) => {
          const connectionsUrl =
            'https://' + window.location.host + '/settings?connectionsTab=true';
          const routesUrl =
            'https://' + window.location.host + '/settings?wallet=true';
          this.toastrService.error(
            this.translate.instant('unableToRetrieveBalances', {
              connectionsUrl: connectionsUrl,
              routesUrl: routesUrl,
            }),
            '',
            {
              enableHtml: true,
              disableTimeOut: true,
            }
          );

          setTimeout(() => {
            const connectionsLink =
              document.getElementsByClassName('connections-url');
            const routesLink = document.getElementsByClassName('routes-url');

            if (connectionsLink.length > 0) {
              (connectionsLink[0] as HTMLElement).onclick = () => {
                this.router.navigateByUrl('/settings?connectionsTab=true');
              };
            }

            if (routesLink.length > 0) {
              (routesLink[0] as HTMLElement).onclick = (event: any) => {
                this.router.navigateByUrl('/settings?wallets=true');
              };
            }
          }, 1000);

          // this.exchangeError(e);
          return EMPTY;
        })
      )
      .subscribe();
  }

  exchangeError(serverError: any) {
    console.log(serverError);
    if (
      serverError.error?.Message?.includes('Kraken') ||
      serverError.error?.ErrorCode?.includes('KrakenError')
    ) {
      this.toastrService.error(
        `${this.translate.instant('krakenMessage')} "${
          serverError.error.Message
        }" ${this.translate.instant('pleaseAccess')}`,
        this.translate.instant('krakenError'),
        {
          enableHtml: true,
          timeOut: 10000,
        }
      );
    } else {
      this.toastrService.error(
        `${
          serverError.error?.Message.includes('Exchange')
            ? 'Exchange Error -'
            : undefined
        } ${
          serverError.error?.Message.replace('System.Exception').split('\r')[0]
        }`,
        '',
        {
          timeOut: 10000,
        }
      );
    }
  }

  formatIfCommaDecimal(value: any) {
    if (this.formatCurrency.isCommaDecimal(navigator.language)) {
      return this.missingValue
        .toString()
        .replace(/\./g, '')
        .replace(/\,/g, '.');
    } else {
      return this.missingValue.toString().replace(/\,/g, '');
    }
  }

  submitReopenPayment() {
    if (
      this.missingValue?.trim() == '' ||
      this.missingValue == undefined ||
      parseFloat(this.formatIfCommaDecimal(this.missingValue)) <= 0
    ) {
      this.invalidMissingValue = true;
      return;
    }
    this.missingValue = this.formatIfCommaDecimal(this.missingValue);
    this.toastrService.success(
      this.translate.instant('successfullyReopened'),
      this.translate.instant('success')
    );
    this.closePaidModal();
    this.reopenPayment = false;
    this.missingValue = '';
    this.paymentRequestAsset = '';
  }

  formatAssetToUi(asset: string) {
    if (!asset) {
      return '';
    }
    return asset.split('.')[1];
  }

  closeReopenModal(back?: boolean) {
    this.invalidMissingValue = false;
    this.reopenPayment = false;
    if (!back) this.paymentRequestAsset = '';
  }

  reopenModal() {
    this.reopenPayment = true;
  }

  getScanAddress(transaction: PaymentReportModel) {
    const transactionAsset =
      transaction.paymentTransactions[0].blockchainAssetId;
    const transactionId =
      transaction.paymentTransactions[0].blockchainTransactionId;
    if (transactionAsset.startsWith('BTC.')) {
      return btcAddress + 'tx/' + transactionId.trim();
    }
    if (transactionAsset.startsWith('ETH.')) {
      return ethAddress + 'tx/' + transactionId.trim();
    }
    if (transactionAsset.startsWith('MATIC.')) {
      return polyAddress + 'tx/' + transactionId.trim();
    }
    if (transactionAsset.startsWith('BEP.')) {
      return bnbAddress + 'tx/' + transactionId.trim();
    }
    if (transactionAsset.startsWith('TRC.')) {
      return tronScan + 'transaction/' + transactionId.trim();
    }
    return '';
  }

  copyQrCode(shortIdCode: string) {
    this.selectedShortIdCode = shortIdCode;
    this.qrCode = qrApi + this.url + shortIdCode;
    this.qrModal = true;
  }

  async copyQr() {
    const response = await fetch(this.qrCode);
    const blob = await response.blob();
    this.copyQrToCliboard(blob);
  }

  copyQrToCliboard(imageBlob: any) {
    if (!this.cliboardItem) {
      const imageURL = URL.createObjectURL(imageBlob);
      const link = document.createElement('a');
      link.href = imageURL;
      link.download =
        this.translate.instant('DASHBOARD.paymentRequest') +
        this.selectedShortIdCode;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    try {
      const data = [new ClipboardItem({ [imageBlob.type]: imageBlob })];
      navigator.clipboard.write(data);
      this.toastrService.success(
        this.translate.instant('qrHasBeenCopied'),
        this.translate.instant('success'),
        {
          timeOut: 3000,
        }
      );
    } catch (e: any) {
      this.toastrFactory.unknownError(this.translate.instant('unknownError'));
      console.log(e);
    }
  }

  getPaymentStatus() {
    const paymentReports = this.currentPayment.bill.paymentReports;
    const requestAsset = this.currentPayment.blockchainAssetId;
    const requestPaymentAsset = this.currentPayment?.blockchainAssetId;
    let paymentDiff = this.currentPayment?.openAmountNative;
    // const quoteTimeWindow = this.currentPayment.bill.paymentReports[0].paymentTransactions[0].isWithinQuoteTimeWindow;
    const requestValue = this.currentPayment?.amountNative;
    const paymentValue =
      paymentReports[0]?.paymentTransactions[0]?.paymentTransfers[0]
        ?.amountNative;

    if (paymentReports?.length === 0) {
      this.paymentWarnings.push(this.translate.instant('paymentIsPending'));
      return;
    }

    const { checkedStatus } = paymentReports[0]?.paymentTransactions[0] || {};

    if (!(checkedStatus === 'Finished' || checkedStatus === 'Error')) {
      this.paymentWarnings.push(this.translate.instant('paymentIsPending'));
      return;
    }

    if (this.requestPaymentError(this.currentPayment)) {
      if (
        this.currentPayment.bill.paymentReports[0]?.paymentTransactions[0]
          .paymentTransfers
      ) {
        this.paymentWarnings.push(
          this.translate.instant('outOfTimeWindowPaymentRequest')
        );
        paymentDiff = requestValue - paymentValue;
        this.getValueDiff(paymentDiff, requestPaymentAsset);
      } else {
        this.paymentWarnings.push(
          this.translate.instant('errorOnPaymentValidation')
        );
      }
      return;
    }

    if (requestAsset == requestPaymentAsset) {
      this.getValueDiff(paymentDiff, requestAsset);
      return;
    }

    if (
      (requestAsset == 'ETH.USDT' || requestAsset == 'ETH.USDC') &&
      (requestPaymentAsset == 'ETH.USDT' || requestPaymentAsset == 'ETH.USDC')
    ) {
      paymentDiff = requestValue - paymentValue;
      this.paymentWarnings.push(this.translate.instant('stableCoins'));
      this.getValueDiff(paymentDiff, requestPaymentAsset);
      return;
    }
    // if (!quoteTimeWindows) {
    //   this.paymentWarnings.push(this.translate.instant('paymentNotMadedWithin') + ` ${minutes}` + this.translate.instant('minuteWindow'))
    //   return;
    // }

    if (
      paymentReports[0].paymentTransactions[0]
        .exchangeConversionRequestStatus === 'Error'
    ) {
      this.paymentWarnings.push(
        this.translate.instant('ExchangeConversionRequestStatus.Error')
      );
    }

    this.getValueDiff(paymentDiff, requestPaymentAsset);
    return;
  }

  closePaidModal() {
    this.modalMarkAsPaid = false;
    this.paymentWarnings = [];
  }

  requestPaymentError(paymentRequest: PaymentRequestModel) {
    try {
      if (
        paymentRequest.bill.paymentReports[0].paymentTransactions[0]
          .checkedStatus == 'Error'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  getValueDiff(paymentDiff: number, requestAsset: string): string {
    const requestAssetTicker = requestAsset.split('.')[1];

    const roundedUpPaymentDiff: string = this.roundUpUtil.roundUpAmount(
      paymentDiff,
      decimalPlacesFor[requestAssetTicker]
    );

    if (paymentDiff < 0) {
      this.paymentWarnings.push(
        `${this.translate.instant(
          'higherValueThanExpected'
        )}: ${requestAssetTicker} ${this.formatCurrency.convertToLocal(
          roundedUpPaymentDiff.toString()
        )}`
      );
    } else if (paymentDiff > 0) {
      this.paymentWarnings.push(
        `${this.translate.instant(
          'insufficientAmountStill'
        )} ${requestAssetTicker} ${this.formatCurrency.convertToLocal(
          roundedUpPaymentDiff.toString()
        )} ${this.translate.instant('leftToFullyCoverTheRequestedAmount')}`
      );
    }
    return paymentDiff.toString();
  }

  getDecimalPlaces(assetId: string) {
    return decimalPlacesFor[assetId?.split('.')[1] || 'BTC'];
  }

  roundUp(num: number, decimalPlaces: number) {
    const factor = Math.pow(10, decimalPlaces);
    let result = (Math.ceil(num * factor) / factor).toFixed(decimalPlaces);
    return result;
  }

  public fillFilterButtons() {
    this.modalBitcoinFilter = this.bitcoinFilter;
    this.modalEthereumFilter = this.ethereumFilter;
    this.modalUsdcFilter = this.usdcFilter;
    this.modalUsdtFilter = this.usdtFilter;
  }

  public setButtonFilterValue(submitedFromButton?: boolean) {
    let paid;
    let canceled;
    let pending;
    const status = this.requestFilterForm.controls['status'].value;

    if (status == 'Pending') {
      pending = true;
    } else if (status == 'Canceled') {
      canceled = true;
    } else if (status == 'Confirmed') {
      paid = true;
    }
    this.filterForm.controls['bitcoinFilter'].setValue(
      submitedFromButton ? this.modalBitcoinFilter : this.bitcoinFilter
    );
    this.filterForm.controls['ethereumFilter'].setValue(
      submitedFromButton ? this.modalEthereumFilter : this.ethereumFilter
    );
    this.filterForm.controls['usdcFilter'].setValue(
      submitedFromButton ? this.modalUsdcFilter : this.usdcFilter
    );
    this.filterForm.controls['usdtFilter'].setValue(
      submitedFromButton ? this.modalUsdtFilter : this.usdtFilter
    );
    this.requestFilterForm.controls['paidFilter'].setValue(
      submitedFromButton ? paid : this.paidFilter
    );
    this.requestFilterForm.controls['canceledFilter'].setValue(
      submitedFromButton ? canceled : this.canceledFilter
    );
    this.requestFilterForm.controls['pendingFilter'].setValue(
      submitedFromButton ? pending : this.pendingFilter
    );

    if (submitedFromButton) {
      this.bitcoinFilter = this.modalBitcoinFilter;
      this.ethereumFilter = this.modalEthereumFilter;
      this.usdcFilter = this.modalUsdcFilter;
      this.usdtFilter = this.modalUsdtFilter;
      this.pendingFilter = pending;
      this.canceledFilter = canceled;
      this.paidFilter = paid;
    }
    return;
  }

  checkWindowSize() {
    if (window.innerWidth > 1100) this.isMobile = false;

    if (this.isMobile) return;

    if (window.innerWidth <= 1100) {
      this.itemsPerPage = 5;
      this.isMobile = true;
    } else {
      this.itemsPerPage = 10;
    }

    if (window.innerWidth <= 1100) this.isMobile = true;
  }

  isAssetEnabled(asset: string) {
    const allowedAssets = ['BTC', 'ETH', 'USDC', 'USDT', 'USD', 'BRL', 'EUR'];
    return allowedAssets.includes(asset);
  }

  removeFilter(status: any) {
    this.requestFilter = this.requestFilter.filter(
      (filter) => filter !== status
    );
  }

  async ngOnInit(): Promise<void> {
    this.getCurrentUserNick();
    this.getCurrentUser();
    this.checkWindowSize();
    this.filterForm.controls['status'].setValue('');

    this.populateTourLegendAsAButton();
    this.populateTourButton();
  }

  ngAfterViewInit(): void {
    this.getSystemAssets();
    this.transactionWarnings = [
      this.translate.instant('weDidntFindAnyTransactionWithThisId'),
    ];
  }

  toggleBookIcon(open: boolean) {
    this.bookIconOpen = open;
  }

  async checkPaymentRequestCreationParams() {
    const queryParams = await firstValueFrom(this.params.queryParams);
    if (queryParams['paymentRequest']) {
      this.openPaymentRequestFlow();
    }
  }

  populateTourButton() {
    this.nextButton = {
      text: this.translate.instant('TOUR.next'),
      action() {
        return this.next();
      },
    };
    this.exitButton = {
      classes: 'exit-button',
      text: this.translate.instant('TOUR.exit'),
      action: () => {
        this.shepherdService.cancel();
        setTimeout(() => {
          this.toggleBookIcon(false);
        }, 100);
      },
    };
    this.backButton = {
      classes: 'back-button secondary-button',
      text: this.translate.instant('TOUR.back'),
      action: () => {
        this.shepherdService.back();
        setTimeout(() => {
          this.toggleBookIcon(true);
        }, 100);
      },
    };
    this.finishButton = {
      classes: 'secondary-button',
      text: this.translate.instant('TOUR.finish'),
      action: () => {
        this.shepherdService.complete();
        setTimeout(() => {
          this.toggleBookIcon(false);
        }, 100);
      },
    };

    this.defaultTourButtons.haveBackButton = [
      this.tourStepLegend,
      this.exitButton,
      this.backButton,
      this.nextButton,
    ];

    this.defaultTourButtons.onlyNextButton = [
      this.tourStepLegend,
      this.exitButton,
      this.nextButton,
    ];

    this.defaultTourButtons.haveFinishButton = [
      this.tourStepLegend,
      this.exitButton,
      this.backButton,
      this.finishButton,
    ];
  }

  populateTourLegendAsAButton() {
    this.tourStepLegend = {
      text: () => {
        const tourObject = this.shepherdService.tourObject;
        const stepsLength = tourObject.steps.length;
        const currentStep = this.shepherdService.tourObject.getCurrentStep();
        let stepIndex = -1;

        if (currentStep) {
          stepIndex = tourObject.steps.findIndex(
            (step) => step.id === currentStep.id
          );
        }

        return `${stepIndex + 1} ${this.translate
          .instant('of')
          .toLowerCase()} ${stepsLength}`;
      },
      classes: 'sheperd-steps-legend',
    };
  }

  populateTourDefaults() {
    this.defaultTourButtons.haveBackButton = [
      this.tourStepLegend,
      this.exitButton,
      this.backButton,
      this.nextButton,
    ];

    this.defaultTourButtons.haveFinishButton = [
      this.tourStepLegend,
      this.exitButton,
      this.backButton,
      this.finishButton,
    ];

    this.defaultTourButtons.onlyNextButton = [
      this.tourStepLegend,
      this.exitButton,
      this.nextButton,
    ];

    this.tourMobilePositionCheck = this.isMobile ? 'bottom' : 'right';

    this.tourPositionDefault = 'bottom';

    this.tourDefaults = {
      scrollTo: { behavior: 'smooth', block: 'center' },
    };

    this.shepherdService.defaultStepOptions = {
      scrollTo: this.tourDefaults,
      cancelIcon: {
        enabled: false,
      },
      canClickTarget: this.isMobile ? false : true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4,
    };
  }

  private setFocusOnInput() {
    const element = this.shepherdService.tourObject
      .getCurrentStep()
      ?.getTarget();

    if (this.isMobile) {
      element?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }

    if (element?.id === this.currentStepId) {
      return;
    }

    this.currentStepId = element?.id;

    if (!element) {
      return;
    }

    if (
      element.tagName.toLowerCase() === 'input' ||
      element.tagName.toLowerCase() === 'textarea'
    ) {
      element.focus({
        preventScroll: this.isMobile ? false : true,
      });
      return;
    }

    var inputElement =
      element.querySelector('input') || element.querySelector('textarea');

    if (inputElement) {
      inputElement.focus({
        preventScroll: this.isMobile ? false : true,
      });
      return;
    }
  }

  //TRANSACTIONS TOUR
  transactionsTour() {
    const tourDefaults = {
      highlightClass: 'highlight',
    };

    this.shepherdService.defaultStepOptions = {
      scrollTo: { behavior: 'smooth', block: 'center' },
      cancelIcon: {
        enabled: false,
      },
      canClickTarget: true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4,
    };

    this.populateTourDefaults();
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: 'payment-section',
        attachTo: {
          element: '#payment-section',
          on: this.tourMobilePositionCheck,
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.onlyNextButton,

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        title: this.translate.instant('TOUR.hereAreThePaymentReports'),
        text: this.translate.instant('TOUR.paymentReportsAreAmountTransferred'),
      },
      {
        id: 'payment-reports',
        attachTo: {
          element: '#payment-reports',
          on: this.tourMobilePositionCheck,
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        text: this.translate.instant('TOUR.paymentReportsAreAmountTransferred'),
      },
      {
        id: 'payment-requests',
        attachTo: {
          element: '#payment-requests',
          on: this.tourMobilePositionCheck,
        },

        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        when: {
          show: () => {
            this.changeTab('requests');
          },
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant('TOUR.paymentRequestsAreGenerated'),
      },
      {
        id: 'create-requests',
        attachTo: {
          element: '#create-requests',
          on: this.tourMobilePositionCheck,
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveFinishButton,

        text: this.translate.instant(
          'TOUR.thisIsWhereYouCreateAPaymentRequest'
        ),
      },
      // {
      //   id: "filter",
      //   attachTo: {
      //     element: '#filter',
      //     on: this.tourMobilePositionCheck
      //   },
      //   scrollTo: { behavior: 'smooth', block: 'center' },
      //   arrow: true,
      //   canClickTarget: true,

      //   buttons: this.defaultTourButtons.haveBackButton,

      //   text: this.translate.instant("TOUR.youCanFilterTheItemsYouWant")

      // },
      // {
      //   id: "fast-filters",
      //   attachTo: {
      //     element: '#fast-filters',
      //     on: this.tourMobilePositionCheck
      //   },
      //   scrollTo: { behavior: 'smooth', block: 'center' },
      //   arrow: true,
      //   canClickTarget: true,

      //   buttons: this.defaultTourButtons.haveFinishButton,

      //   highlightClass: tourDefaults.highlightClass,
      //   text: this.translate.instant("TOUR.youCanAlsoQuicklyFilter")

      // }
    ];

    this.startTour(steps);
  }

  private startTour(steps: StepOptionsButton.StepOptions[]) {
    steps.forEach((step) => {
      if (!step.when) {
        step.when = {
          show: () => {
            const currentStepText = step.text;
            const sheperdObject = this.shepherdService.tourObject;
            const stepIndex = this.shepherdService.tourObject.steps.findIndex(
              (currentStep) => currentStep.id === step.id
            );

            if (this.isMobile) {
              const element =
                this.shepherdService.tourObject.steps[stepIndex].getElement();
              if (element) element.focus = () => {};
              element?.scrollTo();
            }

            if (stepIndex === steps.length - 1) {
              this.shepherdService.tourObject.steps[
                stepIndex
              ].updateStepOptions({
                text:
                  currentStepText +
                  "<img src='/assets/white-nicky-logo.png' class='tour-logo'>",
              });
            }
            setTimeout(() => {
              this.setFocusOnInput();
            }, 500);
          },
        };
      }
    });
    this.shepherdService.addSteps(steps);
    this.shepherdService.start();
  }

  moreThanOneAt(input: string) {
    const regex = /@/g;
    const matches = input.match(regex);
    return matches ? matches.length : 0;
  }

  onSearch(searchInput: HTMLInputElement) {
    const minWidth: number = 3;
    const inputLength: number = searchInput.value
      .replaceAll('@', '')
      .trim().length;
    const atLength: number = this.moreThanOneAt(searchInput.value);

    if (searchInput.value.replaceAll('@', '').trim().length < minWidth) {
      const remainingChar = minWidth - inputLength;
      this.minimumCharMessage =
        this.translate.instant('HOME.youNeedToType') +
        remainingChar +
        this.translate.instant('HOME.moreChar') +
        (remainingChar == 1 ? '' : 's');
      if (atLength > 1) {
        this.minimumCharMessage += this.translate.instant('HOME.doesNotCount');
      }
      this.searchResults = [];
      return;
    }
    this.minimumCharMessage = '';
    this.searchService
      .generalSearch(searchInput.value)
      .pipe(
        tap((response: any) => {
          this.searchResults = response;
          if (this.searchResults?.length == 1) {
            if (searchInput.value.trim() == this.searchResults[0]?.nick) {
              this.searchResults[0].exactlyMatch = true;
            }
          }
        }),
        catchError((error: any) => {
          this.toastrFactory.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  updateSearchResults() {
    this.searchResults = '';
  }

  public clearFilter() {
    if (this.tab == 'transactions') {
      this.filterForm.reset();
      this.modalBitcoinFilter = false;
      this.modalEthereumFilter = false;
      this.modalUsdcFilter = false;
      this.modalUsdtFilter = false;
    }
    if (this.tab == 'requests') {
      this.requestFilterForm.reset();
      this.canceledFilter = false;
      this.pendingFilter = false;
      this.paidFilter = false;
      this.requestFilterForm.controls['status'].setValue('');
      this.requestFilterForm.controls['assetFilter'].setValue('');
      this.requestFilterForm.controls['paymentId'].setValue(undefined);
      this.requestsfilteredEmails = [];
      this.requestFilter = [];
    }
    this.setButtonFilterValue(true);
    this.filterForm.controls['status'].setValue('');
    this.isFilterActive = false;
    this.isRequestFilterActive = false;
    this.filteredEmails = [];
    if (this.tab == 'transactions') {
      this.getAllPaymentReports();
    } else {
      this.getAllPaymentRequests();
    }
  }

  refreshTable() {
    if (this.tab == 'transactions') {
      this.getAllPaymentReports();
    } else {
      this.getAllPaymentRequests();
    }
  }

  public getAllPaymentReports() {
    this.filterService
      .getAllPaymentReports(
        this.itemsPerPage,
        this.currentPage['transactions'].page,
        this.filteredEmails,
        this.filterForm,
        this.ascending
      ).pipe(
        tap((response: any) => {
          console.log(response.data[0]);
          this.transactions = response.data;
          this.totalTransactions = response.total;
          while (this.end > this.totalTransactions) {
            this.currentPage['transactions'].page--;
            // localStorage.setItem(this.currentTab + 'CurrentPage', this.currentPage['transactions'].page.toString())
          }

          this.cdr.detectChanges();
        }),
        catchError((error:any) => {
          this.toastrFactory.handleError(error)
          return EMPTY;
        })
      ).subscribe()
  
    this.openFilter = false;
  }

  public selectTab(selectedTab: string) {
    this.currentTab = selectedTab;
  }

  public isTheFilterFilled() {
    return false;
  }

  private getCurrentUser() {
    this.auth.user$.pipe(
      tap((user: any) => {
        this.userId = user.sub;
        user.email_verified == false ? (this.notVerified = true) : '';  
      }),
    ).subscribe();

    this.userService.getUser()
    .pipe(
      tap((response: any) => {
        this.user = response;

        if (localStorage.getItem('shortId') === this.user.shortId) {
          this.currentPage['transactions'].page = Number.parseInt(
            localStorage.getItem('transactionsCurrentPage') || '1'
          );
          this.currentPage['requests'].page = Number.parseInt(
            localStorage.getItem('requestsCurrentPage') || '1'
          );
          localStorage.removeItem('transactionsCurrentPage');
          localStorage.removeItem('requestsCurrentPage');
        } else {
          localStorage.removeItem('transactionsCurrentPage');
          localStorage.removeItem('requestsCurrentPage');
        }

        if (this.user.agreedPrivacyPolicy) {
          this.getUserData();
        } else {
          return;
        }
      }),
      catchError((error:any) => {
        this.toastrFactory.handleError(error)
        return EMPTY;
      })
    ).subscribe();
  }
}
