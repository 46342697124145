import { LyTheme2 } from '@alyle/ui';
import { LyDialog } from '@alyle/ui/dialog';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import * as auth0 from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, firstValueFrom, switchMap, tap } from 'rxjs';
import { toggleMenu } from 'src/app/app.component';
import { User, UserClass } from 'src/app/components/models/user.mode';
import { FavoritesService } from 'src/app/services/favorites.service';
import { PaymentRequestService } from 'src/app/services/payment.request.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SearchService } from 'src/app/services/search.service';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';


const STYLES = ({
  slider: {
    display: 'block',
    padding: '16px'
  }
});

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnInit {
  favoritesList: any;

  constructor(
    private favoritesService: FavoritesService,
    public auth: AuthService,
    public router: Router,
    private toastrService: ToastrService,
    private searchService: SearchService,
    private userService: UserService,
    private params: ActivatedRoute,
    private paymentRequestService: PaymentRequestService,
    private paymentService: PaymentService,
    private sanitize: DomSanitizer,
    public translate: TranslateService,
    private theme: LyTheme2,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef,
    private toastrFactory: ToastrFactoryService
  ) { }

  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
    audience: environment.auth0Audience,
  });

  readonly classes = this.theme.addStyleSheet(STYLES);


  public searchResults!: any;
  public isAuthenticated: any;
  public showModal!: boolean;
  public modalMessage!: string;
  public modalTittle!: string;
  public dashBoardOptions!: boolean;
  public modalNick!: string;
  public modalPublicName!: string;
  public myNick: any;
  public fullEmailModal!: boolean;
  public isMobile: boolean = false;
  public paymentFlow = false;
  public targetNick!: string;
  public userId!: string;
  public user: any;
  public targetCompany!: string;
  public companyWebsite!: string;
  public requestInfo: any;
  public assets: any;
  public userProfile!: boolean;
  public environment = environment;
  public minimumCharMessage!: string;
  public defaultBackgroundPosition: number = 5;
  public targetDomains: any;
  public closeTooltip: boolean = false;
  public exchangeModal!: boolean;
  public manyUsersModal!: boolean;
  public notify: boolean = true;
  public target!: User;
  public targetUser!: UserClass;
  public profileNotFound!: boolean;

  public selectedLanguage!: string;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize()
  }


  @ViewChild('search') search!: ElementRef<HTMLInputElement>;
  @ViewChild('searchDesktop') searchDesktop!: ElementRef<HTMLInputElement>;


  ngOnInit(): void {
    this.checkWindowSize()
    this.getSystemAssets();
    this.checkParams(true)
    // this.loginWithSocialError();
  }

  toggleMenu() {
    toggleMenu()
  }


  ngAfterViewInit(): void {
    this.userAuthenticated();
    this.setSearchBehavior();
  }


  public getSystemAssets() {
    this.paymentService.getAllAssets()
      .pipe(
        tap((response: any) => {
          this.assets = response;
        }),
        catchError((error: any) => {
          console.log(error);
          this.toastrFactory.unknownError(this.translate.instant('unknownError'));
          return EMPTY;
        })
      )
      .subscribe();
  }

  getShortIdByEmail(email: string) {
    this.searchService.generalSearch(email)
      .pipe(
        tap((response: any) => {
          if (response[0]?.shortId) {
            this.openPublicProfileByShortId(response[0]?.shortId);
          } else {
            this.notFoundProfile();
          }
        }),
        catchError((error: any) => {
          this.toastrFactory.unknownError(this.translate.instant('unknownError'));
          return EMPTY;
        })
      )
      .subscribe();
  }

  private openPublicProfileByShortId(shortId: string) {
    if (shortId) {
      this.searchService.getPublicProfileByShortId(shortId)
        .pipe(
          tap((response: any) => {
            if (response) {
              this.openPayment(response);
            } else {
              this.notFoundProfile();
            }
          }),
          catchError((error: any) => {
            this.toastrFactory.unknownError(this.translate.instant('unknownError'));
            return EMPTY;
          })
        )
        .subscribe();
    } else {
      setTimeout(() => {
        this.toastrService.error(this.translate.instant('notAValidUUID'));
      }, 1000);
    }
  }

  private openPublicProfileByDomain(domain: string) {
    if (domain) {
      this.searchService.getPublicProfileByDomain(domain)
        .pipe(
          tap((response: any) => {
            if (response) {
              this.openPayment(response);
            } else {
              this.notFoundProfile();
            }
          }),
          catchError((error: any) => {
            this.toastrFactory.unknownError(this.translate.instant('unknownError'));
            return EMPTY;
          })
        )
        .subscribe();
    } else {
      setTimeout(() => {
        this.toastrService.error(this.translate.instant('notAValidUUID'));
      }, 1000);
    }
  }

  checkIfUserIsValidated(params: any) {
    this.userService.userIsValidated(this.targetNick)
      .pipe(
        tap((response: any) => {
          if (!response) {
            this.toastrFactory.unknownError(this.translate.instant('unknownError'));
            return;
          }
          if (response) {
            this.paymentFlow = params['paymentFlow'] === 'true';
            this.userId = params['userId'];
            this.targetCompany = params['publicName'];
            this.companyWebsite = params['companyWebsite'];
          }
        }),
        catchError((error: any) => {
          this.toastrFactory.unknownError(this.translate.instant('unknownError'));
          return EMPTY;
        })
      )
      .subscribe();
  }

  private async openPaymentById(paymentId: string) {
    if (this.isAuthenticated && !this.user) {
      this.user = await firstValueFrom(this.userService.getUser());
    }
    this.paymentRequestService.getPaymentById(paymentId)
      .pipe(
        tap((request: any) => {
          if (request && request.bill.paymentReports.length === 0 && request.status !== 'Canceled') {
            this.requestInfo = request;
            this.targetUser = new UserClass(request.creator);
            this.paymentFlow = true;
          } else {
            const message = request.status === 'Canceled'
              ? 'thisPaymentRequestHasBeenCanceled'
              : 'thisPaymentRequestHasAlreadyBeenReported';
            this.toastrService.error(this.translate.instant(message));
          }
        }),
        catchError((error: any) => {
          this.toastrService.error(this.translate.instant("weAreUnableToFindThisPaymentRequest"), '', { timeOut: 3000 });
          return EMPTY;
        })
      )
      .subscribe();
  }

  public async checkParams(initCheck?: boolean) {


    try {
      const params = await firstValueFrom(this.params.paramMap);
      const queryParams = await firstValueFrom(this.params.queryParams);

      const email = params.get('email');

      const shortId = params.get('shortId');

      const domain = params.get('domain');


      if (initCheck) return;

      if (shortId) {
        this.openPublicProfileByShortId(shortId);
        ;
        return;
      }

      if (domain) {
        this.openPublicProfileByDomain(domain);
        ;
        return;
      }

      if (email) {
        this.getShortIdByEmail(email);
        ;
        return;
      }

      if (queryParams) {
        const userShortId = queryParams['shortId'];
        const paymentId = queryParams['paymentId'];
        const canSocial = queryParams['error_description'];
        this.targetNick = queryParams['targetNick'];

        ;

        if (initCheck) return;

        if (userShortId) {
          this.openPublicProfileByShortId(userShortId);
          return;
        }

        if (paymentId) {
          this.openPaymentById(paymentId);
          return;
        }

        if (queryParams['targetNick'] || queryParams['paymentAddress']) {
          if (queryParams['paymentAddress']) {
            this.targetNick = queryParams['paymentAddress'];
          }
          this.checkIfUserIsValidated(queryParams);
          return;
        }
      }

      if (localStorage.getItem('manyUsers')) {
        await this.handleManyUsers();
        return;
      }

      if (!queryParams || Object.values(queryParams).length === 0) {
        this.checkIfIsAuthenticated();
      }
    } catch (error) {
      console.error('Error checking params', error);

    }
  }

  private async handleManyUsers() {
    setTimeout(async () => {
      // if (await firstValueFrom(this.auth.isAuthenticated$)) {
      //   this.JWTToken = await firstValueFrom(this.auth.getAccessTokenSilently({
      //     authorizationParams: {
      //       audience: environment.auth0Audience
      //     },
      //   }));
      // }
      this.manyUsersModal = true;
      this.modalTittle = this.translate.instant('thankYourForSigningup');
      this.modalMessage = this.translate.instant('weAreExcitedToHaveYouOnBoard');

    }, 100);
  }

  notFoundProfile() {
    this.profileNotFound = true;
  }


  openPayment(target: any) {
    this.target = target;
    this.targetUser = new UserClass(target);
    // this.targetDomains = target.domains
    // this.userId = target.userId || target.id;
    // this.targetNick = target.nick || target.email;
    // this.targetCompany = target.publicName;
    // this.companyWebsite = target.websiteUrl;
    this.userProfile = true;
  }

  moreThanOneAt(input: string) {
    const regex = /@/g;
    const matches = input.match(regex);
    return matches ? matches.length : 0;
  }

  async onSearch(searchInput: HTMLInputElement) {
    const minWidth: number = 3;
    const inputLength: number = searchInput.value.replaceAll("@", "").trim().length;
    const atLength: number = this.moreThanOneAt(searchInput.value);

    if (searchInput.value.replaceAll("@", "").trim().length < minWidth) {
      const remainingChar = minWidth - inputLength;
      this.minimumCharMessage = this.translate.instant("HOME.youNeedToType") + remainingChar + this.translate.instant("HOME.moreChar") + (remainingChar == 1 ? '' : 's')
      if (atLength > 1) {
        this.minimumCharMessage += this.translate.instant('HOME.doesNotCount')
      }
      this.searchResults = [];
      return
    }
    this.minimumCharMessage = "";
    if (this.isAuthenticated && !this.user) {
      this.user = await firstValueFrom(this.userService.getUser());
    }
    this.searchService.generalSearch(searchInput.value).pipe(
      tap((response: any) => {
        this.searchResults = response;
        if (this.searchResults?.length == 1) {
          if (searchInput.value.trim() == this.searchResults[0]?.nick) {
            this.searchResults[0].exactlyMatch = true;
          } else {
            this.searchResults = [];
          }
        } else {
          this.searchResults = []
        }
        
      }),
      catchError((error:any) => {
        this.toastrFactory.handleError(error);
        return EMPTY;
      })
    ).subscribe();
  }

  handleImageError(event: any) {
    event.target.src = '/assets/icons/missing-icon.png';
  }

  logoutUser() {
    if (this.notify) {
      this.webAuth.logout({
        clientID: environment.auth0ID,
        returnTo: environment.websiteUrl,
      })
      return;
    } else {
      this.userService.deleteUser().pipe(
        tap((response: any) => {
          this.auth.logout({
            logoutParams: { returnTo: environment.websiteUrl }
          })
        }),
        catchError((error:any) => {
          this.toastrFactory.handleError(error)
          return EMPTY;
        })
      ).subscribe();
    }
  }

  closeModal(event?: any) {
    this.showModal = false;
    this.dashBoardOptions = false;
    this.paymentFlow = false;
    this.userProfile = false;
    this.checkIfIsAuthenticated();
  }

  async checkIfIsAuthenticated() {

    if (await firstValueFrom(this.auth.isAuthenticated$) === false && !(localStorage.getItem('manyUsers'))) {

      window.location.href = environment.websiteUrl;
    } else {

      this.router.navigateByUrl('/overview');
    }
  }

  private getCurrentUser() {

    this.auth.user$.subscribe((user: any) => {
      this.myNick = user?.email
      this.userId = user.sub;
    });
  }

  userAuthenticated() {
    this.auth.isAuthenticated$.pipe(
      tap((response: any) => {
        this.isAuthenticated = response;
      }),
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return this.auth.getAccessTokenSilently({
            authorizationParams: {
              audience: environment.auth0Audience
            },
          }).pipe(
            tap(() => {
              this.getCurrentUser();
              this.checkParams();
            }),
            catchError((error) => {
              this.toastrFactory.unknownError(this.translate.instant('unknownError'));
              return EMPTY;
            })
          );
        } else {
          this.checkParams();
          return EMPTY;
        }
      })
    ).subscribe();
  }

  getCurrentUserNick() {
    this.auth.user$.subscribe(
      (user) => this.myNick = user?.email
    )
  }

  getNickyUser() {
    this.user = firstValueFrom(this.userService.getUser());
  }

  public showEmailModal(fullMail: string) {
    this.showModal = true;
    this.modalMessage = fullMail;
    this.fullEmailModal = true;
  }


  public showDashboardOptionsModal(favorite: any) {
    this.showModal = true;
    this.modalNick = favorite.nick;
    this.modalPublicName = favorite.publicName;
    this.modalTittle = this.translate.instant("weFoundIt");
    this.dashBoardOptions = true;
  }

  setSearchBehavior() {
    // this.search.nativeElement.addEventListener('input', this.updateSearchResults.bind(this));
    // this.searchDesktop?.nativeElement.addEventListener('input', this.updateSearchResults.bind(this));
  }

  updateSearchResults() {
    this.searchResults = "";
  }


  checkWindowSize() {
    if (window.innerWidth <= 1100) {
      this.isMobile = true;
      return;
    }
    this.isMobile = false
  }

  public async addFavorite(nick: string) {


    let alreadyFavorite: any = await firstValueFrom(await this.favoritesService.favoriteByNick(nick));

    if (alreadyFavorite?.length > 0) {
      this.toastrService.success(this.translate.instant('userIsAlreadyInYourFavorites'), '', {
        timeOut: 3000
      });

    } else {
      this.favoritesService.addFavorite(nick).pipe(
        tap((response: any) => {
          this.toastrService.success(this.translate.instant('userAddedToYourFavoritesSuccessfully'), '', {
            timeOut: 3000
          });
          this.searchResults = response;      
        }),
        catchError((error:any) => {
          this.toastrFactory.handleError(error);
          return EMPTY;
        })
      ).subscribe();
    }
  }
}


